import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

import map from 'lodash-es/map';
import each from 'lodash-es/each';
import clone from 'lodash-es/clone';
@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  curateDates(col: any, type: any) {
    each(col, c => {
      const startWithTime = c.start ? dayjs(c.start, 'YYYY-MM-DDThh:mm:ss') : null;
      const endWithTime = c.end ? dayjs(c.end, 'YYYY-MM-DDThh:mm:ss') : null;
      const start = c.start != null ? dayjs(c.start.split('T')[0], 'YYYY-MM-DD') : null;
      const end = c.end != null ? dayjs(c.end.split('T')[0], 'YYYY-MM-DD') : null;
      c.startDate = start ? start.format('ddd DD/MM/YYYY') : null;
      c.recStartDate = start ? start.format('ddd') : null;
      c.endDate = end ? end.format('ddd DD/MM/YYYY') : null;
      c.sameDate = c.startDate === c.endDate;
      c.startTime = startWithTime ? c.sameDate ? startWithTime.format('HH:mm') : startWithTime.format('ddd HH:mm') : null;
      c.endTime = endWithTime ? c.sameDate ? endWithTime.format('HH:mm') : endWithTime.format('ddd HH:mm') : null;
      c.hours = type === 'schedules' ? (startWithTime && endWithTime) ? (endWithTime.diff(startWithTime, 'minutes') / 60).toFixed(2) : null : c.hours;
      c.employeeIds = map(c.employees, e => e.id);
      if (type === 'schedule') { c.employeeIds = map(c.employees, e => e.id); }
      if (type === 'timeEntry') { c.employeeId = c.employee?.id; }
    })
  }

  toFixedNumber(num, digits, base) {
    var pow = Math.pow(base || 10, digits);
    return Math.round(num * pow) / pow;
  }

  isValidDate(d: any) {
    const dc = clone(d);
    if (dc instanceof Date) {
      return !isNaN(d);
    }
    if (typeof (d) === 'string') {
      return true;
    }
  }

  generateRandomPassword() {
    /*const nac = '!@#$%^&*()_-~`,.';
    const random_nac = nac.charAt(Math.floor(Math.random() * nac.length));
    let pwd = `${Math.random().toString(36).slice(-8)}${random_nac}`;
    const rndC = pwd[Math.floor(Math.random() * 6 +1)];
    pwd = `${pwd.split(rndC)[0]}${rndC.toUpperCase()}${pwd.split(rndC)[1]}`;
    return pwd;*/

    var pwdStr = '';
    const charSet = 'AbCdDFgHJKmPrSTVWy';
    // const nacSet = '!@#$%^&*()_-~`,.';

    for (let i = 0, n = charSet.length; i < 3; i++) {
      pwdStr += charSet.charAt(Math.floor(Math.random() * n));
    }

    for (let i = 0; i < 3; i++) {
      let r = Math.floor(Math.random() * 10) + 1;
      pwdStr += r === 10 ? 9 : r;
    }

    // const rndNac = nacSet.charAt(Math.floor(Math.random() * nacSet.length));
    const rndC = charSet.charAt(Math.floor(Math.random() * charSet.length));
    // const rndA = Math.floor(Math.random() * 10) + 1;
    let pwd = (`${pwdStr}${rndC.toUpperCase()}`).split('').sort(function () { return 0.5 - Math.random() }).join('');
    return pwd;
  }

  isEmailValid(email) {
    if (!email) return false;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  mapColorToClass(color) {
    let result = ''
    switch (color) {
      case 'orange': { result = 'warning'; break; }
      case 'green': { result = 'success'; break; }
      case 'red': { result = 'danger'; break; }
      default: { result = 'primary'; break; }
    }

    return result;
  }

  cleanObjectStringProperties(obj: any) {
    for (let prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].trim();
      }
    }
  }

}
