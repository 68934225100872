import { AuthService } from '@core/services/auth.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Component, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../core/settings/settings.service';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { UserProfileService } from '@core/services/user-profile.service';

import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    valForm: FormGroup;
    passwordForm: FormGroup;

    constructor(
        private authService: AuthService,
        private fb: FormBuilder,
        private socialAuthService: SocialAuthService,
        private toastrService: ToastrService,
        private userProfileService: UserProfileService,
        public router: Router,
        public settings: SettingsService
    ) {
        this.router = router;
    }

    ngOnInit() {
        this.initForms();
    }

    initForms() {
        this.passwordForm = this.fb.group({
            'password': [null, [Validators.required, Validators.pattern('(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$')]],
            'password2': [null, [Validators.required]]
        }, { validators: passwordMatchValidator });

        this.valForm = this.fb.group({
            'email': [null, [Validators.required, Validators.email]],
            'accountagreed': [null, Validators.required],
            'passwordGroup': this.passwordForm
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();

        var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
        if (form.checkValidity() === false) {
            $ev.preventDefault();
            $ev.stopPropagation();
        }
        form.classList.add('was-validated');

        if (this.valForm.value?.passwordGroup?.password !== this.valForm.value?.passwordGroup.password2) {
            this.toastrService.warning('Passwords do not match');
            return;
        }

        if (this.valForm.valid) {
            this.createUserCarestart(value);
        }
    }

    createUserCarestart(credentials: any) {
        this.blockUI.start();
        const newUser = {
            email: credentials.email,
            firstName: '',
            lastName: '',
            password: credentials.passwordGroup.password
        }

        this.authService.createUserWithEmail(newUser).then(data => {
            this.blockUI.stop();
            // check if we have id
            // redirect to login or home ?
            Swal.fire('Success', 'You have been registered', 'success');
            this.router.navigate([`login`]);

        }).catch(e => {
            this.blockUI.stop();
            // 500 would be an error returned
            Swal.fire('Error', 'There was a problem in registering', 'error');

        });


    }

    signInWithGoogle(): void {
        const self = this;
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(googleUser => {
            this.blockUI.start();
            this.authService.googleAuthenticate(googleUser).then((data: any) => {
                console.log('returnd ata from googleAuth', data);
                this.authService.setAuth(data);
                this.userProfileService.getUserProfile().then(res => {
                    //this.userProfile = res;
                    //this.profileService.userProfile = res;
                    this.blockUI.stop();
                    //this.menuService.setMenu();
                    self.router.navigate(['/home']);
                },
                    err => {
                        this.authService.logout();
                        this.blockUI.stop();
                        console.error('getUserProfile error', err);
                        Swal.fire({
                            title: 'Not able to login',
                            //text: error.message,
                            icon: 'warning',
                            confirmButtonColor: '#DD6B55',
                        });
                    });
            });
        });
    }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
    if (formGroup.get('password').value === formGroup.get('password2').value)
        return null;
    else
        return { passwordMismatch: true };
};
