import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Router } from '@angular/router';
import screenfull from 'screenfull';

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';

import { AuthService } from '@core/services/auth.service';
import { InternalMailService } from '@core/services/internal-mail.service';

import map from 'lodash-es/map';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    currentOrganisation: any;
    menuItems = []; // for horizontal layout
    modalId: any;
    modules: any = [];
    navCollapsed = true; // for horizontal layout
    organisations: any = [];
    orgSubscription: any;
    router: Router;
    userProfile: any;
    isNavSearchVisible: boolean;
    showNotifictionsMenu: boolean = false;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    constructor(
        private userblockService: UserblockService,
        public settings: SettingsService,
        private injector: Injector,
        private authService: AuthService,
        private internalMailService: InternalMailService,
        private settingsService: SettingsService,
        ) {
    }

    ngOnInit() {
        this.subscribeToCurrentOrg();
        this.setupUserProfile();

        this.isNavSearchVisible = false;

        const ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = 'none';
        }

        // Switch fullscreen icon indicator
        const el = this.fsbutton.nativeElement.firstElementChild;
        if (screenfull.isEnabled) {
            screenfull.on('change', () => {
                if (el) {
                    el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
                }
            });
        }

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            window.scrollTo(0, 0);
             // close collapse menu
            this.navCollapsed = true;
        });
    }

    ngOnDestroy(): void {
        // never forget to add this line.
        this.orgSubscription = null;
      }

    subscribeToCurrentOrg() {
        const self = this;
        let organisation = this.settingsService.getOrgSettings();          
        self.currentOrganisation = organisation;
        
    }

    toggleUserBlock(event: any) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event: any) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleOffsidebarPanel() {
        this.settings.toggleLayoutSetting('offsideMessagePanelOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }
    }

    goToFileUpload() {
        this.router.navigate(['/files']);
    }

    goToPhoneRecords() {
        this.router.navigate(['/reports/phones']);
    }

    goToMailbox() {
        this.router.navigate(['mailbox']);
    }

    goToInvoices() {
        this.router.navigate(['/invoices']);
    }

    goToPayroll() {
        this.router.navigate(['/payroll']);
    }

    goToSettings() {
        this.router.navigate(['/settings/organisation']);
    }
    
    goToBills(): void {
        this.router.navigate(['/invoices/billstopay']);
    }

    goToJobsManage(): void {
        this.router.navigate(['/rosters/manage']);
    }

    signOut(): void {
        this.authService.logout();
        //this.router.navigate(['/login']);
    }

    async selectOrganisation(changeToOrganisation: any) {

        localStorage.removeItem('logoData')
       
        await this.authService.changeCurrentOrgId(changeToOrganisation.id).then((token: any) => {
            console.log('should be saved now: token response ', token);
            
            if (token) {
                this.authService.setAuth(token);
                // reset both user and org
                this.settingsService.setUserSetting('id', null);
                this.settingsService.setOrganisationSetting('id', null);

                this.setupUserProfile(true);
                               
            } else {
                this.signOut();
            }
        });        
    }

    setupUserProfile(reload = false) {
        const self = this;
        
        this.organisations = [];

        const res = this.settingsService.getUserSetting(null);
        this.userProfile  = res;

        res.rolesString = map(res.roles, a => a.name).join(', ');
                
        if (res.organisations && res.organisations.length) {
            res.organisations.forEach((organisation: any) => {
                //organisation.role = data.organisations[key];
                organisation.color = 'text-primary';

                //temp for cache problem
                organisation.id = organisation.Id ? organisation.Id : organisation.id;
                organisation.name = organisation.Name ? organisation.Name : organisation.name;
                // organisation.color = self.userProfile.currentOrganisation === key ? 'text-success' : 'text-primary';

                self.organisations.push(organisation);
                if (organisation.id === self.currentOrganisation.id) {
                    //console.log('set currentOrganisation', organisation);
                    self.currentOrganisation = organisation;
                }
            });
        } else {
            // no organisations
        }

        if (reload) { 
            //this.reloadCurrentRoute();  -- causes orgId to be missing
            window.location.reload(); 
        }
    }

    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

    showMod(modalName: any = null) {
        this.modalId = modalName;
        setTimeout(() => { this.modalId = null; }, 10); //refresh trigger for when selecting same menu 2 or more time in succession
    }

    newRosterEvent(type: any) {
       this.internalMailService.changeModalDetail({type: type, isNew: true, showDetail: true});
    }

    newNoteEvent(managedType: string) {
        this.internalMailService.changeModalDetail({type: 'note', isNew: true, managedType: managedType });
     }
}
