<block-ui></block-ui>

<div class="auth-fluid auth-fluid-std">
    <!-- Auth fluid right content -->
    <div class="auth-fluid-right">
        <div class="auth-user-testimonial">
            <h3 class="mb-3 text-white">Simple & impressive!</h3>
            <p class="lead fw-normal"><i class="mdi mdi-format-quote-open"></i> I've been using Carestart for some time
                now and it has reduced the amount of time I spend on admin tasks. Our billing is always up-to-date and
                balanced. The interface is easy to understand and provides us with a level of comfort that no bills are
                getting left behind. <i class="mdi mdi-format-quote-close"></i>
            </p>
            <h5 class="text-white">
                - Plan Manager
            </h5>
        </div> <!-- end auth-user-testimonial-->
    </div>
    <!-- end Auth fluid right content -->

    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
        <div class="align-items-center d-flex h-100">
            <div class="card-body">

                <!-- Logo -->
                <div class="auth-brand text-center text-lg-start">
                    <div class="auth-logo">
                        <a href="index.html" class="logo logo-dark text-center">
                            <span class="logo-lg">
                                <img src="../assets/img/logo/logo.png" alt="" height="75">
                            </span>
                        </a>

                        <a href="index.html" class="logo logo-light text-center">
                            <span class="logo-lg">
                                <img src="../assets/img/logo/logo.png" alt="" height="75">
                            </span>
                        </a>
                    </div>
                </div>

                <!-- title-->
                <h4 class="mt-0">Sign In</h4>
                <p class="text-muted mb-4">Enter your email address and password or use social media links to sign in.
                </p>

                <!-- form -->
                <form [formGroup]="valForm" role="form" name="loginForm" class="needs-validation"
                    (submit)="submitForm($event, valForm.value)">
                    <div class="mb-2">
                        <label for="emailaddress" class="form-label">Email address</label>
                        <input type="email" id="emailaddress" autocomplete="username" class="form-control"
                            formControlName="email" required placeholder="Enter your email">
                        <div id="validationServerUsernameFeedback" class="invalid-feedback">
                            Please enter a valid email address
                        </div>
                    </div>
                    <div class="mb-2">
                        <a class="text-muted float-end" [routerLink]="'/recover'"><small>Forgot your
                                password?</small></a>
                        <label for="password" class="form-label">Password</label>
                        <!-- <div class="input-group input-group-merge"> -->
                        <input type="password" id="password" class="form-control" autocomplete="current-password"
                            formControlName="password" required placeholder="Enter your password">
                        <!-- <div class="input-group-text" data-password="false">
                                <span class="password-eye"></span>
                            </div> -->
                        <!-- </div> -->
                        <div id="validationServerPasswordFeedback" class="invalid-feedback">
                            Please enter a password
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="checkbox-signin">
                            <label class="form-check-label" for="checkbox-signin">
                                Remember me
                            </label>
                        </div>
                    </div>
                    <div class="d-grid text-center">
                        <button class="btn btn-purple" type="submit">Log In </button>
                    </div>
                    <!-- social-->
                    <div class="text-center mt-4">
                        <h5 class="mt-0 text-muted">Or, sign in with</h5>
                        <ul class="social-list list-inline mt-3 mb-0">
                            <li class="list-inline-item">
                                <asl-google-signin-button type='standard' size='medium'></asl-google-signin-button>
                            </li>
                            <!-- <li class="list-inline-item">
                                <a href="javascript: void(0);" class="social-list-item border-purple text-purple"><i class="mdi mdi-facebook"></i></a>
                            </li> -->
                            <li class="mt-2">
                                <img style="width:170px" class="clickable" src="../assets/img/auth/ms-symbollockup_signin_light.png" (click)="signInWithMicrosoft()">
                                <!-- <button class="btn btn-social-icon btn-microsoft mx-1" (click)="signInWithMicrosoft()">
                                   
                                </button> -->
                            </li>
                            <!-- <li class="list-inline-item">
                                <a href="javascript: void(0);" class="social-list-item border-info text-info"><i class="mdi mdi-twitter"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a href="javascript: void(0);" class="social-list-item border-secondary text-secondary"><i class="mdi mdi-github"></i></a>
                            </li> -->
                        </ul>
                    </div>
                </form>
                <!-- end form-->

                <!-- Footer-->
                <footer class="footer footer-alt">
                    <p class="text-muted">Don't have an account? <a [routerLink]="'/register'"
                            class="text-primary fw-medium ms-1">Sign Up</a></p>
                </footer>

            </div> <!-- end .card-body -->
        </div> <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->
</div>