import { Component, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BusinessService } from 'src/app/services/business.service';
import { UtilityService } from 'src/app/services/utility.service';
import { EmployeeService } from '@core/services/employee/employee.service';
import { JobService } from '@core/services/job.service';
import { ClientService } from '@core/services/client/client.service';
import { ProviderService } from '@core/services/provider.service';
import { OutletService } from '@core/services/outlet.service';
import { SilService } from '@core/services/sil.service';
import { TypeService } from '@core/services/type.service';

import size from 'lodash-es/size';
import filter from 'lodash-es/filter';
import cloneDeep from 'lodash-es/cloneDeep';
import every from 'lodash-es/every';
import orderBy from 'lodash-es/orderBy';
import sumBy from 'lodash-es/sumBy';
import map from 'lodash-es/map';
import each from 'lodash-es/each';
import remove from 'lodash-es/remove';
import find from 'lodash-es/find';
import upperCase from 'lodash-es/upperCase';

import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask, IConfig } from 'ngx-mask';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { ClientOutletsComponent } from '@components/client-outlets/client-outlets.component';
import { SchedulesComponent } from '@components/schedules/schedules.component';
@Component({
	selector: 'edit-job-component',
	templateUrl: './edit-job.component.html',
	styleUrls: ['./edit-job.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		NgSelectModule,
		BsDatepickerModule,
		NgxMaskDirective, NgxMaskPipe,
		TypeaheadModule,
		ModalModule,
		ClientOutletsComponent,
		SchedulesComponent
	]
})
export class EditJobComponent {
	@Input() callback = null;
	@Input() container = null;
	@Input() containerVisible = false;
	@Input() isNew = null;
	@Input() modalCode = null;
	@Input() model: any = {};
	@Input() organisationId = '';
	@Input() recurring = null;
	@Input() scheduleDate = null;
	@Input() showDetail = null;

	allJobTypes: any = [];
	allClients: any = [];
	backupClients: any;
	chartAccountCodes: any = [];
	client: any = {};
	clients: any = [];
	employees: any = [];
	groupings: any = this.businessService.getGroupClientGroupings();
	hasClient: any;
	hasJobType: any;
	hasName: any;
	hasProvider: any;
	hasWorkType: any;
	isCheckForGroup: any = false;
	isEditGroupClient: boolean;
	itemCodeTypes: any = this.businessService.getItemCodeTypes();
	jobCreated: any;
	jobForm: FormGroup;
	jobModalXL: any = true;
	jobStatuses: any = [];
	jobTypes: any = [];
	outlets: any = [];
	silHouses: any = [];
	providers: any = [];
	ratios: any = this.businessService.getGroupClientRatios();
	selectedChartAccountCodeItem: any;
	selectedGroupClientToAdd: any;
	selectedSupportItem: any;
	softWarning: any;
	supportItemNumbers: any = [];
	taxTypes: any = [];
	timeReg: any = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
	title: any;
	workTypes: any = [];
	isShowShortNoticeApptClientsOnly: any = false;

	constructor(
		private formBuilder: FormBuilder,
		private businessService: BusinessService,
		private utilityService: UtilityService,
		private toastrService: ToastrService,
		private employeeService: EmployeeService,
		private jobService: JobService,
		private clientService: ClientService,
		private outletService: OutletService,
		private silsService: SilService,
		private providerService: ProviderService,
		private router: Router,
		private typeService: TypeService,
	) { }

	initForms() {
		this.model = { schedules: [] };
		this.jobForm = this.formBuilder.group({
			id: null,
			jobTypeId: [''],
			clientId: null,
			clients: [],
			providerId: [''],
			outletId: [''],
			silId: [''],
			name: [''],
			workId: [''],
			jobNumber: [''],
			status: [''],
			isComplete: [''],
			scheduledDate: null,
			startTime: null,
			endTime: null,
			isStartValid: [false],
			isEndValid: [false],
			scope: [''],
			templateJobId: null,
			isKmsPayable: [false],
			isGroupActivity: false,
			isSILHouse: false,
			maxClients: [0]
		});
	}

	// only runs on initial load of modal for site
	ngOnInit(): void {
		this.initForms();
		if (this.container && this.containerVisible) {
			this.init();
		}
	}

	init() {
		this.taxTypes = this.businessService.getTaxTypes();
		this.workTypes = this.businessService.getWorkTypes();
		this.jobStatuses = this.businessService.getJobStatuses();
		this.chartAccountCodes = this.businessService.getChartAccountCodes(this.organisationId);

		this.getClients();
		this.getEmployees();
		this.getProviders();
		this.getOutlets();
		this.getSILHouses();
		this.getJobTypes();
	}

	async getClientJobs(scheduleDate: any = null) {
		let date = scheduleDate ? dayjs(scheduleDate) : this.scheduleDate;
		if (!date) {
			if (this.model?.schedules?.length) {
				const currentRow = find(this.model.schedules, s => s.newRow && s.editMode);
				if (currentRow && currentRow.startDate) {
					date = dayjs(currentRow.startDate).format('YYYY-MM-DD');
				}
			}
		} else {
			date = date.format('YYYY-MM-DD');
		}

		if (date) {
			const jobs: any = await this.jobService.getClientJobs(this.jobForm.value.clientId, date, date);
			this.softWarning = !!jobs.length;
		}
	}

	// runs when we call the modal 
	ngOnChanges(changes: SimpleChanges) {
		// default values
		this.softWarning = false;
		this.isCheckForGroup = false;
		this.hasJobType = false;
		this.hasWorkType = false;
		this.hasClient = false;
		this.hasProvider = false;
		this.hasName = false;

		switch (this.modalCode) {
			case 'j':
				this.title = 'Job';
				this.hasClient = true;
				this.hasJobType = true;
				this.hasWorkType = true;
				this.isCheckForGroup = true;
				break;
			case 'pd':
				this.title = 'Professional Development (PD)';
				this.hasProvider = true;
				this.hasName = true;
				break;
			case 'ce':
				this.title = 'Community Engagement (CE)';
				this.hasProvider = true;
				this.hasName = true;
				break;
			case 'im':
				this.title = 'Staff Meeting (IM)';
				this.hasName = true;
				break;
			case 'cm':
				this.title = 'Client Meeting (CM)';
				this.hasClient = true;
				this.hasProvider = true;
				this.hasName = true;
				break;
			case 're':
				this.title = 'Reminder (RE)';
				this.hasName = true;
				break;
			case 'ta':
				this.title = 'Time Away (TA)';
				this.hasName = true;
				break;
			case 's':
				this.title = 'Supervision';
				this.hasProvider = true;
				break;
			case 'lc':
				this.title = 'Location';
				this.hasName = true;
				break;

		}

		if (changes.model && changes.model.currentValue) {
			this.model = cloneDeep(changes.model.currentValue);
			const val = changes.model.currentValue;
			each(this.model.schedules, s => this.onTimeChange(s));
			this.jobForm.patchValue({
				id: val.id,
				jobTypeId: val.jobTypeId,
				clientId: val.clientId,
				clients: map(val.groupActivityBookings, gab => {
					const client = gab.client;
					client.isCopay = gab.isCoPay;
					client.isTransport = gab.isTransport;
					client.isCancelled = gab.isCancelled;
					client.isBillable = gab.isBillable;
					client.clientRatio = gab.clientRatio;
					client.isNew = false;
					return client;
				}),
				providerId: val.providerId,
				outletId: val.outletId || val.outlet?.id,
				silId: val.silId || val.sil?.id,
				workId: val.workId,
				jobNumber: val.jobNumber,
				status: val.status,
				scheduledDate: val.scheduledDate,
				scope: val.scope,
				templateJobId: val.templateJobId,
				isKmsPayable: val.isKmsPayable,
				isGroupActivity: val.isGroupActivity,
				isSILHouse: val.isSILHouse,
				name: val.name,
				maxClients: val.maxClients,
			});

			if ((!this.client?.outlets || !this.client.outlets?.length) && changes.model?.currentValue?.client?.outlets) {
				this.client.outlets = changes.model.currentValue.client.outlets;
			}
			this.backupClients = cloneDeep(this.jobForm.value.clients);
		}

		if (changes.isNew && changes.isNew.previousValue === false && changes.isNew.currentValue === true) { // modal for new job is opened, clear fields
			this.initForms();
		}

		if (changes.containerVisible && changes.containerVisible.currentValue) {
			this.init();
		}
	}

	async getClients() {
		if (!this.clients.length) {
			this.clients = await lastValueFrom(this.clientService.getClientIdsObs());
			this.clients = orderBy(this.clients, c => c.fullName ? c.fullName.toLowerCase() : null);
			this.allClients = cloneDeep(this.clients);
		}
	}

	async getEmployees() {
		if (!this.employees.length) {
			let employees = await lastValueFrom(this.employeeService.getIds());
			this.employees = orderBy(employees, p => p.name?.toLowerCase(), 'asc');
		}
	}

	async getProviders() {
		if (!this.providers.length) {
			this.providers = await lastValueFrom(this.providerService.getIds());
			this.providers = orderBy(this.providers, p => p.name ? p.name.toLowerCase() : null);
		}
	}

	async getOutlets() {
		if (!this.outlets.length) {
			this.outlets = await lastValueFrom(this.outletService.getIds());
			this.outlets = orderBy(this.outlets, p => p.name ? p.name.toLowerCase() : null);
			console.log('outlets', this.outlets)
		}
	}

	async getSILHouses() {
		if (!this.silHouses.length) {
			this.silHouses = await this.silsService.getAll();
			this.silHouses = orderBy(this.silHouses, p => p.name ? p.name.toLowerCase() : null);
		}
	}

	async getJobTypes() {
		if (!this.jobTypes.length) {
			this.allJobTypes = await this.typeService.getJobTypes();
		}
	}

	async getClient(id: any) {
		this.client = await this.clientService.getClient(id);
	}

	async getClientOutlets() {
		this.client.outlets = await this.outletService.getOutletsByClient(this.jobForm.value.clientId);
	}

	async saveJob() {
		const newVal = this.jobForm.value;

		const jobObj: any = {
			scope: newVal.scope,
			jobClass: upperCase(this.modalCode),
			status: newVal.status,
			outletId: newVal.outletId ? newVal.outletId : null,
			silId: newVal.silId ? newVal.silId : null,
			providerId: newVal.providerId ? newVal.providerId : null,
			clientId: newVal.clientId ? newVal.clientId : null, // allow nulls for PD, CE etc but not if Job
			name: newVal.name,
			isGroupActivity: newVal.isGroupActivity,
			isSILHouse: newVal.isSILHouse,
			maxClients: newVal.maxClients,
		}

		this.model?.id ? await this.updateJob(jobObj, newVal.id) : await this.createNewJob(jobObj);
	}

	async createNewJob(jobObj: any) {
		jobObj.status = 'scheduled';
		try {
			const job: any = await this.jobService.createJob(jobObj);
			if (this.model?.schedules && this.model?.schedules.length) {
				each(this.model.schedules, async (schedule) => {
					schedule.jobId = job.id;
					await this.saveSchedule(schedule)
				})
			}

			this.saveScopeOfWork();
			this.saveGroupClientBookings(job);
			this.resetJob();
			if (this.callback) {
				this.container.hide();
				this.callback(job.jobNumber);
			}
			this.toastrService.success('Success', 'Saved Job');
			this.jobCreated = job;
			this.model.schedules = [];
		} catch (err) {
			this.toastrService.error('Error', 'Failed to Save Job Details');
			console.error('err', err);
		};
	}

	async updateJob(jobObj: any, jobId: any) {
		jobObj.id = jobId;
		await this.jobService.patchJob(jobObj).catch(err => {
			this.toastrService.error('Error', 'Failed to Update Job Details');
			console.error('err', err);
		});

		this.saveGroupClientBookings(jobObj);
		this.toastrService.success('Success', 'Updated Job Details');
		this.container.hide();
		this.resetJob();
		if (this.callback) {
			this.callback();
		}
	}

	resetJob() { //reset all fields except these, for saving
		this.jobForm.reset({
			id: this.jobForm.get('id').value,
			jobTypeId: this.jobForm.get('jobTypeId').value,
			isGroupActivity: this.jobForm.get('isGroupActivity').value,
			isSILHouse: this.jobForm.get('isSILHouse').value
		});
	}

	goToNewJob() {
		let classType = this.jobCreated.jobClass === 'J' ? 'job' : this.jobCreated.jobClass;
		this.router.navigate(['rosters/' + classType + '/' + this.jobCreated.jobNumber]);
		this.container.hide();
	}

	async deleteRecurringJob() {
		const self = this;
		Swal.fire({
			title: 'Are you sure?',
			html: 'Delete this Job?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then(async (res: any) => {
			if (res.value) {
				await self.jobService.deleteRecurringJobTemplate(this.jobForm.value.templateJobId).then(() => {
					self.toastrService.success('Success', 'Deleted Recurring Job');
				}).catch((err) => {
					self.toastrService.error('Error', `Something went wrong while trying to delete record. Err: ${err}`);
				}).finally(() => {
					this.container.hide();
					if (this.callback) {
						this.callback();
					}
				});
			};
		});
	}

	saveScopeOfWork(jobId: any = this.jobForm.value.id) {
		if (!jobId) return;
		const patchItem = {
			id: this.jobForm.value.id,
			scope: this.jobForm.value.scope
		}

		this.jobService.patchJob(patchItem).then(() => {
			this.toastrService.success('Success', 'Saved Scope');
		}).catch((error: any) => {
			this.toastrService.error('Error', `Something went wrong while trying to save scope. ${error.message}`);
		});
	}

	saveGroupClientBookings(job: any) {
		console.log('this.model', this.model);
		if (!this.jobForm.value.isGroupActivity && this.model.groupClientBookings?.length) {
			// check if have groupClientBookings
			// prompt with sweetalert to delete
			Swal.fire({
				title: 'Are you sure?',
				html: 'Delete Group Bookings?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}).then((result) => {
				if (result.isConfirmed) {
					this.model.groupClientBookings.forEach((booking) => {
						this.clientService.deleteGroupBooking(job.id, booking.clientId)
							.catch(() => this.toastrService.error('Error', 'Something went wrong while trying to delete group activity clients. Please contact administrator'));
					});
					this.toastrService.success('Success', 'Deleted Group Bookings');
				}
			}).catch((error) => {
				this.toastrService.error('Error', `Something went wrong while trying to delete group bookings. ${error.message}`);
			});

			return;
		}
		console.log('this.jobForm.value', this.jobForm.value);

		if (this.jobForm.value.clients?.length) {
			job.groupActivityBookings = [];
			each(this.jobForm.value.clients, groupClient => {
				job.groupActivityBookings.push({
					client: groupClient.id,
					clientRatio: groupClient.clientRatio,
					isTransport: groupClient.isTransport,
					isBillable: groupClient.isBillable,
					isCopay: groupClient.isCopay,
					isCancelled: groupClient.isCancelled,
					isNew: groupClient.isNew
				});
			})
		}

		const prs: any = [];
		each(job.groupActivityBookings, (booking: any) => {
			const saveObj: any = {
				clientId: booking.client,
				jobId: job.id,
				isCancelled: booking.isCancelled || false,
				isTransport: booking.isTransport || false,
				isBillable: booking.isBillable || false,
				isCopay: booking.isCopay || false,
				cancelledDate: booking.cancelledDate || null,
				clientRatio: booking.clientRatio || 0,
				enrolledDate: dayjs().format('YYYY-MM-DD'),
				isNew: booking.isNew
			}

			console.log('saveObj', saveObj);
			if (saveObj.isNew) {
				prs.push(this.clientService.createGroupBooking(saveObj));
			} else {
				prs.push(this.clientService.saveGroupBooking(saveObj.jobId, saveObj.clientId, saveObj));
			}
		});

		Promise.all(prs).catch(() => this.toastrService.error('Error', 'Something went wrong while trying to save group activity clients. Please contact administrator'))
			.finally(() => this.container.hide());
	}

	onClientSelected() {
		this.getClient(this.jobForm.value.clientId);
		this.jobForm.controls['outletId'].reset();
		this.getClientJobs();
	}

	onGroupClientSelected(groupClient: any, multipleClientsSelect: any) {
		groupClient.isNew = true;
		this.selectedGroupClientToAdd = groupClient;
		if (!this.backupClients) this.backupClients = [];
		this.backupClients.push(groupClient)
		this.jobForm.controls['clients'].setValue(this.backupClients);
		multipleClientsSelect.readonly = true;
		this.isEditGroupClient = false;
	}

	onGroupClientSave(multipleClientsSelect: any) {
		this.selectedGroupClientToAdd = null;
		multipleClientsSelect.readonly = false;
		this.isEditGroupClient = false;
	}

	onGroupClientCancel(multipleClientsSelect: any) {
		remove(this.jobForm.value.clients, (cl: any) => cl.id === this.selectedGroupClientToAdd.id);
		this.jobForm.controls['clients'].setValue(this.jobForm.value.clients);
		this.selectedGroupClientToAdd = null;
		multipleClientsSelect.readonly = false;
		this.isEditGroupClient = false;
	}

	unselect(item: any) {
		remove(this.jobForm.value.clients, (cl: any) => cl.id === item.id);
		this.jobForm.controls['clients'].setValue(this.jobForm.value.clients);
	}

	clearAllGroupClients() {
		this.backupClients = [];
	}

	onClientClick(client: any, multipleClientsSelect: any) {
		multipleClientsSelect.isOpen = false;
		this.selectedGroupClientToAdd = find(this.jobForm.value.clients, cl => cl.id === client.id);
		this.isEditGroupClient = true;

	}

	closeModal() {
		this.container.hide();
	}

	formatDateFromTime(date) {
		return !isNaN(date) ? dayjs(date).format('YYYY-MM-DD') : dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
	}

	async saveSchedule(item: any) {
		let startDate = this.formatDateFromTime(item.startDate);
		let endDate = this.formatDateFromTime(item.endDate);
		const startDateTime = startDate + 'T' + this.businessService.extractTime(item.startTime) + ':00';
		const endDateTime = endDate + 'T' + this.businessService.extractTime(item.endTime) + ':00';

		const officeLeaveDateTime = item.officeLeaveTime ? startDate + 'T' + this.businessService.extractTime(item.officeLeaveTime) + ':00' : null;
		const officeReturnDateTime = item.officeReturnTime ? endDate + 'T' + this.businessService.extractTime(item.officeReturnTime) + ':00' : null;
		const travelTime = item.jobType?.name && item.jobType?.name.includes('Therapy') ? 0.5 : 1;

		const payload: any = {
			jobId: this.model.id || item.jobId,
			name: item.name,
			workType: item.workType,
			description: item.description,
			start: startDateTime,
			end: endDateTime,
			isKmsPayable: item.isKmsPayable,
			isChargeable: item.isChargeable,
			isSleepover: item.isSleepover,
			isMobile: item.isMobile,
			officeLeaveTime: officeLeaveDateTime,
			officeReturnTime: officeReturnDateTime,
			isTravelTimeChargeable: item.isTravelTimeChargeable,
			preTravelHours: travelTime,
			postTravelHours: travelTime,
			//paySource: item.paySource,
			isNDISJob: item.isNDISJob,
			taskTypeId: item.taskTypeId || 122318, //WORK
			jobTypeId: item.jobTypeId,
			employees: item.employees
		};

		if (this.modalCode !== 'j') {
			delete payload.workType;
			delete payload.jobTypeId
		}

		try {
			if (item.id) {
				payload.id = item.id;
				await this.jobService.updateJobSchedule(payload)
			} else {
				await this.jobService.createJobSchedule(payload)
			}
			item.editMode = false;
		} catch (err) {
			console.error('Error while saving schedule', err);
		}
	}

	onJobTypeChange(item: any) {
		let jobType = find(this.jobTypes, a => a.id === item.jobTypeId);
		let jobScheduleItem = find(this.model.schedules, a => a.idx === item.idx);
		if (jobType && jobScheduleItem) {
			// TODO need 2 way binding
			item.isKmsPayable === jobType.isKmsPayable;
		}
	}

	isScheduleValid(item) {
		if (item) {
			let isValid = item.isStartValid && item.isEndValid && (item.startDate && this.utilityService.isValidDate(item.startDate));
			if (this.modalCode === 'ta') { isValid &&= (item.endDate && this.utilityService.isValidDate(item.endDate)) }
			if (this.hasJobType) { isValid &&= (item.jobTypeId || item.jobType) }
			return isValid;
		}
		return false;
	}

	// ----------------- CHARGES -----------------
	chargeGotoEditMode(item: any) {
		item.beforeEdit = cloneDeep(item);
		item.editMode = true;
	}

	cancelChargeLineItem(item: any) {
		if (item.newRow) {
			remove(this.model?.charges, (c: any) => c.newRow === true);
		} else {
			item.editMode = false;
			if (item.beforeEdit) { Object.assign(item, item.beforeEdit); }
		}
	}

	chargeLineItemDelete(item: { id: any; }) {
		Swal.fire({
			title: 'Are you sure?',
			html: 'Charge Delete',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((res: any) => {
			if (res.value) {
				this.jobService.deleteJobCharge(item.id)
					.then(() => { this.loadTemplate(); })
					.catch((err) => {
						this.toastrService.error('Error', `Something went wrong while trying to delete. Err: ${err}`);
					})
			}
		});
	}

	saveChargeLineItem(item: any) {
		const payload: any = {
			jobId: this.model.id,
			itemId: 0,
			description: item.description,
			details: item.details,
			unit: item.unit,
			taxType: item.taxType,
			quantity: item.quantity,
			chartAccountCode: item.chartAccountCode,
			itemCode: item.supportItemNumber,   // just get the code number
			itemCodeType: 'NDIS',   // assuming NDIS has been selected and we use the price guide typeahead
			total: item.total
		};

		if (item.beforeEdit) { delete item.beforeEdit; }

		if (item.id) {
			payload.id = item.id;
			this.jobService.updateJobCharge(payload).then(() => {
				//this.loadTemplate(); 
			});
		} else {
			this.jobService.createJobCharge(payload).then(() => {
				//this.loadTemplate();
			});
		}

		item.total = item.quantity * item.unit * (item.taxRate);
		this.model.totalChargeAmount = sumBy(this.model.charges, (b: any) => b.total);
		this.model.totalChargeCount = size(this.model.charges);
		this.model.totalPayments = 0; // TODO:
		this.model.balanceRemaining = this.model.totalChargeAmount - this.model.totalPayments;
		item.editMode = false;

	}

	isChargeValid(item) {
		let res = !!item.itemCodeType;
		if (!item.isFixedTotal) {
			res &&= item.unit && item.quantity
		} else {
			res &&= item.total;
		}
		return res;
	}

	chargeAddNew() {
		this.model?.charges.push({
			editMode: true,
			newRow: true,
			itemCodeType: '',
			taxType: ''
		});
	}

	loadTemplate() {
		this.jobService.getRecurringJobTemplate(this.model.templateJobId).then((res: any) => {
			this.model = res.baseJob;
			this.model.templateJobId = res.id;
			this.utilityService.curateDates(this.model.schedules, 'schedules');
		});
	}

	onTimeChange(item: any) {
		if (this.modalCode === 'ta') {
			this.onTimeChangeItem(item, false);
			if (item.isStartValid && item.isEndValid) {
				let start = dayjs(`${!isNaN(item.startDate) ? dayjs(item.startDate).format('DD/MM/YYYY') : item.startDate} ${this.businessService.extractTime(item.startTime)}`, 'DD/MM/YYYY HH:mm');
				let end = dayjs(`${!isNaN(item.endDate) ? dayjs(item.endDate).format('DD/MM/YYYY') : item.endDate} ${this.businessService.extractTime(item.endTime)}`, 'DD/MM/YYYY HH:mm');
				item.startDate = start.format('DD/MM/YYYY');
				item.endDate = end.format('DD/MM/YYYY');
			}
		} else {
			this.onTimeChangeItem(item);
		}
	}

	onTimeChangeItem(item: any, calcHours: boolean = true) {
		item.isStartValid = item.startTime ? !!this.businessService.extractTime(item.startTime).match(this.timeReg) : false;
		item.isEndValid = item.endTime ? !!this.businessService.extractTime(item.endTime).match(this.timeReg) : false;

		if (calcHours) {
			if (item.isStartValid && item.isEndValid) {
				this.businessService.calcHours(item);
			}
		}
	}

	onSupportItemChanged(item: any, line: any) {
		this.selectedSupportItem = item.item;
		line.supportItem = { name: this.selectedSupportItem.supportItemName, number: this.selectedSupportItem.supportItemNumber };
	}

	onChartAccountsCodeChanged(item: any, line: any) {
		this.selectedChartAccountCodeItem = item.item;
		line.chartAccountCode = { name: this.selectedChartAccountCodeItem.name, number: this.selectedChartAccountCodeItem.accountCode };
	}

	filterClients() {
		this.clients = this.allClients;
		if (this.isShowShortNoticeApptClientsOnly) {
			this.clients = filter(this.clients, c => c.isOnShortNoticeList);
			this.jobForm.patchValue({ clients: filter(this.jobForm.value.clients, c => c.isOnShortNoticeList) });
		}
	}

	isFormValid() {
		let res = this.model?.schedules && this.model?.schedules.length && every(this.model?.schedules, s => this.isScheduleValid(s));
		if (this.jobForm.value.isGroupActivity) {
			res &&= this.jobForm.value?.clients?.length && every(this.jobForm.value?.clients, cl => cl.clientRatio !== undefined && cl.clientRatio);
		} else {
			if (['ce', 'pd', 's'].indexOf(this.modalCode) >= 0) { res &&= this.jobForm.value?.providerId; }
			if (this.modalCode === 'j') { res &&= this.jobForm.value?.clientId; }
		}
		if (this.isNew) { res &&= this.jobForm.valid; }
		return res;
	}

	expandJobModal() {
		this.jobModalXL = !this.jobModalXL;
	}

	onContainerHideEvent() {
		if (this.isNew) {
			this.initForms();
			this.backupClients = [];
			this.model = { schedules: [], charges: [], clients: [] };
		}
	}


}
