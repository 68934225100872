import { AbstractControl, ValidatorFn, ValidationErrors } from "@angular/forms";

export class RegexConstants {
    public static PERSON_NAME = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;
    public static PHONE_NUMBER = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    public static CM_RANGE = /^([1-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)$/g;
    public static NAME_WIDESCOPE = /^[a-zA-Z][0-9a-zA-Z .,'-]*$/g;
    public static COMPANY_NAME = /^[a-zA-Z][0-9a-zA-Z .,'-/&]*$/g;
    public static FORM_NAME = /^[a-zA-Z][0-9a-zA-Z .,'-_]*$/g;
    public static INVOICE_NUMBER = /^[a-zA-Z0-9-_]+$/g;
    public static AUSTRALIAN_ZIPCODE = /^\d{4}$/g;
    public static NUMBER = /^-?\d+(\.\d+)?$/g;

}

export function RegexValidator(reg: RegexConstants): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && !control.value.toString().trim().match(reg)) {
      return { decimal: true };
    }
    return null;
  };
  
}
