import { Component, Input, OnInit, SimpleChanges, ViewChild,} from '@angular/core';
import { Router } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { CommonModule } from '@angular/common';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { HttpEventType } from '@angular/common/http';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import find from 'lodash-es/find';
import filter from 'lodash-es/filter';
import orderBy from 'lodash-es/orderBy';
import cloneDeep from 'lodash-es/cloneDeep';
dayjs.extend(customParseFormat);

import Quill from 'quill';
import { ImageDrop } from 'quill-image-drop-module';
Quill.register('modules/imageDrop', ImageDrop);
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

import { DocumentService } from '@core/services/document.service';
import { SettingsService } from '@core/settings/settings.service';
import { ClientService } from '@core/services/client/client.service';
import { ClinicNoteService } from '@core/services/clinic-note.service';
import { BusinessService } from 'src/app/services/business.service';
import { NoteTemplateService } from '@core/services/note-template.service';

import { BillingNotesComponent } from '@components/billing-notes/billing-notes.component';

@Component({
  selector: 'app-edit-clinic-note',
  templateUrl: './edit-clinic-note.component.html',
  styleUrls: ['./edit-clinic-note.component.scss'],
  standalone: true,
  imports: [SharedModule, BsDatepickerModule, BillingNotesComponent, TimepickerModule, CommonModule, NgxDropzoneModule],
})
export class EditClinicNoteComponent implements OnInit {
  @ViewChild('clinicNoteModal', { static: true }) clinicNoteModal: {
    show: () => void;
    hide: () => void;
  };

  @Input() callback = null;
  @Input() selectedSchedule = null;
  @Input() onRecordAdded = null;
  @Input() model = null;
  @Input() caller: any = '';
  @Input() editMode = false;
  @Input() clientId: any;
  @Input() client: any = {};

  _managedType: any = null;
  _isLockManagedType: any = null;

  HTML_CONTENT = ''; // '<p>Fill in note detail here...</p><br/><br/>...<br/><br/><br/>When finished click on save button';
  clients: any = [];
  clinicNote: any = {};
  currentUser: any = {};
  defaultTypes: any[];
  editor: any;
  isBillables: any = this.businessService.getBillableStatuses();
  isNew: any;
  jobIsTherapy: boolean;
  levels: any = [1, 2, 3];
  noteManagedTypes: any = this.businessService.getNoteManagedTypes();
  noteTemplates: any = [];
  noteTypes: any = this.businessService.getNoteTypes();
  otherTypes: any[];
  pastNotes: any = [];
  selectedPastNoteId: any = '';
  selectedTemplate: any = {};
  selectedTemplateId: any = '';
  showEditor: boolean = true;
  therapyTypes: any[];
  modalXL: boolean = true;
  tabSelection = 'notes';
  isLoading: any = {};
  alertTypes: any = this.businessService.getAlertTypes();

  constructor(
    private clinicNoteService: ClinicNoteService,
    private businessService: BusinessService,
    private toastrService: ToastrService,
    private noteTemplateService: NoteTemplateService,
    private documentService: DocumentService,
    private settingsService: SettingsService,
    private clientService: ClientService,
    private router: Router
  ) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = this.settingsService.getUserSetting(null);
    this.defaultTypes = filter(
      this.noteTypes,
      (nt) => nt.group === 'default' || nt.group == 'multi'
    );
    this.therapyTypes = filter(
      this.noteTypes,
      (nt) => nt.group === 'therapy' || nt.group == 'multi'
    );
    this.otherTypes = filter(this.noteTypes, (nt) => nt.group === 'other');
    if (this.editor) this.editor.clipboard.dangerouslyPasteHTML('');
  }

  // runs when we call the modal
  async ngOnChanges(changes: SimpleChanges) {
    //console.log('managedType', this.managedType);
    if (changes.model && changes.model.currentValue) {
      this.clinicNote = { managedType: this.managedType };
      const note = changes.model.currentValue;
      this.isNew = !note?.id;

      if (note && note.id) {
        await this.getClinicNote(note.id);
        this.destroyQuill();
        this.initCKEditor(note.detail);
      }
    } else {
      if (!this.model) {
        this.isNew = true;
        this.clinicNote = { managedType: this.managedType };
        if (this.jobIsTherapy) {
          this.clinicNote.managedType = 'Therapy';
        }
      }
    }

    if (changes.clientId && changes.clientId.currentValue) {
      //console.log('onselectClient', changes.clientId.currentValue)
      this.onSelectClient(changes.clientId.currentValue);
    }

    if (changes.selectedSchedule && changes.selectedSchedule.currentValue) {
      const currentValue = changes.selectedSchedule.currentValue;
      if (currentValue.jobType?.name) {
        this.jobIsTherapy =
          currentValue.jobType.name.toLowerCase().indexOf('therapy') > -1;

        // this.sessionOutcomesObj = find(this.noteTypes, nt => nt.name === 'Session Outcomes');
        // if (!find(this.defaultTypes, dt => dt.name === 'Session Outcomes') && this.sessionOutcomesObj) {
        //   this.defaultTypes.push(this.sessionOutcomesObj);
        // }
      } else {
        this.jobIsTherapy = false;
        this.clinicNote.managedType === '';
      }

      this.getPastNotes(this.selectedSchedule?.job?.clientId);
    }
  }

  async open() {
    console.log('this.model *****', this.model);
    if (!this.model || !this.model.id) {
      this.isNew = true;
      if (this.jobIsTherapy) {
        this.clinicNote.managedType = 'Therapy';
      }
    }
    this.clinicNoteModal.show();
    if (!this.clients.length) {
      this.clients = await this.clientService.getClientIds();
    }
    if (!this.noteTemplates.length) {
      this.noteTemplates = await this.noteTemplateService.getAll();
      console.log('this.noteTemplates', this.noteTemplates);
    }
    if (this.editMode && this.isNew) {
      this.destroyQuill();
      this.initCKEditor(this.HTML_CONTENT);
      this.clinicNote.noteDate = new Date();
      this.clinicNote.level = this.levels[0] || null;
    }
    if (!this.clinicNote.managedType) this.clinicNote.managedType = this.managedType;
  }

  async getClinicNote(noteId: any) {
    if (noteId) {
      const clinicNote: any = await this.clinicNoteService.getNote(noteId);
      clinicNote.isBillable = clinicNote.isBillable ? 1 : 0;
      clinicNote.date = dayjs(clinicNote.date).format('DD/MM/YYYY hh:mm');
      clinicNote.attributableBillDate = clinicNote.attributableBillDate
        ? dayjs(clinicNote.attributableBillDate).format('DD/MM/YYYY')
        : null;
      clinicNote.minsTaken = this.businessService.calcHoursAndMinutesReverse(
        clinicNote.timeTaken
      );
      this.clinicNote = clinicNote;
    }
  }

  async getPastNotes(clientId: any) {
    if (clientId) {
      let notes: any = await this.clinicNoteService
        .getNotesByClient(clientId)
        .catch(() => {
          this.toastrService.error(
            'Error',
            'Something went wrong while retrieving notes data. Please contact administrator'
          );
        });

      notes = orderBy(notes, (a) => a.date, 'desc');
      this.pastNotes = notes;
      console.log('this.pastNotes', this.pastNotes);
    } else {
      this.pastNotes = [];
    }
  }

  onSelectTemplate() {
    this.clinicNote.detail = '';
    this.selectedTemplate = find(
      this.noteTemplates,
      (n) => n.id == this.selectedTemplateId
    );
    this.clinicNote.detail = this.selectedTemplate?.detail;
    this.editor.root.innerHTML = this.clinicNote.detail;
  }

  onSelectPastNote() {
    this.clinicNote.detail = '';
    const selectedPastNote = find(
      this.pastNotes,
      (n) => n.id == this.selectedPastNoteId
    );
    this.clinicNote.detail = selectedPastNote.detail;
    this.editor.root.innerHTML = this.clinicNote.detail;
  }

  onSelectClient(clientId: any) {
    this.getPastNotes(clientId);

    // lookup client and check cos levels, then change this.levels to match available levels for client
    this.clientService.getClient(clientId).then((data: any) => {
      this.levels = [];
      if (data.isCOSLevel1) this.levels.push(1);
      if (data.isCOSLevel2) this.levels.push(2);
      if (data.isCOSLevel3) this.levels.push(3);

      this.checkCOSLevelDisplay();

      this.client = data;
    });
  }

  onSelectManagedType(manaegdType: any) {
    this.checkCOSLevelDisplay();
    //this.setCOSDateAsToday();
    //this.setIsNDISClientsForCOS();
  }

  checkCOSLevelDisplay() {
    if (this.levels.length == 1 && this.clinicNote.managedType == 'COS') {
      this.clinicNote.level = this.levels[0];
    }
  }

  setCOSDateAsToday() { 
    if (this.clinicNote.managedType === 'COS' && this.isNew) {
      this.clinicNote.date = dayjs().format('DD/MM/YYYY');
    }
  }

  async setIsNDISClientsForCOS() {
    if (this.clinicNote.managedType === 'COS') {
      this.clients = filter(this.clients, c => c.isNDISClient);
    }
  }

  validateHoursItem(item: any) {
    const hours = item.timeTaken
      ?.replace('(', '')
      .replace(')', '')
      .replace('m', '')
      .replace('h', '');
    if (hours.indexOf('_') > -1) {
      item.timeTaken = '';
      //item.hoursAmount = '';
    }
  }

  validateMinsItem(item: any) {
    item = item.toString();
    if (item.indexOf('_') > -1) {
      this.clinicNote.minsTaken = '';
    }
    if (item.length === 3 && item[0] === '0') {
      this.clinicNote.minsTaken = '';
    }
  }

  initCKEditor(htmlContent = '') {
    var options = {
      placeholder: 'Compose a note...',
      theme: 'snow',
      modules: {
        toolbar: [
          [{ font: [] }, { size: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'super' }, { script: 'sub' }],
          [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['direction', { align: [] }],
          ['link', 'image', 'formula'], // 'video',
          ['clean'],
        ],
        imageDrop: true,
        imageResize: {
          displayStyles: {
            backgroundColor: 'black',
            border: 'none',
            color: 'white',
          },
          modules: ['Resize', 'DisplaySize', 'Toolbar'],
        },
        clipboard: {
          matchVisual: false,
        },
      },
    };

    setTimeout(() => {
      this.editor = new Quill(
        this.caller ? `.${this.caller}` : '#editor',
        options
      );
      // this.editor.clipboard.dangerouslyPasteHTML(htmlContent);
      this.editor.root.innerHTML = htmlContent;
    }, 100);
  }

  saveNote(status: string) {
    this.clinicNote.status = status;

    const action = this.clinicNote.id
      ? this.updateNote.bind(this)
      : this.createNote.bind(this);

    // if (note.jobId) {
    //   saveNote.jobId = note.jobId;
    // }
    // if (note.clientId) {
    //   saveNote.clientId = note.clientId;
    // }

    // console.log('this.currentUser.employeeId', this.currentUser.employeeId);
    // console.log('saveNote', saveNote);
    // console.log('note', note);
    // console.log('this.selectedSchedule', this.selectedSchedule);

    action()
      .then((res: any) => {
        this.uploadNotesFile(res?.id || this.clinicNote.id);
        if (this.callback) {
          this.callback();
        }
        this.toastrService.success('Sucess', 'Saved succesfully');
        this.closeModal();
      })
      .catch((err) => {
        this.toastrService.error(
          'Error',
          'Something went wrong. Please contact administrator'
        );
      });
  }

  createSaveNoteObject(noteObject: any) {
    const isSession = (this.clinicNote?.type || '').toLowerCase() === 'session outcomes';

    const note = cloneDeep(noteObject);
    note.date =
      typeof note.date === 'string'
        ? dayjs(note.date, 'DD/MM/YYYY').format('YYYY-MM-DD hh:mm')
        : dayjs(note.date).format('YYYY-MM-DD hh:mm');
    //note.timeTaken = this.businessService.calcHoursAndMinutes(note.timeTaken);
    //note.isBillable = note.isBillable == true || note.isBillable == 1;
    note.clientId =
      note.clientId || this.clientId || this.selectedSchedule?.job?.clientId;
    note.jobId = note.jobId || this.selectedSchedule?.job?.id;
    note.detail = this.editor.root.innerHTML;
    //if (note.attributableBillDate) { note.attributableBillDate = dayjs(note.attributableBillDate).format('YYYY-MM-DD'); }

    let noteDate = ''
    let noteDatetime = '';

    // noteDate = dayjs(note.date).format();
    // noteDate = noteDate.split('+')[0];

    // if (note.noteDate) {
    //   noteDatetime = dayjs(note.noteDate).format();
    //   noteDatetime = noteDatetime.split('+')[0]
    // } else {
    //   noteDatetime = noteDate;
    // }

    noteDate = dayjs(note.date).format('YYYY-MM-DD');

    if (note.noteDate) {
      const noteTime = dayjs(note.noteDate).format('HH:mm:ss'); // Extracting the time part
      noteDatetime = dayjs(`${noteDate} ${noteTime}`).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      noteDatetime = dayjs(noteDate).format('YYYY-MM-DDTHH:mm:ss');
    }

    const saveNote = {
      id: note.id,
      jobId: note.jobId || this.selectedSchedule?.job?.id,
      detail: note.detail,
      managedType: note.managedType,
      type: note.type,
      level: note.level,
      isActive: note.isActive,
      alertType: note.alertType,
      employeeId: note.id
        ? this.selectedSchedule?.employeeId
        : this.currentUser.employeeId,
      clientId: note.clientId,
      minsTaken: note.minsTaken,
      timeTaken: isSession ? 0 : Math.round((note.minsTaken / 60) * 1e2) / 1e2,
      date: noteDate,   // date was the old field and may be removed in future
      noteDate: noteDatetime,
      status: note.status
    };

    console.log('saveNote', saveNote);

    return saveNote;
  }

  createNote() {
    if (this.clinicNote.level === null) delete this.clinicNote.level;
    let createNote = this.createSaveNoteObject(this.clinicNote);
    delete createNote.id;
    return this.clinicNoteService.createNote(createNote);
  }

  updateNote() {
    let patchNote = this.createSaveNoteObject(this.clinicNote);
    // TODO check whether removing these is ok
    delete patchNote.employeeId;
    // delete patchNote.date;

    return this.clinicNoteService.patch(patchNote);
  }

  deleteNoteConfirm(noteId: any) {
    Swal.fire({
      title: 'Are you sure?',
      text:
        'Are you sure you want to delete this note.' +
        '\n' +
        'This action cannot be undone',
      input: 'password',
      icon: 'warning',
      inputPlaceholder: 'Enter your pin code to confirm',
      inputAttributes: {
        maxlength: '6',
        autocapitalize: 'off',
        autocorrect: 'off',
      },
      showCancelButton: true,
      confirmButtonText: 'Yes, I am sure!',
      cancelButtonText: 'Cancel',
    }).then((swalData) => {
      if (swalData.isDismissed) {
        console.log('cancel');
      } else {
        if (swalData.value === '555') {
          this.deleteNote(noteId);
        } else {
          Swal.fire('Cancelled', 'Invalid PIN. Please try again', 'error');
        }
      }
    });
  }

  deleteNote(noteId: any) {
    // might need to check if linked to other field in db with foreign key first

    this.clinicNoteService
      .deleteNote(noteId)
      .then((data: any) => {
        this.closeModal();
      })
      .catch((error) => {
        // could not get response from server
        this.toastrService.error('Error', 'Could not delete note');
        console.log('errors', error);
      });
  }

  closeModal() {
    this.clinicNoteModal.hide();
    this.clinicNote = {};
    this.selectedPastNoteId = '';
    this.selectedTemplateId = '';
  }

  onModalHide() {
    this.destroyQuill();
    this.clinicNote = {};
    this.model = {};
    this.selectedTemplate = '';
    this.selectedPastNoteId = '';
    this.selectedTemplateId = '';
  }

  destroyQuill() {
    const editorDiv = document.querySelector(`#editor.${this.caller}`);
    const toolbar = document.querySelector('.ql-toolbar.ql-snow');

    if (editorDiv) {
      editorDiv.innerHTML = '';
      editorDiv.className = `mb-4 ${this.caller}`;
      editorDiv.removeAttribute('style');
    }

    if (toolbar) {
      toolbar.remove();
    }
  }

  onManagedTypeChange() {
    if (this.clinicNote.managedType === 'Therapy') {
      this.clinicNote.type = 'Session Outcomes';
    }
  }

  onUploadFileClickEdit(drop: any) {
    drop.showFileSelector();
  }

  resetFileUpload() {
    this.clinicNote.notesFile = null;
  }

  onFilesSelect(event: any) {
    if (event.addedFiles) {
      if (event.addedFiles.length > 1) {
        this.toastrService.error(
          'Cannot upload multiple files. Make sure you select one file only'
        );
        return;
      }
      this.clinicNote.notesFile = event.addedFiles[0];
    }
  }

  async uploadNotesFile(noteId: any) {
    const notesFile = this.clinicNote.notesFile;
    if (!notesFile) return;
    let fileName = notesFile.name;
    const formData = new FormData();
    formData.append('file', notesFile, fileName);
    this.documentService.uploadDocument(formData).subscribe((event: any) => {
      if (event.type === HttpEventType.Response) {
        let data = event.body?.data;

        const saveItem: any = {
          id: noteId,
          documentId: data.documentId, //id of newly uploaded notes document
        };

        const res = this.clinicNoteService.patch(saveItem);
      }
    });
  }

  isNoteValid() {
    const note = this.clinicNote;
    var valid = note.type && this.editor?.root?.innerHTML !== '<p><br></p>';

    //if (note.isBillable && note.isBillable != 0) { valid &&= note.attributableBillDate; }

    // if (this.selectedSchedule?.workType != 'SUPP') {
    //   valid &&= note.date;
    // }

    if (!this.clientId && !this.selectedSchedule?.job?.clientId) {
      valid &&= note.clientId;
    }

    if (note.type !== 'Session Outcomes') {
      valid &&= note.minsTaken;
    }

    // if (note.managedType === 'COS') {
    //   valid &&= note.noteDate;
    // }

    return valid;
  }

  expandModal() {

  }


  showTabNotes() {
    this.tabSelection = 'notes';
  }

  showTabSA(item, item2) {
    this.tabSelection = 'sa';
  }

  showTabPlans(item) {
    this.tabSelection = 'plans';
  }

  showTabOther() {
    this.tabSelection = 'other';
  }

  saveLongTermActionPlan() {
    this.clientService.patchClient({id: this.client.id, longTermCOSActionPlan: this.client.longTermCOSActionPlan})
    .then(() => this.toastrService.success('Saved data'))
    .catch(() => this.toastrService.error('Something went wrong. Please contact administrator'));
  }

  @Input()
  set managedType(value: any) {
    //console.log('value', value)
    this._managedType = value;
    this.clinicNote = { managedType: this.managedType };
    this._isLockManagedType = true;
  }

  get managedType(): any {
    return this._managedType;
  }

  get isLockManagedType(): any {
    return this._isLockManagedType;
  }
}
