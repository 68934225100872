<div class="wrapper d-flex flex-column">
    <!-- top navbar-->
    <app-header #header class="topnavbar-wrapper"></app-header>
    <!-- menu -->
    <!-- <app-horizontal></app-horizontal> -->
    <!-- sidebar old left side menu-->
    <!-- <app-sidebar class="aside-container"></app-sidebar> -->
    <div class="todo-button v-text" (click)="toggleTaskPanel()">
        <span>TASKS</span>
    </div> 
    <!-- <div class="clients-button v-text" (click)="toggleClientPanel()">
        <span><i class="fa-solid fa-people-group"></i></span>
    </div>       -->
    <!-- offsidebar-->
    <app-offsidebar class="offsidebar"></app-offsidebar>
     <!-- offsidemessage panel -->
     <app-offside-message-panel class="offsidebar offsideMessagePanel"></app-offside-message-panel>
      <!-- app-panel -->
    <app-offside-panel class="offsidePanel tasks"></app-offside-panel>
      <!-- app-offsidePdfPanel -->
    <app-offside-pdf-panel class="offsidePdfPanel"></app-offside-pdf-panel>
    <!-- <app-offside-panel class="offsideMessagePanel"></app-offside-panel> -->
    <!-- Main section-->
    <section class="section-container">
        <!-- Page content-->
        <div class="content-wrapper">
            <router-outlet></router-outlet>
            <div id="modalContainer">
                <modal-container #modalContainer [modalId]="header.modalId"></modal-container>
            </div>
        </div>
    </section>
    <!-- Page footer-->
    <footer class="footer-container" app-footer></footer>
</div>
