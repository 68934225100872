import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OutletService } from '@core/services/outlet.service';
import Swal from 'sweetalert2'

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { OutletComponent } from '@components/modal/outlet/outlet.component'
import { ScrollableDirective } from 'src/app/shared/directives/scrollable/scrollable.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgIf, NgFor, TitleCasePipe } from '@angular/common';
@Component({
  selector: 'app-client-outlets',
  templateUrl: './client-outlets.component.html',
  styleUrls: ['./client-outlets.component.scss'],
  standalone: true,
  imports: [
    //CommonModule,
		FormsModule,
		ReactiveFormsModule,
    ScrollableDirective,
    ModalModule,
    OutletComponent,
    NgIf, NgFor, TitleCasePipe
  ]
})
export class ClientOutletsComponent implements OnInit {
  @Input() callback: any;
  @Input() clientId: any;
  @Input() outlets: any;
  isOutletNew: boolean;
  selectedOutlet: any;

  constructor(private outletService: OutletService, private toastrService: ToastrService) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.outlets && changes.outlets.currentValue) {
      this.outlets = changes.outlets.currentValue;
    }
  }

  showOutletModal(outlet: any, modalCtrl: any) {
    this.selectedOutlet = outlet;
    this.isOutletNew = !outlet;
    modalCtrl.show();
  }

  deleteOutlet(outlet: any) {
    Swal.fire({
      title: 'Are you sure?',
      html: 'Delete this Outlet?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((res: any) => {
      if (res.value) {
        this.outletService.deleteOutlet(outlet.id).then(() => {
          this.toastrService.success('Success', 'Deleted Outlet');
        }).catch((err) => {
          this.toastrService.error('Error', `Something went wrong while trying to delete record. Err: ${err}`);
        }).finally(() => {
          if (this.callback) { this.callback(); }
        });
      };
    });
  }
}
