import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { JsonPipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { TodoService } from '@core/services/todo.service';

import dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { find, map } from 'lodash-es';
import { SettingsService } from '@core/settings/settings.service';
import { CourseService } from '@core/services/training/course.service';
import { BusinessService } from 'src/app/services/business.service';

@Component({
    selector: 'edit-todo-component',
    templateUrl: './edit-todo.component.html',
    styleUrls: ['./edit-todo.component.scss'],
    standalone: true,
    imports: [
        NgFor, NgIf, TitleCasePipe,
        FormsModule,
        ModalModule,
        BsDatepickerModule,
        ReactiveFormsModule
    ]
})
export class EditTodoComponent {
    todoForm: FormGroup;
    @Input() isNew = null;
    @Input() callback;
    @Input() container = null;
    @Input() model = null;
    @Input() employees: any = [];
    @ViewChild('todoModal', { static: true }) todoModal;
    @Output() save: EventEmitter<void> = new EventEmitter<void>();

    categories: any = this.businessService.getTodoCategories();
    currentOrganisation: any = {};
    displayField: any = [];
    currentUser: any = {};
    courses: any = [];
    actionStatuses: any = this.businessService.getTodoStatuses();

    constructor(
        private todoService: TodoService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService,
        private businessService: BusinessService,
        private courseService: CourseService,
        private toastrService: ToastrService) { }

    ngOnInit() {
        this.initForms();
        this.initFields();

        let user = this.settingsService.getUserSettings()
        this.currentUser = user;           
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            if (changes.model.currentValue) {
                if (!this.todoForm) {
                    this.initForms();
                }
                this.setupForm(changes.model.currentValue);
                this.isNew = false;
            } else {
                this.isNew = true;
                this.todoForm?.reset();
            }
        }
    }

    setupForm(model: any) {
        map(model.registrysActions, action => {
            action.employeeName = find(this.employees, e => e.id === action.allocatedToUser).fullName || ''
        })
        if (model.date === '0001-01-01T00:00:00') { model.date = null; }
        if (model.estimatedCompletedDate === '0001-01-01T00:00:00') { model.estimatedCompletedDate = null; }
        this.todoForm?.patchValue(model);
    }

    initFields() {
        this.displayField = { mainText1: {}, mainText2: {}, mainText3: {}, mainText4: {}, source: {}, sourceEmail: {}, sourcePhone: {}, category: {}, location: {}, allocatedTo: {}, actionedBy: {}, level: {}, residualLevel: {}, completedDate: {}, reviewDate: {}, status: {}, satisfaction: {}, orgRole: {} };
        map(this.displayField, (v, key) => this.displayField[key] = { title: '', show: false, order: '', placeholder: '' });

        this.displayField.status = { title: 'Status', show: true };
        this.displayField.dueDate = { title: 'Due Date', show: true };       
        this.displayField.description = { title: 'Description', show: true, placeholder: '' };
        this.displayField.otherDetails = { title: 'Other Details', show: true, placeholder: '' };
        this.displayField.allocatedTo = { title: 'Allocated To', show: true };
        this.displayField.actionedBy = { title: 'Actioned By', show: true };
        this.displayField.completedDate = { title: 'Completed Date', show: false };
        this.displayField.category = { title: 'Category', show: true };
        this.displayField.courseId = { title: 'Link to Course', show: false };

        this.getCourses();
    }

    initForms() {

        // default form
        const todoObject: any = {
            id: [''],
            dueDate: ['', Validators.required],                   
            description: ['', Validators.required],           
            otherDetails: [''],           
            category: ['', Validators.required],
            status: [''],           
            employeeId: [''],
            courseId: [''],
            assignedByEmployeeId: [''],           
            completedDate: [''],
            userId: ['']           
        };

        if (!this.todoForm) this.todoForm = this.formBuilder.group(todoObject);
    }

    getCourses() {
        this.courseService.get().then((data: any) => { this.courses = data; });
    }

    async saveItem(modalCtrl: any) {
        const imp = this.todoForm.value;

        // if ((!imp.category || !imp.feedbackSource || !imp.practiceStandard) && imp.status != 'PENDING') {
        //   Swal.fire('Warning', "Category, Feedback Source and Practice Standard must be set to change status away from Pending", "warning");
        //   return false;
        // }

        const saveItem = {
            id: imp.id,            
            dueDate: dayjs(imp.date).format('YYYY/MM/DD'),
            category: imp.category,
            //comment: imp.comment,           
            status: imp.status ? imp.status : 'not started',    
            courseId: imp.courseId,                
            employeeId: this.currentUser.employeeId,    //  imp.allocatedTo,
            assignedByEmployeeId: this.currentUser.employeeId, // imp.actionedBy,
            description: imp.description,
            //otherDetails: imp.otherDetails,
            //completedDate: imp.completedDate ? dayjs(imp.completedDate).format('YYYY/MM/DD') : '1901-01-1',
            //reviewDate: imp.reviewDate ? dayjs(imp.reviewDate).format('YYYY/MM/DD') : '1901-01-1',
        };

        map(saveItem, (val, prop) => {
            if (this.displayField[prop] && !this.displayField[prop].show) {
                delete saveItem[prop];
            }
        })

        let action = saveItem.id ? this.todoService.patch(saveItem) : this.todoService.create(saveItem);
        await action.then((data: any) => {
            if (this.callback) { this.callback(); }
            this.toastrService.success('Saved');
        }).catch(() => {
            this.toastrService.error('Something went wrong. Please contact administrator');
        }).finally(() => {
            this.closeModal();
        });
    }

    confirmDelete(id: any) {
        Swal.fire({
            title: 'Are you sure?',
            html: `Delete this record ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes!',
            cancelButtonText: 'Cancel'
        }).then((result: any) => {
            if (result.value) {
                this.delete(id);
            }
        });
    }

    delete(id: any) {
        this.todoService.delete(id).then(() => {
            this.toastrService.success('Deleted item');
            this.closeModal();
            if (this.callback) { this.callback(); }
        }).catch(() => {
            this.toastrService.error('Something went wrong. Please contact administrator');
        });
    }

    open() {
        this.todoModal.show()
    }

    closeModal() {
        this.save.emit();
    }
}
