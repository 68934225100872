import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RecoverComponent } from './recover/recover.component';
import { LockComponent } from './lock/lock.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {  SocialLoginModule, GoogleSigninButtonModule } from "@abacritt/angularx-social-login";
import { ResetComponent } from './reset/reset.component';
import { TrialComponent } from './trial/trial.component';


/* Use this routes definition in case you want to make them lazy-loaded */
const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'trial', component: TrialComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'reset', component: ResetComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
];

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        SocialLoginModule,
        GoogleSigninButtonModule
        // RouterModule.forChild(routes)
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
        UnauthorizedComponent,
        ResetComponent,
        TrialComponent
    ],
    exports: [
        RouterModule,
        LoginComponent,
        RegisterComponent,
        TrialComponent,
        RecoverComponent,
        LockComponent,
        MaintenanceComponent,
        Error404Component,
        Error500Component,
        UnauthorizedComponent
    ]
})
export class PagesModule { }
