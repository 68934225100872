<form [formGroup]="todoForm" (ngSubmit)="saveItem(todoModal)" autocomplete="off">
  <div class="row">
    <div class="col-md-6">

      <!-- description -->
      <div class="mb-3" *ngIf="displayField.description?.show">
        <h5 class="fontSizeM" role="none">{{displayField.description?.title}}</h5>
        <input class="form-control" type="text" placeholder="Fill in details" formControlName="description"
          [class.err]="!todoForm.get('description').valid" />
      </div>

      <!-- other details -->
      <div class="mb-2">
        <h5 class="fontSizeM" role="none">{{displayField.otherDetails?.title}}</h5>
        <textarea class="form-control" rows="5" placeholder="Fill in details" formControlName="otherDetails"></textarea>
      </div>

      <!-- due date -->
      <div class="mb-3">
        <h5 class="fontSizeM" role="none">Due Date</h5>
        <div class="d-flex">
          <input type="text" placeholder="Due Date" class="form-control me-1 halfling" bsDatepicker
            [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue'}" formControlName="dueDate"
            [class.err]="!todoForm.get('dueDate').valid">
        </div>
      </div>

      <!-- allocated to -->
      <div class="mb-2" *ngIf="displayField.allocatedTo?.show">
        <h5 class="fontSizeM" role="none">Allocated To</h5>
        <select class="form-select form-select-sm" formControlName="userId">
          <option value="">Select Allocated Staff ...</option>
          <option *ngFor="let emp of this.employees" [value]="emp.id">{{emp.fullName | titlecase}}</option>
        </select>
      </div>

    </div>
    <div class="col-md-6">

      <!-- category as dropdown -->
      <!-- <div class="mb-2" *ngIf="displayField.category?.show">
                <h5 class="fontSizeM" role="none">{{displayField.category.title}}</h5>
                <select class="form-select form-select-sm" formControlName="category" [class.err]="!todoForm.get('category').valid">
                  <option value="">Select Category ...</option>
                  <option *ngFor="let category of categories" [value]="category">
                    {{category}}
                  </option>
                </select>
              </div> -->

      <!-- Category as radio buttons -->
      <div class="mb-2" *ngIf="displayField.category?.show">
        <h5 class="fontSizeM" role="none">{{displayField.category.title}}</h5>
        <div *ngFor="let category of categories" class="form-check">
          <input class="form-check-input" type="radio" formControlName="category" [id]="category" [value]="category">
          <label class="form-check-label" [for]="category">{{ category }}</label>
        </div>
      </div>


      <!-- status -->
      <div class="mb-2" *ngIf="displayField.status?.show">
        <h5 class="fontSizeM" role="none">Status</h5>
        <select class="form-select form-select-sm" formControlName="status">
          <option value="">Select Status ...</option>
          <option *ngFor="let status of actionStatuses" [value]="status">{{status | titlecase}}</option>
        </select>
      </div>


      <!-- Course -->
      <div class="mb-2" *ngIf="displayField.courseId?.show">
        <h5 class="fontSizeM" role="none">Course Link</h5>
        <select class="form-select form-select-sm" formControlName="courseId">
          <option value="">Select Course ...</option>
          <option *ngFor="let course of courses" [value]="course.id">
            {{course.title}}
          </option>
        </select>
      </div>

      <!-- actioned by -->
      <div class="mb-2" *ngIf="displayField.assignedByUserId?.show">
        <h5 class="fontSizeM" role="none">Actioned By</h5>
        <select class="form-select form-select-sm" formControlName="assignedByUserId">
          <option value="">Select Actioned By Staff ...</option>
          <option *ngFor="let emp of this.employees" [value]="emp.id">{{emp.fullName | titlecase}}</option>
        </select>
      </div>

      <!-- <div class="mb-2">
                Priority
              </div> -->
      <div class="mb-2" *ngIf="displayField.completedDate?.show">
        <h5 class="fontSizeM" role="none">{{displayField.completedDate?.title}}</h5>
        <div class="d-flex">
          <input type="text" placeholder="Start Date" class="form-control me-1 halfling" bsDatepicker
            [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue'}" formControlName="completedDate"
            [class.err]="!todoForm.get('completedDate')?.valid">
        </div>
      </div>

      <!-- review date -->
      <div class="mb-3" *ngIf="displayField.reviewDate?.show">
        <h5 class="fontSizeM" role="none">{{displayField.reviewDate?.title}}</h5>
        <div class="d-flex">
          <input type="text" placeholder="Start Date" class="form-control me-1 halfling" bsDatepicker
            [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-blue'}" formControlName="reviewDate"
            [class.err]="!todoForm.get('reviewDate').valid">
        </div>
      </div>



      <!-- mainText 4 -->
      <div class="mb-2" *ngIf="displayField.mainText4?.show">
        <h5 class="fontSizeM" role="none">{{displayField.mainText4?.title}}</h5>
        <textarea class="form-control" rows="5" placeholder="Fill in reasons" formControlName="mainText3"></textarea>
      </div>


      <!-- satisfaction rating  -->
      <div class="mb-2" *ngIf="displayField.satisfaction?.show">
        <h5 class="fontSizeM" role="none">{{displayField.satisfaction.title}}</h5>
        <select class="form-select form-select-sm" formControlName="satisfaction">
          <option value="">Select Rating ...</option>
          <!-- <option *ngFor="let category of categories" [value]="category.id">
                    {{category.name}}
                  </option> -->
        </select>
      </div>
      <!-- <div class="mb-3">
                Responsibility
              </div> -->
    </div>

  </div>
</form>

<button type="button" class="btn btn-xs btn-gray ms-auto me-2" id="cancelSidebar" (click)="closeModal()">Cancel</button>
<button type="submit" class="btn btn-xs btn-primary ms-1 me-2" (click)="saveItem(todoModal)"
  [disabled]="!todoForm.valid">
  <em class="fa fa-fw fa-edit"></em>Save</button>
<button type="button" class="btn btn-xs btn-danger float-right" (click)="confirmDelete(todoForm.value.id)"
  *ngIf="todoForm?.value?.id"><em class="fa fa-fw fa-trash"></em> Delete</button>