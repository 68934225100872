import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  handleError(error: any): void {
    console.error('Global Error Handler:', error);
  } 

  // handleError(error: any): void {
  //  const chunkFailedMessage = /Loading chunk [\d]+ failed/;

  //   if (chunkFailedMessage.test(error.message)) {
  //       if(confirm("Web page needs to reload ok ? ")) {
  //           window.location.reload();
  //         }
  //   } else {
  //     // TODO better handle all errors, log etc but for now put in console for dev
  //     console.warn('error', error);
  //   }
  // }
}