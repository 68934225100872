import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'customdate'
})
export class CustomdatePipe implements PipeTransform {

  transform(dateString: string, ...args: any) {
    let result = '';
    let date: any;
    if (!dateString) {
      return '';
    } else {
      if (args[0] && args[1]) {
        date = dayjs(dateString, args[1]);
        result = date ? date.format(args[0]) : '';
      } else if (args[0]) {
        date = dayjs(dateString, 'DD/MM/YYYY');
        if (!date.isValid()) {
          date = dayjs(dateString);
        }
        result = date ? date.format(args[0]) : '';
      } else {
        date = dayjs(dateString);
        result = date.format('MMM D, YYYY');
      }
    }
    return result;
  }

}
