<div class="loaderSpin">
	<div class="loader-inner line-scale-party">
	  <div></div>
	  <div></div>
	  <div></div>
	  <div></div>
	</div><span class="tooltip">
	  <p>loading data ... </p></span>
</div>

<!-- <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
  </div> -->