import { DatePipe, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsService } from '@core/settings/settings.service';
import { SharedModule } from '@shared/shared.module';
import { cloneDeep, filter, flatMap, get, map, orderBy, startCase, uniqBy } from 'lodash-es';
import { Observable, OperatorFunction, debounceTime, map as maprx } from 'rxjs';
import { BusinessService } from 'src/app/services/business.service';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { JobService } from '@core/services/job.service';
import { ScrollableDirective } from '@shared/directives/scrollable/scrollable.directive';
import { ClientService } from '@core/services/client/client.service';

@Component({
  selector: 'app-rosters-mini',
  templateUrl: './rosters-mini.component.html',
  styleUrls: ['./rosters-mini.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, SharedModule, TitleCasePipe, DatePipe, NgbTypeaheadModule]
})
export class RostersMiniComponent implements OnInit {

  allRosters: any = [];
  clients: any = [];
  currentUser: any;
  isLoading: boolean;
  rosters: any = [];
  searchInput: any;
  selectedClientId: any;
  selectedClient: any = {};
  showAddEditComponent: boolean = false;
  schedules: any = [];

  search: OperatorFunction<string, readonly { fullName }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      maprx((term) =>
        term === ''
          ? []
          : this.clients.filter((c) => c.fullName.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );

  formatter = (x: { fullName: string }) => x.fullName;
  screenHeight: number;
  startDate: string;
  endDate: string;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight - 240;
  }

  constructor(private router: Router,
    private settingsService: SettingsService,
    private businessService: BusinessService,
    private clientService: ClientService,
    private jobService: JobService) {
      this.screenHeight = window.innerHeight - 240;
     }

  ngOnInit() {
    this.init();
    this.load();
  }

  init() {
    let user = this.settingsService.getUserSettings()
    this.currentUser = user;
    this.currentUser.isAdmin = get(this.currentUser, 'rolesString', '').includes('Admin');
    this.currentUser.isOfficeManager = get(this.currentUser, 'rolesString', '').includes('Office Manager');

    this.startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
    this.endDate = dayjs().add(1, 'day').format('YYYY-MM-DD');

    // this.internalMailService.rostersUpdate.subscribe(() => {
    //   this.getRosters();
    // })
  }

  load() {
    this.init();
    this.getClients();
  }

  async getClients() {
    this.clients = await this.clientService.getClientIds();
  }

  searchRosters(source: any = this.allRosters) {
    const desc = this.searchInput?.description || this.searchInput || '';
    this.rosters = source.filter(r => r.client?.fullName?.toLowerCase().includes(desc.toLowerCase()));
  }

  async onSearchItemSelected() {
    this.isLoading = true;
    const clientJobs: any = await this.jobService.getClientJobs(this.selectedClientId, this.startDate, this.endDate)
      .catch(() => { console.error('Error', 'Something went wrong while retrieving roster data for client'); });
    let rosters: any = await this.businessService.mapRosterData(clientJobs);
    this.rosters = orderBy(rosters, a => a.scheduledFrom, 'asc');
    console.log('Rosters', this.rosters);
    // add rosters id to each schedule
    this.rosters = map(this.rosters, r => {
      r.schedules = map(r.schedules, s => {
        s.rosterId = r.id;
        return s;
      });
      return r;
    });
    // we have rosters.schedules array on each rosters. make a flat array of all schedules
    let schedules = flatMap(this.rosters, r => r.schedules);
    // add roster back on the end of each schedule
    schedules = map(schedules, s => {
      s.roster = filter(this.rosters, r => r.id === s.rosterId)[0];
      return s;
    });
    console.log('Schedules', schedules);
    this.schedules = schedules;

    // add client details from roster to this.selectedClient
    this.selectedClient = this.clients.find(c => c.id === this.selectedClientId);
    

    this.allRosters = cloneDeep(this.rosters);
    this.isLoading = false;
  }

  gotoClientDetail(clientId: any) {
      this.router.navigate(['/clients/detail/' + clientId]);
  }
}
