import { NgModule, effect } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
//import { SharedModule } from '../shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { GlobalMenuService } from '../services/globalmenu.service';
import { routes } from './routes';
import { AuthService } from '../core/services/auth.service';

@NgModule({
    imports: [
        //SharedModule,
        RouterModule.forRoot(routes, {}),
        PagesModule
    ],
    declarations: [
  ],
    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    user: any;
    userProfile: any;

    constructor(
        tr: TranslatorService,
        private menuService: GlobalMenuService,
        private authService: AuthService
    ) {
        let data = this.authService.currentUser;

        effect(() => {
            this.menuService.setMenu(data());
        });              
    }
}
