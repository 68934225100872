import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalMailService } from '@core/services/internal-mail.service';
import { JobService } from '@core/services/job.service';
import { MailService } from '@core/services/mail.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { TranslatorService } from '../../../core/translator/translator.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

import find from 'lodash-es/find';

@Component({
    selector: 'app-offside-message-panel',
    templateUrl: './offside-message-Panel.component.html',
    styleUrls: ['./offside-message-panel.component.scss']
})
export class OffsideMessagePanelComponent implements OnInit, OnDestroy {
    @ViewChild('offsidebarpaneltabs') tabset: TabsetComponent;
    app: any = {};
    clients: any = [];
    contents: string;
    currentTheme: any;
    mailForm: FormGroup;
    organisation: any = {};
    selectedLanguage: string;
    showCC = false;
    showBCC = false;
    showJobNumber = false;
    showClientName = false;
    tabsOn = false;
    template: any = [{}, {}];
    user: any = {};
    users: any = [];
    allUsers: any = [];

    constructor(public settings: SettingsService,
        public translator: TranslatorService,
        private internalMailService: InternalMailService,
        private mailService: MailService,
        private jobService: JobService,
        public elem: ElementRef,
        private toastrService: ToastrService,
        public formBuilder: FormBuilder,
        private settingsService: SettingsService,
        public route: ActivatedRoute,
        public router: Router) {

        this.selectedLanguage = this.getLangs()[0].code;
    }

    ngOnInit() {
        this.anyClickClose();
        this.initForm();

        let organisation = this.settingsService.getOrgSettings();
        this.organisation = organisation; 
        let user = this.settingsService.getUserSettings()
        this.user = user;

        let app = this.settingsService.getAppSettings()
        let modules = [];
        if (app.pageRequiredModules) {
            app.pageRequiredModules.forEach((obj, index) => {
                modules.push( {code: obj, assigned: find(this.organisation.modules, o => o.code === obj ) != null })
            }); 
        }
        app.modules = modules;
        this.app = app;
    }

    ngAfterViewInit() {
        this.internalMailService.subject.subscribe((data: any) => {
            this.mailForm.patchValue({ 
                jobId: data.jobId ,
                clientId: data.clientId,
                jobNumber: data.jobNumber,
                title: data.title,
                details: data.details
            });
        })

        // this.clientService.getClientIdsObs().subscribe((data: any) => {
        //     this.clients = data;
        // });

        // this.Service.getUsersIdsObs('admin').subscribe((data: any) => {
        //     this.adminUsers = data;
        // });

       
        // users
        // this.roleService.getAllOrganisationsAccessesObs().subscribe(function (data: any) {
        //     const filters = ['A', 'OM', 'OS', 'AM'];    // not FS
        //     const users = filter(data, a => filters.includes(a.organisationRole.code));

        //     self.users = users;
        //     console.log('offside panel user', users);
        // });
          
        
      }

    setTheme() {
       // this.themes.setTheme(this.currentTheme);
    }

    getLangs() {
        return this.translator.getAvailableLanguages();
    }

    setLang(value: any) {
        this.translator.useLanguage(value);
    }

    addUserToMail(item: any) {
        this.mailForm.patchValue({recipientUserId: item.userId});
        console.log('this.tabset', this.tabset.tabs);
        this.tabset.tabs[0].active = true;
    }

    async SaveEmail() {
        const m: any = this.mailForm.value;

        let jobId;
        // TODO would this be better on server?
        let job:any = await this.jobService.getJobByJobNumber(m.jobNumber);
        if (job) { jobId = job.id; }
        
        const newMail = {
            recipientUserId: m.recipientUserId,   
            title: m.title,
            details: m.details,
            jobId:  jobId ? jobId : null,    // need to convert the job number to jobId
            clientId: m.clientId ? m.clientId : null
        }

        console.log('newMail', newMail);
        this.mailService.createMail(newMail).then(data => {
            console.log('newMail', data);
            this.toastrService.success('Success', `Your message has been sent.`);
            this.close();
        }).catch(e => {
            this.toastrService.error('Error', `Something went wrong while sending the message.`);
        })
    }

    addTemplateText(id: any) {
        let text = "";
        this.template[id].selected = true;
        switch(id) {
            case 0:
                text = "Please check the charges on this job for me"; 
                break;
            case 1:
                text = "Please check the staff time entries on this job for me";
                break;
            default:
                break;
        }
        if (text) {
            let newText = this.mailForm.value.details + "\n" + text;
            this.mailForm.patchValue( {details: newText} );
        }
    }

    cancel() {
       this.close();
    }

    close() {
        this.settings.setLayoutSetting('offsideMessagePanelOpen', false);
    }

    anyClickClose() {
       // document.addEventListener('click', this.checkCloseOffsidebarPanel, false);
    }

    checkCloseOffsidebarPanel = (e: any) => {
        const contains = (this.elem.nativeElement !== e.target && this.elem.nativeElement.contains(e.target));
        if (!contains) {
            this.settings.setLayoutSetting('offsideMessagePanelOpen', false);
        }
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.checkCloseOffsidebarPanel);
        // if (this.organisation) this.organisation.unsubscribe();
        // if (this.user) this.user.unsubscribe();
    }

    goToDetail(item: any) { }

    initForm() {
        this.mailForm = this.formBuilder.group({
            recipientUserId: [''],
            title: ['', Validators.required],
            details: [null, Validators.required],
            jobId: [''],
            jobNumber: [''],
            clientId: [''],            
          });
    }
}
