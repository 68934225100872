<div class="modal fade" bsModal #clinicNoteModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
  [config]="{backdrop: 'static'}" (onHide)="onModalHide()">
  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
    <div class="modal-content border-strong">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h4 class="modal-title">
          {{ isNew ? 'New' : '' }} Clinic Note
          <span *ngIf="selectedSchedule?.job">
            for job {{ selectedSchedule?.job?.jobNumber }}, {{ selectedSchedule?.job?.client?.fullName }}
            <span *ngIf="!isNew" class="text-sm">{{ selectedSchedule?.startDisplay }}</span>
          </span>
          <span *ngIf="client?.fullName">
            for {{ client?.fullName }}
          </span>
        </h4>
      
        <div class="d-flex align-items-center ms-auto">
          <select *ngIf="managedType == 'Therapy'" class="form-select form-select-sm me-4" (change)="onSelectPastNote()"
            [(ngModel)]="selectedPastNoteId">
            <option value="">Past Note ...</option>
            <option *ngFor="let note of pastNotes" [value]="note.id">
              {{ note.date | date }} {{ note.managedType }} <span *ngIf="note.level != null && note.level != 0">Level {{ note.level }}</span> {{ note.type }}
            </option>
          </select>
      
          <select class="form-select form-select-sm me-4" (change)="onSelectTemplate()" [(ngModel)]="selectedTemplateId">
            <option value="">Select Template ...</option>
            <option *ngFor="let template of noteTemplates" [value]="template.id">
              {{ template.name }}
            </option>
          </select>
      
          <span class="me-3">{{ clinicNote.managedType }}</span>
          <button type="button" class="btn" aria-label="Expand" (click)="expandModal()">
            <span aria-hidden="true"><i class="fas" [class.fa-compress]="modalXL" [class.fa-expand]="!modalXL"></i></span>
          </button>
          <button type="button" class="btn-close" aria-label="btn-close" (click)="clinicNoteModal.hide()"></button>
        </div>
      </div>
      
      <div class="modal-body">

        <div class="row">
          <!-- left side -->
          <div [ngClass]="{'col-lg-8': modalXL, 'col-xxl-7': modalXL, 'col-lg-12': !modalXL}">
            <div *ngIf="editMode">
              <div class="row">
                <div class="col-md-12">    
                  <div class="row">                    
                    <div class="col d-flex mb-2" *ngIf="!_isLockManagedType && selectedSchedule?.workType !== 'SUPP' && !jobIsTherapy">
                      <div class="btn btn-outline-dark me-2 disabled text-nowrap">Type</div>
                      <select class="form-select form-select-sm" [(ngModel)]="clinicNote.managedType" (change)="onSelectManagedType(clinicNote.managedType)">
                        <option value="">Select Managed Type ...</option>
                        <option *ngFor="let noteManagedType of noteManagedTypes" [value]="noteManagedType.name">
                          {{noteManagedType.name}}
                        </option>
                      </select>
                    </div>
                    <div class="row">
                      <div class="col-md-6 d-flex mb-2" *ngIf="!clientId && !selectedSchedule?.job?.clientId" >
                        <div class="btn btn-outline-dark me-2 disabled text-nowrap">Client</div>
                        <ng-select class="dd me-1" [items]="clients" bindLabel="fullName" bindValue="id" (change)="onSelectClient(clinicNote.clientId)"
                          placeholder="Select Client" [(ngModel)]="clinicNote.clientId" [class.err]="!clinicNote.clientId">
                        </ng-select>
                      </div>
                      
                      <div class="col-md-6 d-flex mb-2 date-container" *ngIf="selectedSchedule?.workType !== 'SUPP'">
                        <div  class="btn btn-outline-dark me-2 disabled">Date</div>
                        <input  type="text" placeholder="dd/mm/yyyy" class="form-control" bsDatepicker placeholder="Date"
                          [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue'}"
                          [(ngModel)]="clinicNote.date" [class.err]="!clinicNote.date">
                        <timepicker [(ngModel)]="clinicNote.noteDate"  [class.error]="!clinicNote.noteDate" [showMeridian]="false" class="ms-2"></timepicker>
                      </div>
                 
                    </div>
                  </div>

                

                <!-- <div class="row">
                  <div class="col d-flex mb-2">
                    <div class="btn btn-outline-dark me-2 disabled">Template</div>
                    <select class="form-select form-select-sm" (change)="onSelectTemplate()"
                      [(ngModel)]="selectedTemplateId">
                      <option value="">Select Template ...</option>
                      <option *ngFor="let template of noteTemplates" [value]="template.id">
                        {{template.name}}
                      </option>
                    </select>
                  </div>
                  <div class="col d-flex mb-2" *ngIf="managedType != 'COS'">
                    <span class="btn btn-outline-dark me-2 disabled"><span class="text-nowrap">Past Note</span></span>
                    <select class="form-select form-select-sm" (change)="onSelectPastNote()"
                      [(ngModel)]="selectedPastNoteId">
                      <option value="">Select Note ...</option>
                      <option *ngFor="let note of pastNotes" [value]="note.id">
                        {{note.date | date}} {{note.managedType}} <span *ngIf="note.level != null">Level {{note.level}}</span> {{note.type}}
                      </option>
                    </select>
                  </div>
                </div> -->
                  <!-- <div class="d-flex mb-2">
                    <div class="btn btn-outline-dark me-2 disabled text-nowrap">Billable Status</div>
                    <select class="form-select form-select-sm" [(ngModel)]="clinicNote.isBillable" [class.err]="clinicNote.isBillable == null || clinicNote.isBillable == undefined || clinicNote.isBillable === ''">
                      <option value="">Select Billable Status ...</option>
                      <option *ngFor="let bs of isBillables" [value]="bs.id">
                        {{bs?.status}}
                      </option>
                    </select>
                  </div> -->
                  <!-- <div class="d-flex mb-2" *ngIf="clinicNote.isBillable == 1">
                    <div class="btn btn-outline-dark me-2 disabled text-nowrap">Billable Date</div>
                    <input type="text" class="form-control" bsDatepicker placeholder="Billable Date"
                      [bsConfig]="{dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-blue'}"
                      [(ngModel)]="clinicNote.attributableBillDate" [ngModelOptions]="{standalone: true}"
                      [class.err]="!clinicNote.attributableBillDate && !!clinicNote.isBillable">
                  </div> -->
                  <div class="mb-2">
                    <div class="btn-group btn-group-toggle" data-bs-toggle="buttons">
                      <a href="#" role="button" class="btn btn-outline-dark me-2 disabled" id="label-btn"
                        aria-disabled="true">Default</a>
                      <label *ngFor="let noteType of defaultTypes" class="btn btn-white"
                        [class.bg-primary]="clinicNote.type === noteType.name">
                        <input type="radio" name="type" [(ngModel)]="clinicNote.type" value="{{noteType.name}}">
                        {{noteType.name}}
                      </label>
                    </div>
                  </div>
                 
                  <div class="mb-2" *ngIf="clinicNote.managedType === 'Therapy'">
                    <div class="btn-group btn-group-toggle" data-bs-toggle="buttons">
                      <a href="#" role="button" class="btn btn-outline-dark me-2 disabled" id="label-btn"
                        aria-disabled="true">Therapy</a>
                      <label *ngFor="let noteType of therapyTypes" class="btn btn-white"
                        [class.bg-primary]="clinicNote.type === noteType.name">
                        <input type="radio" name="type" [(ngModel)]="clinicNote.type" value="{{noteType.name}}">
                        {{noteType.name}}
                      </label>
                    </div>
                  </div>
                  <div class="mb-2" *ngIf="clinicNote.managedType === 'Other'">
                    <div class="btn-group btn-group-toggle" data-bs-toggle="buttons">
                      <a href="#" role="button" class="btn btn-outline-dark me-2 disabled" id="label-btn"
                        aria-disabled="true">Other</a>
                      <label *ngFor="let noteType of otherTypes" class="btn btn-white"
                        [class.bg-primary]="clinicNote.type === noteType.name">
                        <input type="radio" name="type" [(ngModel)]="clinicNote.type" value="{{noteType.name}}">
                        {{noteType.name}}
                      </label>
                    </div>
                  </div>

                  <div class="row" *ngIf="clinicNote.type == 'Alert'">
                    <div>
                      <a href="#" role="button" class="btn btn-outline-dark me-2 disabled" id="label-btn"
                          aria-disabled="true">Is Active</a>
                      <input type="checkbox" [(ngModel)]="clinicNote.isActive" class="ms-2">
                    </div>

                    <div>
                      <div class="btn-group btn-group-toggle" data-bs-toggle="buttons">
                        <a href="#" role="button" class="btn btn-outline-dark me-2 disabled" id="label-btn"
                          aria-disabled="true">Alert Type</a>
                        <label *ngFor="let alertType of alertTypes" class="btn btn-white"
                          [class.bg-primary]="clinicNote.alertType === alertType.name">
                          <input type="radio" name="alertType" [(ngModel)]="clinicNote.alertType" value="{{alertType.name}}">
                          {{alertType.name}}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <ng-container *ngIf="clinicNote.type != 'Session Outcomes'">
                      <div class="col-md-8">
                        <div class="d-flex">
                          <!-- <label>Activity Time Taken (mins)</label> -->
                          <div class="btn btn-outline-dark me-2 disabled">Activity Time Taken (mins)</div>
                          <div>
                            <input class="form-control time-input" type="text" [class.err]="!clinicNote.minsTaken"
                              [(ngModel)]="clinicNote.minsTaken" mask="000" [showMaskTyped]="true"
                              [dropSpecialCharacters]="false" placeholder="Time Taken"
                              (blur)="validateMinsItem(clinicNote.minsTaken)" />
                          </div>
                        </div>
                        <div class="text-muted">
                          1 hr = 60 mins, 2 hrs = 120 mins
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="mb-2" *ngIf="clinicNote?.managedType === 'COS'">
                          <div class="btn-group btn-group-toggle" data-bs-toggle="buttons">
                            <a href="#" role="button" class="btn btn-outline-dark me-2 disabled" id="label-btn"
                              aria-disabled="true">COS Level</a>
                            <label *ngFor="let level of levels" class="btn btn-white level"
                              [class.bg-primary]="clinicNote?.level === level">
                              <input type="radio" name="level" [(ngModel)]="clinicNote.level" [value]="level">
                              {{level}}
                            </label>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div *ngIf="clinicNote.type == 'Session Outcomes'">
                      Part of session package
                    </div>
      
                  </div>

                </div>

                
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="editor mt-3" [style.display]="showEditor ? 'block' : 'none'">
                    <div id="editor" class="mb-4" [class]="caller"></div>
                  </div>
                </div>
              
              </div>
            </div>

            <div *ngIf="!editMode">
              <h4>
                {{clinicNote.type}} - {{clinicNote.managedType}}
              </h4>

              <pre [innerHTML]="clinicNote.detail | htmlDisplay"></pre>
            </div>

          </div>

          <!-- right side -->
          <div [ngClass]="{'col-lg-4': modalXL, 'col-xxl-5': modalXL, 'col-lg-12': !modalXL}">
            <div class="d-flex justify-content-evenly mb-1 border-bottom border-primary">
                <button class="btn btn-xs text-primary" (click)="showTabNotes()">Past Notes</button>
                <button class="btn btn-xs text-primary" (click)="showTabSA(null, true)">Service Agreements</button>
                <button class="btn btn-xs text-primary" (click)="showTabPlans(null)">NDIS Plans</button>      
                <!-- <button class="btn btn-xs text-primary" (click)="showTabOther()">Other</button>                                                                               -->
            </div>       
            
            <div *ngIf="tabSelection === 'notes'" class="mt-2">                           
              <ng-template *ngTemplateOutlet="notesTemplate"></ng-template>  
            </div>                

            <div *ngIf="tabSelection === 'sa'" class="mt-2">
                <h4>Service Agreements</h4>
                <!-- <div>
                    Send Email to chosen provider to obtain service agreement
                </div> -->
                <table class="table table-bordered">
                    <ng-container *ngFor="let sa of client.clientServiceAgreements">
                        <tr>
                            <td>{{sa.provider?.name}}</td>
                            <td>{{sa.startDate | date}} ~ {{sa.endDate | date}}</td>
                            <td>{{sa.totalAmount | currency}}</td>
                            <td>{{sa.fundingSource}}</td>
                            <td>{{sa.agreementType}}</td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let lineItem of sa.serviceAgreementLineItems">
                          
                            <td>
                                <div class="ms-2">{{lineItem.supportItemNumber}}</div>
                            </td>
                            <td>{{lineItem.rate | currency}}</td>
                            <td>{{lineItem.unit}}</td>
                            <td>{{lineItem.amount | currency}}</td>
                            <td>{{lineItem.total | currency}}</td>
                            <td [class.bg-danger]="lineItem.balance < 0">
                                {{lineItem.balance | currency}}
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>          

            <div *ngIf="tabSelection === 'plans'" class="mt-2">
                <h4>NDIS Plans</h4>

                <div>
                    Plan: {{client.currentPMAgreement?.startDate}} ~ {{client.currentPMAgreement?.endDate}}
                    , {{client.currentPMAgreement?.provider?.name}}
                </div>

                <loading-spinner *ngIf="isLoading.plans === true"></loading-spinner>
                <div class="no-data" *ngIf="!isLoading.plans && !client?.plans?.length">No plan data available</div>
                    

                <div class="accordion" id="accordionPlan">
                    <div class="accordion-item" *ngFor="let plan of client?.plans">

                      <h2 class="accordion-header" id="headingPlan">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" style="font-size:0.9rem"
                        [attr.data-bs-target]="'#collapsePlan-' + plan.id" aria-expanded="true" [attr.aria-controls]="'collapsePlan-'+ plan.id">
                          {{plan.startDate | date}} ~ {{plan.endDate | date}}  <span class="ms-5">{{plan.totalAmount | currency}}</span>
                        </button>
                      </h2>

                      <div [attr.id]="'collapsePlan-' + plan.id" class="accordion-collapse collapse show" aria-labelledby="headingPlan"
                        data-bs-parent="#accordionPlan">
                        <div class="accordion-body">
                            <table class="table table-bordered">
                                <thead>
                                    <th>Cat</th>
                                    <th>Budget</th>
                                    <th>Spent</th>
                                    <th>Remain</th>
                                    <th>% Spent</th>
                                </thead>
                                <tr *ngFor="let cat of plan.cats">
                                    <td>
                                        {{cat.id}}
                                    </td>
                                    <td class="text-muted">
                                        {{cat.budget | currency}}
                                        <!-- <em class="ms-1 fa fa-file-pdf fa-fw" (click)="togglePDFView(plan?.document, false)"></em> -->
                                    </td>                                                
                                    <td class="text-muted">{{cat.managedType}}</td>
                                    <td class="text-muted">{{cat.openingBalanceAtPMCommencementDate | currency}}</td>
                                </tr>
                            </table>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            <div *ngIf="tabSelection === 'other' && managedType == 'COS'" class="mt-2">
              
                <div class="mt-3">
                    <label>COS Followup Request:</label>
                    <div>
                        <textarea  rows="4" style="width:100%" [(ngModel)]="client.longTermCOSActionPlan"></textarea>
                        <button type="button" class="btn btn-xs btn-primary pull-right float-right" (click)="saveLongTermActionPlan()">Save</button>
                    </div>
                </div>

            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer d-flex">
        <button type="button" class="btn btn-sm btn-outline-danger" (click)="deleteNoteConfirm(clinicNote?.id)">Delete</button>
        <button class="btn btn-sm btn-outline-secondary" type="button"
          (click)="onUploadFileClickEdit(drop)"><em class="fa fa-upload"></em> Upload PDF
        </button>
        <ng-container *ngIf="clinicNote.notesFile">
          <em class="badge badge-gray text-black">
            {{clinicNote.notesFile?.name}}
            <em (click)="resetFileUpload()" class="text-primary fa fa-close hoverable"></em>
          </em>
        </ng-container>
        <button type="button" class="btn btn-sm btn-gray ms-auto me-2" (click)="closeModal()">Cancel</button>
        <button *ngIf="editMode" type="button" class="btn btn-sm btn-secondary" (click)="saveNote('draft')" [disabled]="!isNoteValid()">Save Draft</button>
        <button *ngIf="editMode" type="button" class="btn btn-sm btn-success" (click)="saveNote('published')" [disabled]="!isNoteValid()">Publish Note</button>
      </div>
    </div>
  </div>
  <div ngx-dropzone disableClick=true class='dropzone' #drop (change)="onFilesSelect($event)" accept="application/pdf"></div>
</div>


<!-- COS Notes -->
<ng-template #notesTemplate let-model="item" let-index="index">
  <div>
          <!-- <div>
              SA: {{selectedClient.currentSA?.startDate | date}} ~ {{selectedClient.currentSA?.endDate | date}} 
              <span *ngIf="selectedClient.currentSA?.earlyEndDate">* early end {{selectedClient.currentSA?.earlyEndDate | date}}</span>
          </div> -->

          <div class="mt-3">
              <!-- notes -->
              <app-billing-notes [client]="client" [managedType]="managedType"></app-billing-notes>
          </div>

  </div>
</ng-template>