<!-- JOB CLONE MODAL -->
<div class="modal-dialog shadow">
    <div class="modal-content" *ngIf="model">
        <div class="modal-header">
            <h4 class="modal-title">Job Clone for #{{model?.jobNumber}}</h4>
            <button type="button" class="btn-close" aria-label="btn-close" (click)="container.hide()"></button>
        </div>
        <div class="modal-body">
            <div class="d-flex mb-3">
                <div class="col" *ngIf="!multiple">
                    <div class="form-group">
                        <label class="text-bold col-form-label text-end me-2">Date to clone to:</label>
                        <input type="text" placeholder="dd/mm/yyyy" class="form-control time-control mr-4"
                            bsDatepicker [bsConfig]="{dateInputFormat:  'DD/MM/YYYY', containerClass: 'theme-blue'}"
                            [(ngModel)]="cloneStartDate" [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="col" *ngIf="multiple">
                    <div class="form-group">
                        <label class="text-bold col-form-label text-end me-2">Select week to clone to:</label>
                        <span class="btn-group">
                            <ngc-week-picker *ngIf="currentOrganisation" class="week-picker"
                                [formControl]="weekFilterForm" (change)="weekSelected($event)"
                                [startDay]="currentOrganisation?.weekStart" [restricted]="true">
                            </ngc-week-picker>
                            <button class="btn btn-gray btn-sm mr-0" (click)="prevWeek()">
                                < </button><button class="btn btn-gray btn-sm" (click)="nextWeek()"> > </button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <div class="checkbox c-checkbox mt-2">
                    <label>
                        <input type="checkbox" [(ngModel)]="isCloneCharges" />
                        <span class="fa fa-check"></span>Clone Charges
                    </label>
                </div>
            </div>
            <div class="mb-2">
                <div class="checkbox c-checkbox mt-2">
                    <label>
                        <input type="checkbox" [(ngModel)]="isCloneKmCharges" />
                        <span class="fa fa-check"></span>Clone Kms Charges
                    </label>
                </div>
            </div>
            <div class="mb-2">
                <div class="checkbox c-checkbox mt-2">
                    <label>
                        <input type="checkbox" [(ngModel)]="isCloneStaff" />
                        <span class="fa fa-check"></span>Clone Staff
                    </label>
                </div>
            </div>
            <div class="mb-2">
                <div class="checkbox c-checkbox mt-2">
                    <label>
                        <input type="checkbox" [(ngModel)]="isCloneShadowShifts" />
                        <span class="fa fa-check"></span>Clone Shadow Shifts
                    </label>
                </div>
            </div>
            <div class="mb-3">
                <em>** Note - payments, notes, time entries, etc. do not get cloned</em>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="process()"
                [disabled]="!isFormValid()">Proceed</button>
        </div>
    </div>
</div>