import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const PROVIDER_ENDPOINT = 'provider';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  constructor(private baseApi: CarestartApiService) { }

  /* PROVIDER */

  // get(pageSize: any = 100, page: any = 1) {
  //   let endpoint = `${PROVIDER_ENDPOINT}?Limit=${pageSize}&Page=${page}`;
  //   return this.baseApi.basicGet(endpoint);
  // }

  getByIdObs(id: any) {
    const endpoint = `${PROVIDER_ENDPOINT}/${id}`;
    return this.baseApi.getObs(endpoint);
  }

  getById(id: any) {
    return this.baseApi.basicGet(`${PROVIDER_ENDPOINT}/${id}`)
  }

  // used for dropdownlists etc
  getIds(isActive: any = true) {
    const endpoint = `${PROVIDER_ENDPOINT}/ids?isActive=${isActive}`;
    return this.baseApi.getObs(endpoint);
  }

  // used for dropdownlists etc
  getPlanManagerIds(isActive: any = true) {
    const endpoint = `${PROVIDER_ENDPOINT}/planManager/ids?isActive=${isActive}`;
    return this.baseApi.getObs(endpoint);
  }

  getProviderByName(name: any) {
    const endpoint = `${PROVIDER_ENDPOINT}/GetByName/${name}`;
    return this.baseApi.basicGet(endpoint);
  }

  getProviders() {
      return this.baseApi.basicGet(PROVIDER_ENDPOINT);
  }

  createProvider(data: any) {
    return this.baseApi.basicCreate('provider', data);
  }

  deleteProvider(providerId: any) {
    const endpoint = `${PROVIDER_ENDPOINT}/${providerId}`;
    return this.baseApi.delete(endpoint);
  }

  patchProvider(provider: any) {
    const data: any = this.baseApi.createPatchObject(provider);
    return this.baseApi.updatePartial(PROVIDER_ENDPOINT, provider.id, data);
  }

  getABNLookup() {
    const search = 'https://abr.business.gov.au/abrxmlsearchRPC/AbrXmlSearch.asmx/' //+ 
    // 'ABRSearchByNameSimpleProtocol?name=' + name + 
    // '&postcode=' + postcode + '&legalName=' + legalName + 
    // '&tradingName=' + tradingName  +
    // '&authenticationGuid=' + authenticationGuid;

    // this.http.getABNLookup
  }

}
