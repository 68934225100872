<div class="modal-dialog modal-dialog-scrollable" [class.modal-xl]="jobModalXL" [class.modal-lg]="!jobModalXL">
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between align-items-center">
      <h4 class="modal-title">{{isNew ? 'New' : 'Edit'}} {{title}} Event Details {{model?.jobNumber}}</h4>
      <span>
        <span class="me-3 checkbox c-checkbox text-muted">
          <label>
            <input type="checkbox" [(ngModel)]="isShowShortNoticeApptClientsOnly" (change)="filterClients()"/>
            <span class="fa fa-check"></span>
            <b>Show Short Notice Appt Clients Only</b>
          </label>
        </span>
        <button type="button" class="btn-close" aria-label="btn-close" (click)="container.hide()">
        </button>
      </span>     
    </div>
    <div class="modal-body">

      <div class="alert alert-danger" *ngIf="modalCode == 'ce'">If this is a CLIENT MEETING you cannot use Community Engagement (CE). Please go back and select Client Meeting (CM)</div>
    
      <form [formGroup]="jobForm" (ngSubmit)="saveJob()">

        <!-- SIL HOUSE-->
        <div class="checkbox c-checkbox">
          <label>
            <input type="checkbox" formControlName="isSILHouse" />
            <span class="fa fa-check"></span>
            <b>Is SIL House</b>
          </label>
        </div>        
        <div class="input-group mb-3" *ngIf="jobForm.value?.isSILHouse">
          <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;SIL HOUSE&nbsp;&nbsp;</label>
          <ng-select formControlName="silId" class="dd me-1" [items]="silHouses" bindLabel="name" bindValue="id"
            placeholder="Select SIL">
          </ng-select>
        </div>

        <!-- GROUP ACTIVITY-->
        <div class="mb-3" *ngIf="isCheckForGroup">
          <div class="checkbox c-checkbox">
            <label>
              <input type="checkbox" formControlName="isGroupActivity" />
              <span class="fa fa-check"></span>
              <b>Is Group Activity</b>
            </label>
          </div>
        </div>
        <div class="d-flex mb-3" *ngIf="jobForm.value?.isGroupActivity">
          <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;ACTIVITY NAME&nbsp;&nbsp;</label>
          <input type="text" class="form-control" formControlName="name" id="name" style="width: 560px">
        </div>
        <div class="d-flex mb-3" *ngIf="jobForm.value?.isGroupActivity">
          <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;MAX CLIENTS&nbsp;&nbsp;</label>
          <input type="text" class="form-control" formControlName="maxClients" id="maxClients" style="width: 140px">
        </div>

        <!-- CLIENT -->
        <div class="input-group mb-3" *ngIf="hasClient">
          <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;CLIENT
            <span *ngIf="jobForm.value?.isGroupActivity">S</span>&nbsp;&nbsp;</label>
          <ng-select 
            *ngIf="!jobForm.value?.isGroupActivity" 
            formControlName="clientId" 
            class="dd me-1"
            [class.err]="!jobForm.get('clientId').valid" 
            [items]="clients" 
            bindLabel="fullName" 
            bindValue="id"
            placeholder="Select Client" 
            (change)="onClientSelected()">
            <!-- groupBy="isOnShortNoticeList"  <ng-template ng-optgroup-tmp let-item="item">
              <span *ngIf="item.isOnShortNoticeList">Short Notice Appts</span>
              <span *ngIf="!item.isOnShortNoticeList"></span>
            </ng-template> -->
            <ng-template ng-option-tmp let-item="item">
              <!-- <img height="15" width="15" [src]="item.avatar"/> -->
              {{item.fullName}}  <span *ngIf="item.isOnShortNoticeList" class="ms-2 text-muted">(Short Notice Appts OK)</span>
            </ng-template>
          </ng-select>

          <!-- if client has no outlets provide small button to open outlets modal, dont make too big, dont show if group activity -->
          <!-- probably position top right later -->
          <!-- careful if we press this button and it saved the form with no data in clientId -->
          <span *ngIf="!jobForm.value?.isGroupActivity && jobForm.value?.clientId">
            <button class="btn btn-sm btn-gray" type="button"
              (click)="addEditOutletsModal.show()">Add/Edit Client Outlets</button>
          </span>
          <ng-select class="dd me-1 minwidth" [items]="clients" bindLabel="fullName" #multipleClientsSelect id="multipleClientsSelect"
            [hidden]="!jobForm.value?.isGroupActivity" placeholder="Select Client(s)" [multiple]="true"
            formControlName="clients" (add)="onGroupClientSelected($event, multipleClientsSelect)" (clear)="clearAllGroupClients()">
            <ng-template ng-label-tmp let-item="item">
              <span class="ng-value-icon left" (click)="unselect(item);" aria-hidden="true">×</span>
              <span (click)="onClientClick(item, multipleClientsSelect)">{{item.fullName}}</span>
            </ng-template>
          </ng-select>
          <em *ngIf="jobForm.value?.isGroupActivity" style="align-self: center;"><small>* Click on client to edit details</small></em>
          <div *ngIf="selectedGroupClientToAdd" class="ratio-box mt-2 dd">
            <em class="me-2">{{selectedGroupClientToAdd.fullName}}</em>
            <span *ngFor="let ratio of ratios" class="btn btn-xs btn-gray me-1"
              [class.ratio-selected]="selectedGroupClientToAdd.clientRatio === ratio.id"
              (click)="selectedGroupClientToAdd.clientRatio = ratio.id">{{ratio.value}}</span>
            <div class="checkbox c-checkbox ms-3">
              <label>
                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="selectedGroupClientToAdd.isTransport" />
                <span class="fa fa-check"></span>
                Transport
              </label>
            </div>
            <div class="checkbox c-checkbox ms-2">
              <label>
                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="selectedGroupClientToAdd.isCopay" />
                <span class="fa fa-check"></span>
                Copay
              </label>
            </div>
            <div class="checkbox c-checkbox ms-2">
              <label>
                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="selectedGroupClientToAdd.isCancelled" />
                <span class="fa fa-check"></span>
                Cancelled
              </label>
            </div>
            <div class="checkbox c-checkbox ms-2">
              <label>
                <input type="checkbox" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="selectedGroupClientToAdd.isBillable" />
                <span class="fa fa-check"></span>
                Billable
              </label>
            </div>
            <button type="button" class="btn btn-success btn-xs ms-5" (click)="onGroupClientSave(multipleClientsSelect)"
              [disabled]="!selectedGroupClientToAdd.clientRatio">{{isEditGroupClient ? 'Edit' : 'Add'}}
              Client</button>
            <span class="btn btn-gray btn-xs ms-3" (click)="onGroupClientCancel(multipleClientsSelect)">Cancel</span>
          </div>
        </div>
        <em *ngIf="softWarning" class="font-mono text-warning text-muted line-item text-center"><i>Client already has a job on same day</i></em>
        <!-- if group activity show outlets by default -->
        <div class="input-group mb-3" *ngIf="jobForm.value?.isGroupActivity">
          <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;OUTLET&nbsp;&nbsp;</label>
          <ng-select formControlName="outletId" class="dd me-1" [items]="outlets" bindLabel="name" bindValue="id"
            placeholder="Select Outlet">
          </ng-select>
        </div>

        <!-- only show if client has an existing outlet -->
        <ng-container *ngIf="!jobForm.value?.isGroupActivity && client?.outlets?.length">
          <div class="input-group mb-3">
            <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;OUTLET&nbsp;&nbsp;</label>
            <ng-select formControlName="outletId" class="dd me-1" [items]="client.outlets" bindLabel="name"
              bindValue="id" placeholder="Select Outlet">
            </ng-select>
          </div>
        </ng-container>

        <div class="input-group mb-3" *ngIf="hasProvider">
          <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;PROVIDER&nbsp;&nbsp;</label>
          <ng-select formControlName="providerId" class="dd me-1" [items]="providers" bindLabel="name" bindValue="id"
            placeholder="Select Provider">
          </ng-select>
        </div>

        <div class="input-group mb-3" *ngIf="hasName">
          <label for="inputName" class="col-sm-1 col-form-label">Title</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" formControlName="name" placeholder="Title" id="name">
          </div>
        </div>

        <div class="input-group mb-3" *ngIf="!isNew">

          <label class="input-group-text">&nbsp;&nbsp;&nbsp;&nbsp;STATUS&nbsp;&nbsp;</label>
          <ng-select formControlName="status" [class.err]="!jobForm.get('status').valid" class="dd me-1"
            [items]="jobStatuses" bindLabel="name" bindValue="name" placeholder="Select Job Status">
          </ng-select>

        </div>

        <div class="row mt-3 mb-2">
          <div class="col-md-12" *ngIf="showDetail">
            <schedules-component [job]="model" [allJobTypes]="allJobTypes" [workTypes]="workTypes" 
            [employees]="employees" [isInModal]="true" [client]="client" 
            [scheduleDate]="scheduleDate" [modalCode]="modalCode" [groupings]="groupings" (scheduleDateSelect)="getClientJobs($event)"
            ></schedules-component>
          </div>

          <div class="col-md-12">
            <div class="table-responsive" *ngIf="!isNew && showDetail && !jobForm.value?.isGroupActivity">
              <table class="table table-md table-bordered table-hover">
                <thead class="table-light">
                  <tr class="header text-center">
                    <th width="10%">Account</th>
                    <th width="9%">Type</th>
                    <th width="30%">Item Code/Details</th>
                    <th width="9%">Price</th>
                    <th width="9%">Qty</th>
                    <th width="10%">Tax</th>
                    <th width="8%">Total</th>
                    <th width="5%">PayReq</th>
                    <th width="10%">
                      <button class="btn btn-xs" (click)="chargeAddNew()" tooltip="Add" type="button">
                        <em class="fas fa-plus-circle"></em> Add New
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="!model?.charges?.length">
                    <td colspan="9" class="no-data">No Data</td>
                  </tr>
                  <ng-container *ngFor="let item of model?.charges">
                    <tr *ngIf="!item.editMode">
                      <td>{{item.chartAccountCode}}</td>
                      <td>{{item.itemCodeType}}</td>
                      <td>
                        <div *ngIf="item.itemCode">{{item.itemCode}}</div>
                        <div>{{item.details}}</div>
                      </td>
                      <td class="text-end">{{item.unit || 0 | currency}}</td>
                      <td class="text-end">{{item.quantity || 0 | number}}</td>
                      <td class="text-end">{{item.taxTypeItem?.name}}</td>
                      <td class="text-end">{{item.total || 0 | currency}}</td>
                      <td class="text-center">
                        <!-- <div *ngIf="!model.client?.isPlanManaged || model.client?.isPlanManagedByUs">
                          <span *ngIf="item.payRequestId === null">
                            <button class="btn btn-xs" type="submit" (click)="createPaymentRequest(item, job)"
                              tooltip="Create Pay Request">
                              PayReq
                            </button>
                          </span>
                          <em class="far fa-check-circle text-success" title="PayReq" *ngIf="item.payRequestId"></em>
                        </div> -->
                      </td>
                      <td class="text-center">
                        <span class="pointer" (click)="chargeGotoEditMode(item)">Edit</span> |
                        <span class="pointer" (click)="chargeLineItemDelete(item)">Del</span>
                      </td>
                    </tr>
                    <tr *ngIf="item.editMode" class="edit-mode bt-edit">
                      <td colspan="9" class="no-padding">
                        <div class="ms-2 mt-2">
                          <input type="checkbox" id="fixedTotal" [(ngModel)]="item.isFixedTotal"
                            [ngModelOptions]="{standalone: true}"><label class="ms-1" for="fixedTotal">Fixed
                            Total?</label>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="item.editMode" class="edit-mode">
                      <td>
                        <input placeholder="Chart Accounts Code" typeaheadOptionField="searchFieldChartAccountsCode"
                          autocomplete="off" (typeaheadOnSelect)='onChartAccountsCodeChanged($event, item)'
                          class="form-control" [typeahead]="chartAccountCodes"
                          [id]="'itemChartAccountsCodes_' + item.id" [isAnimated]="true"
                          [typeaheadItemTemplate]="chartAccountsCodesTemplate" [(ngModel)]="item.chartAccountCode"
                          [ngModelOptions]="{standalone: true}">
                      </td>
                      <td>
                        <select class="form-select form-select-sm" [(ngModel)]="item.itemCodeType"
                          [ngModelOptions]="{standalone: true}">
                          <option value="">Select Item Code Type ...</option>
                          <option *ngFor="let codeType of itemCodeTypes" [value]="codeType.code">
                            {{codeType.title}}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input placeholder="Support Item Number" typeaheadOptionField="searchField" autocomplete="off"
                          (typeaheadOnSelect)='onSupportItemChanged($event, item)' class="form-control"
                          [typeahead]="supportItemNumbers" [id]="'item_' + item.id" [isAnimated]="true"
                          [typeaheadItemTemplate]="supportItemsTemplate" [(ngModel)]="item.supportItemNumber"
                          [ngModelOptions]="{standalone: true}">
                      </td>
                      <td>
                        <input class="form-control" type="number" *ngIf="!item.isFixedTotal" [(ngModel)]="item.unit"
                          [ngModelOptions]="{standalone: true}" placeholder="Price..." />
                      </td>
                      <td>
                        <input class="form-control" type="number" *ngIf="!item.isFixedTotal" [(ngModel)]="item.quantity"
                          [ngModelOptions]="{standalone: true}" placeholder="Qty..." />
                      </td>
                      <td>
                        <select class="form-select form-select-sm" [(ngModel)]="item.taxType"
                          [ngModelOptions]="{standalone: true}">
                          <option value="">Select Tax Type...</option>
                          <option *ngFor="let taxType of taxTypes" [value]="taxType.code">
                            {{taxType.name}}
                          </option>
                        </select>
                      </td>
                      <td>
                        <input [disabled]="!item.isFixedTotal" class="form-control" type="number" placeholder="Total..."
                          [(ngModel)]="item.total" [ngModelOptions]="{standalone: true}" />
                      </td>
                      <td colspan="2"></td>
                    </tr>
                    <tr *ngIf="item.editMode" class="edit-mode bb-edit">
                      <td colspan="6">
                        <input class="form-control" [(ngModel)]="item.details" placeholder="Details..."
                          [ngModelOptions]="{standalone: true}" />
                      </td>
                      <td  colspan="3">
                        <div class="d-flex justify-content-center">
                          <button class="btn btn-gray btn-sm me-2" type="button" (click)="cancelChargeLineItem(item)"
                            title="Cancel">
                            Cancel
                          </button>
                          <button class="btn btn-primary btn-sm" type="button" [disabled]="!isChargeValid(item)"
                            (click)="saveChargeLineItem(item)" title="Save">
                            <em class="fas fa-save"></em> Save
                          </button>
                          &nbsp;<span class="fas fa-spinner fa-pulse" *ngIf="item.inProgress === true"></span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="item.expectedRateItem" class="pt-0 pb-0 font-italic text-muted guide">
                      <td colspan="2">
                        * Price Guide
                      </td>
                      <td>
                        <div class="font-italic text-muted">{{item.expectedRateItem?.supportItemName}}</div>
                      </td>

                      <td class="text-end text-muted font-mono">
                        <span *ngIf="item.expectedRateItem" [class.text-bold]="item.expectedRateWarning === true"
                          [class.text-danger]="item.expectedRateWarning === true"
                          [class.text-primary]="item.expectedRateWarning === false">
                          {{item.expectedRateItem?.priceDefault || 0 | currency}}
                        </span>
                      </td>
                      <td colspan="3" class="font-mono">
                        <span class="text-muted">NDIA {{item.expectedRateItem?.priceGuide?.effectiveFrom}}
                          ({{item.expectedRateItem?.unit}})</span>
                      </td>
                      <td colspan="2"></td>
                    </tr>
                  </ng-container>

                  <tr class="table-borderless font-larger text-bold">
                    <td colspan="5"></td>
                    <td class="text-end">
                      <div>TOTAL</div>
                      <div>Payments</div>
                      <div>Balance</div>
                    </td>
                    <td class="text-end" colspan="2">
                      <div>{{(model?.totalChargeAmount | currency) || 'N/A'}}</div>
                      <div>{{(model?.totalPayments | currency) || 'N/A'}}</div>
                      <div class="balance-box" [class.balance-bg-black]="model?.balanceRemaining > 0">
                        {{(model?.balanceRemaining | currency) || 'N/A'}}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-12">
            <div class="table-responsive mb-4" *ngIf="this.modalCode == 'j'">
              <label *ngIf="title === 'Job'"><strong>Scope of Work:</strong></label>
              <label *ngIf="title !== 'Job'"><strong>Details:</strong></label>
              <textarea class="form-control" rows="4" placeholder="Insert scope of work details..."
                (change)="saveScopeOfWork()" formControlName="scope"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer custom">
          <button type="button" class="btn btn-danger" *ngIf="recurring" (click)="deleteRecurringJob()">Delete</button>
          <button type="submit" class="btn btn-primary float-end" [disabled]="!isFormValid()"><em
              class="fa fa-fw fa-edit"></em>Save</button>
          <button type="button" class="btn btn-gray float-end" (click)="closeModal()">Cancel</button>
        </div>
      </form>

      <div *ngIf="jobCreated">
        <button class="btn btn-primary btn-sm" (click)="goToNewJob()">Go to New Job</button>
      </div>

    </div>
  </div>
</div>

<div class="modal fade" bsModal #addEditOutletsModal="bs-modal" id="addEditOutletsModal" tabindex="-1" role="dialog"
  aria-labelledby="addEditOutletsModal" aria-hidden="true" data-bs-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
    <div class="modal-content" style="background-color: #def; min-height: 480px;">
      <div class="modal-header">
        <h4 class="modal-title">Add / Edit Outlets</h4>
        <button type="button" class="btn-close" (click)="addEditOutletsModal.hide()" aria-label="btn-close"></button>
      </div>
      <div class="modal-body">
        <app-client-outlets [outlets]="client?.outlets" [clientId]="client.id" [callback]="getClientOutlets.bind(this)"
          *ngIf="jobForm.value.clientId"></app-client-outlets>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-gray me-3" (click)="addEditOutletsModal.hide()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #supportItemsTemplate let-model="item" let-index="index">
  <h5>{{model.supportItemName}} <b>{{model.supportItemNumber}}</b></h5>
</ng-template>

<ng-template #chartAccountsCodesTemplate let-model="item" let-index="index">
  <h5>{{model.name}} <b>{{model.accountCode}}</b></h5>
</ng-template>