import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const JOB_ENDPOINT = 'job';
const JOB_CHARGE_ENDPOINT = 'jobCharge';
const JOB_TIME_ENTRY_ENDPOINT = 'jobTimeEntry';
const JOB_SCHEDULE_ENDPOINT = 'jobSchedule';
const RECURRING_JOB_TEMPLATE = 'recurringJobTemplate';
const JOB_HISTORY_ENDPOINT = 'jobHistory';
const JOB_NOTE_ENDPOINT = 'jobNote';
const JOB_NOTE_TYPE_ENDPOINT = 'jobNoteType';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private baseApi: CarestartApiService) { }

  /**
   * job data in sql (including old ks data)
   */

   getJobs(startDate: any, endDate: any, includes: any = true, jobClass: string = 'J', clientId: string = "") {
    const endpoint = `${JOB_ENDPOINT}?startDate=${startDate}&endDate=${endDate}&includes=${includes}&jobClass=${jobClass}&clientId=${clientId}`;
    return this.baseApi.basicGet(endpoint);
  }

  get(startDate: any, endDate: any, includes: any = true, jobClass: string = 'J', clientId: string = "") {
    const endpoint = `${JOB_ENDPOINT}?startDate=${startDate}&endDate=${endDate}&includes=${includes}&jobClass=${jobClass}&clientId=${clientId}`;
    return this.baseApi.getObs(endpoint);
  }

  getJobsSummary(startDate: any, endDate: any) {
    const endpoint = `${JOB_ENDPOINT}/summary?startDate=${startDate}&endDate=${endDate}`;
    return this.baseApi.getObs(endpoint);
  }

  getJobsSummary2(startDate: any, endDate: any) {
    const endpoint = `${JOB_ENDPOINT}/summary2?startDate=${startDate}&endDate=${endDate}`;
    return this.baseApi.getObs(endpoint);
  }

  getJobsSummaryByJobType(id: string, startDate: any, endDate: any) {
    const endpoint = `${JOB_ENDPOINT}/summaryJobType/${id}?startDate=${startDate}&endDate=${endDate}`;
    return this.baseApi.getObs(endpoint);
  }

  getJobsByEmployeeId(employeeId: any, startDate: any, endDate: any, includes: any = true) {
    const endpoint = `${JOB_ENDPOINT}/Employee/${employeeId}?startDate=${startDate}&endDate=${endDate}&includes=${includes}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobById(id: any) {
    const endpoint = `${JOB_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobByJobNumber(id: any, jobClass: any  = 'J') {
    const endpoint = `${JOB_ENDPOINT}/number/${jobClass}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobByJobNumberObs(id: any, jobClass: any  = 'J') {
    const endpoint = `${JOB_ENDPOINT}/number/${jobClass}/${id}`;
    return this.baseApi.getObs(endpoint);
  }

  getClientJobs(clientId: any, startDate: any = null, endDate: any = null) {
    if (startDate == null) { startDate = '2000-01-01' }
    if (endDate == null) { endDate = '2099-01-01' }

    const endpoint = `${JOB_ENDPOINT}?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`;
    return this.baseApi.basicGet(endpoint);
  }

  createJob(data: any) {
    return this.baseApi.basicCreate(JOB_ENDPOINT, data);
  }

  updateJob(data: any) {
    const endpoint = `${JOB_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  patchJob(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(JOB_ENDPOINT, payload.id, data);
  }

  deleteJob(id: any) {
    const endpoint = `${JOB_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  // deleteJobByNumber(id: any) {
  //   if (id < 30) return;
  //   const endpoint = `Job/number/${id}`;
  //   return this.baseApi.delete(endpoint);
  // }


  getJobCharge(id: any) {
    const endpoint = `${JOB_CHARGE_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobChargeByKsId(id: any) {
    const endpoint = `${JOB_CHARGE_ENDPOINT}/ksId/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  createJobCharge(data: any) {
    return this.baseApi.basicCreate(JOB_CHARGE_ENDPOINT, data);
  }

  updateJobCharge(data: any) {
    const endpoint = `${JOB_CHARGE_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  patchJobCharge(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(JOB_CHARGE_ENDPOINT, payload.id, data);
  }

  deleteJobCharge(id: any) {
    const endpoint = `${JOB_CHARGE_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }
  
  getJobChargeSummaryBySA(id: any) {
    const endpoint = `${JOB_CHARGE_ENDPOINT}/summary/sa/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  
   /* Job Time Entry */

  getJobTimeEntry(id: any) {
    const endpoint = `${JOB_TIME_ENTRY_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobTimeEntryByEmployeeId(id: any, isIncomplete: boolean = false) {
    const endpoint = `${JOB_TIME_ENTRY_ENDPOINT}/employee/${id}?isIncomplete=${isIncomplete}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobTimeEntryPendingTimeSheet() {
    const endpoint = `${JOB_TIME_ENTRY_ENDPOINT}/PendingTimeSheet`;
    return this.baseApi.basicGet(endpoint);
  }

  createJobTimeEntry(data: any) {
    return this.baseApi.basicCreate(JOB_TIME_ENTRY_ENDPOINT, data);
  }

  updateJobTimeEntry(data: any) {
    const endpoint = `${JOB_TIME_ENTRY_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  patchJobTimeEntry(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(JOB_TIME_ENTRY_ENDPOINT, payload.id, data);
  }

  deleteJobTimeEntry(id: any) {
    const endpoint = `${JOB_TIME_ENTRY_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  /* Job Schedule */

  getJobTask(id: any) {
    const endpoint = `${JOB_SCHEDULE_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobSchedules(startDate: any, endDate: any, includes: any = true) {
    const endpoint = `${JOB_SCHEDULE_ENDPOINT}?startDate=${startDate}&endDate=${endDate}&includes=${includes}`;
    return this.baseApi.getObs(endpoint);
  }

  getJobSchedulesForPayroll(startDate: any, endDate: any) {
    const endpoint = `${JOB_SCHEDULE_ENDPOINT}/payroll?startDate=${startDate}&endDate=${endDate}`;
    return this.baseApi.getObs(endpoint);
  }

  getJobSchedulesByEmployee(startDate: any, endDate: any, includes: any = true, employeeId = '') {
    const endpoint = `${JOB_SCHEDULE_ENDPOINT}?startDate=${startDate}&endDate=${endDate}&includes=${includes}&employeeId=${employeeId}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobTaskByKsId(id: any) {
    const endpoint = `${JOB_SCHEDULE_ENDPOINT}/ksId/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  createJobSchedule(data: any) {
    return this.baseApi.basicCreate(JOB_SCHEDULE_ENDPOINT, data);
  }

  updateJobSchedule(data: any) {
    const endpoint = `${JOB_SCHEDULE_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  patchJobSchedule(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(JOB_SCHEDULE_ENDPOINT, payload.id, data);
  }

  deleteJobSchedule(id: any) {
    const endpoint = `${JOB_SCHEDULE_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  /* RecurringJobs */

  getRecurringJobTemplate(id: any) {
    const endpoint = `${RECURRING_JOB_TEMPLATE}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getRecurringJobTemplates() {
    return this.baseApi.basicGet(RECURRING_JOB_TEMPLATE);
  }

  createRecurringJobTemplate(data: any) {
    return this.baseApi.basicCreate(RECURRING_JOB_TEMPLATE, data);
  }

  updateRecurringJobTemplate(data: any) {
    const endpoint = `${RECURRING_JOB_TEMPLATE}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  patchRecurringJobTemplate(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(RECURRING_JOB_TEMPLATE, payload.id, data);
  }

  deleteRecurringJobTemplate(id: any) {
    const endpoint = `${RECURRING_JOB_TEMPLATE}/${id}`;
    return this.baseApi.delete(endpoint);
  }



  /* Job History */
  getJobHistoryForJob(id: any) {
    const endpoint = `${JOB_HISTORY_ENDPOINT}/Job/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  /* Job Notes */
  getJobNotesObs(pageSize: any = 100, page: any = 1, type: any = "", status: any = 'draft') {
    const endpoint = `${JOB_NOTE_ENDPOINT}?type=${type}&status=${status}&Limit=${pageSize}&Page=${page}`; 
    return this.baseApi.getObs(endpoint);
  }

  getJobNotesForJob(id: any) {
    const endpoint = `${JOB_NOTE_ENDPOINT}/Job/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getJobNotesForClientId(id: any) {
    const endpoint = `${JOB_NOTE_ENDPOINT}/Job/client/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  createJobNote(data: any) {
    delete data.id;
    return this.baseApi.basicCreate(JOB_NOTE_ENDPOINT, data);
  }

  updateJobNote(data: any) {
    const endpoint = `${JOB_NOTE_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  deleteJobNote(id: any) {
    const endpoint = `${JOB_NOTE_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  /* Job Notes Type */
  getJobNoteTypes() {
  
    return this.baseApi.basicGet(JOB_NOTE_TYPE_ENDPOINT);
  }

  getJobNoteTypesObs() {
    return this.baseApi.getObs(JOB_NOTE_TYPE_ENDPOINT);
  }

  createJobNoteTypes(data: any) {
    return this.baseApi.basicCreate(JOB_NOTE_TYPE_ENDPOINT, data);
  }

  updateJobNoteTypes(data: any) {
    const endpoint = `${JOB_NOTE_TYPE_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  patchJobNoteTypes(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(JOB_NOTE_TYPE_ENDPOINT, payload.id, data);
  }

  deleteJobNoteTypes(id: any) {
    const endpoint = `${JOB_NOTE_TYPE_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  // savePayRequestIdToJobCharge(payReqId: any, jobChargeId: any) {
  //   const promise = new Promise((resolve, reject) => {
  //     const record: any = this.getJobCharge(jobChargeId);
  //     if (record) {
  //       record.PayRequestId = payReqId;
  //       const payload = [{
  //         "id": jobChargeId,
  //         "op": "replace",
  //         "path": "/payRequestId",
  //         "value": payReqId
  //       }];
  //       this.baseApi.updatePartial('jobCharge', jobChargeId, payload).then((data: any) => {
  //         resolve(true);
  //       });
  //     } else {
  //       resolve(false);
  //     }
  //   });
  //   return promise;
  // }

  createJobPayment(data: any) {
    // return this.baseApi.basicCreate('JobPayment', data);
  }

  updateJobPayment(data: any) {
    // const endpoint = `JobPayment/${data.id}`;
    // return this.baseApi.update(endpoint, data);
  }

  patchJobPayment(payload: any) {
    // const data: any = this.baseApi.createPatchObject(payload);
    // return this.baseApi.updatePartial(`JobPayment`, payload.id, data);
  }

  deleteJobPayment(id: any) {
    // const endpoint = `JobPayment/${id}`;
    // return this.baseApi.delete(endpoint);
  }

  stripeCreatePaymentUrl(jobId: any, amount: any, quantity: any, isDev = false, isTest = false) {
    const data = {
      jobId: jobId,
      amount: amount,
      quantity: quantity,
      isDev: isDev,
      isTest: isTest
    }

    return this.baseApi.basicCreate('Stripe', data);
  }


  /* lock timeentry */
  async lockTimeEntry(id: any) {
    this.changeTimeEntryLockStatus(id, true);
  }

  async unLockTimeEntry(id: any) {
    this.changeTimeEntryLockStatus(id, false);
  }

  changeTimeEntryLockStatus(id: any, locked: any) {
    const updateItem = {
      id: id,
      IsLocked: locked     
    };
    this.patchJobTimeEntry(updateItem);
  }

  /* lock job schedule */
  async lockJobSchedule(id: any) {
    this.changeJobScheduleLockStatus(id, true);
  }

  async unLockJobSchedule(id: any) {
    this.changeJobScheduleLockStatus(id, false);
  }

  changeJobScheduleLockStatus(id: any, locked: any) {
    const updateItem = {
      id: id,
      IsLocked: locked     
    };
    this.patchJobSchedule(updateItem);
  }

  /* lock job charges */
  async lockJobCharge(id: any) {
    this.changeJobChargeLockStatus(id, true);
  }

  async unLockJobCharge(id: any) {
    this.changeJobChargeLockStatus(id, false);
  }

  changeJobChargeLockStatus(id: any, locked: any) {
    const updateItem = {
      id: id,
      IsLocked: locked     
    };
    this.patchJobCharge(updateItem);
  }

}
