import { Component, OnInit } from '@angular/core';
import { InternalMailService } from '@core/services/internal-mail.service';
import { SettingsService } from 'src/app/core/settings/settings.service';

@Component({
  selector: 'app-offside-panel',
  templateUrl: './offside-panel.component.html',
  styleUrls: ['./offside-panel.component.scss']
})
export class OffsidePanelComponent {

  panelTitle: any;

  constructor(
    private internalMailService: InternalMailService,
    private settings: SettingsService,
  ) { }

  ngAfterViewInit() {
    this.internalMailService.panelDetail.subscribe((data: any) => {
        this.panelTitle = data;
        this.settings.toggleLayoutSetting('offsidePanelOpen');
    })
  }

  closePanel() {    
    this.settings.setLayoutSetting('offsidePanelOpen', false);
  }

}
