import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// ngx-bootstrap modules
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
//import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
//import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

// syncfusion modules used by many pages
// import { TooltipModule } from '@syncfusion/ej2-angular-popups';
// import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

// other modules
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask, IConfig } from 'ngx-mask';

// services
import { ColorsService } from './colors/colors.service';

// directives
import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { NowDirective } from './directives/now/now.directive';
import { MouseFollowDirective } from './directives/mousefollow/mousefollow.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';

// pipes
import { CustomdatePipe } from './pipes/customdate.pipe';
import { CleanDatePipe } from './pipes/cleandate.pipe';
import { StringSplitPipe } from './pipes/stringsplit.pipe';
import { HtmlDisplayPipe } from './pipes/htmlDisplay.pipe';
import { RemoveHtmlTagsPipe } from './pipes/removeHtmlTags.pipe';

// loading modules
import { BlockUIModule } from 'ng-block-ui';
import { LoadingSpinnerComponent } from '@components/loading-components/loading-spinner/loading-spinner.component';
import { LoadingRefreshComponent } from '@components/loading-components/loading-refresh/loading-refresh.component';
export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    //BsDatepickerModule,
    //ModalModule,
    PaginationModule,
    //TooltipModule,
    //CheckBoxModule,
    //TypeaheadModule,
    BlockUIModule.forRoot(),
    //LoadingComponentsModule,
    LoadingSpinnerComponent,
    LoadingRefreshComponent,
    ScrollableDirective,
    //AvatarModule,
    NgxMaskDirective, NgxMaskPipe,
    NgSelectModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),

  ],
  providers: [ColorsService, provideNgxMask()],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    NowDirective,
    MouseFollowDirective,
    CustomdatePipe,
    CleanDatePipe,
    StringSplitPipe,
    HtmlDisplayPipe,
    RemoveHtmlTagsPipe
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    //BsDatepickerModule,
    ModalModule,
    PaginationModule,
    //TypeaheadModule,
    ToastrModule,
    FlotDirective,
    EasypiechartDirective,
    CheckallDirective,
    NowDirective,
    MouseFollowDirective,
    ScrollableDirective,
    SparklineDirective,
    NgSelectModule,
    NgxMaskDirective, NgxMaskPipe,
    CustomdatePipe,
    CleanDatePipe,
    StringSplitPipe,
    HtmlDisplayPipe,
    RemoveHtmlTagsPipe,
    BlockUIModule,
    LoadingSpinnerComponent,
    LoadingRefreshComponent
  ],
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
