import { Component, OnInit, inject } from '@angular/core';
//import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';

import Swal from 'sweetalert2'

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    private authService = inject(AuthService);
    private fb = inject(FormBuilder);

    public valForm: FormGroup;
    
    isLoadingReset = false;

    constructor() {       
        this.valForm = this.fb.group({
            'email': [null, [Validators.required, Validators.email]]
        });
    }

    submitForm($ev: any, value: any) {
        $ev.preventDefault();
        // for (let c in this.valForm.controls) {
        //     this.valForm.controls[c].markAsTouched();
        // }
        var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
          
        if (this.valForm.valid) {
            this.resetPassword(value)
        }
    }

    resetPassword(value: any) {
        console.log(value);
        this.isLoadingReset = true
        this.authService.resetPassword(value.email).then((user: any) => {
            Swal.fire({
                title: 'Password reset',
                text: 'We just sent you a reset link to your email',
                icon: 'success'
            });

        }, (error: any) => {
            Swal.fire({
                title: 'Error while trying to reset password',
                text: 'Please check the email address is correct', // error.message,
                icon: 'warning',
                confirmButtonColor: '#DD6B55',
            });
        }).finally(() => {
            this.isLoadingReset = false;
        });
    }

    ngOnInit() { }

}
