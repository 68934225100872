import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { MenuService } from '@core/menu/menu.service';
import { SettingsService } from '@core/settings/settings.service';
import { DocumentService } from '@core/services/document.service';

import map from 'lodash-es/map';
import { find, findIndex } from 'lodash-es';
@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})
export class HorizontalComponent implements OnInit {

  currentProvider: any;
  menuItems: Array<any>;
  actionMenuItems: any;
  router: Router;
  user: any;
  $doc: any = null;

  constructor(
    public menuService: MenuService,
    public settings: SettingsService,
    public injector: Injector,
    private settingsService: SettingsService,
    private documentService: DocumentService,
    ) { }

  ngOnInit(): void {
    this.router = this.injector.get(Router);
    this.router.events.subscribe((val) => {});
    this.user = this.settingsService.getUserSetting(null);
    this.settingsService.actionMenuItems.subscribe((val) => { this.actionMenuItems = val; });

    const m = this.menuService.getMenu();
    var newMenu: any = [];
    map(m, menuItem =>  { 
      if (menuItem.heading === true) {
        menuItem.items = [];
        newMenu.push(menuItem);
      } else  { 
        let i = newMenu.length-1;
        if (newMenu[i]) {
          newMenu[i].items !== null ? newMenu[i].items.push(menuItem) : newMenu[i].items = [menuItem];       
        }
      }
      
    });
    this.menuItems = newMenu;
    console.log('menuItems', this.menuItems)
    this.addTrialMenuItem();

    let organisation = this.settingsService.getOrgSettings();
    console.log('organisation', organisation)

    var urlCreator = window.URL || window.webkitURL;
    var documentId = organisation.logoDocumentId;

    // TODO review
    if (organisation && documentId && documentId != '00000000-0000-0000-0000-000000000000') {
      //const logoDocument: any = await this.documentService.getDocument(documentId);
      var urlCreator = window.URL || window.webkitURL;
      //localStorage.removeItem('logoData')
      
      // TODO 
      // the image should be cropped and compressed down or saved on the browser
      // this browser store is good until we change the logo on the server side
      const cachedLogoData = localStorage.getItem('logoData');

      if (cachedLogoData) {
        // If it exists, use the cached logo data
        this.placeLogoIntoSection(cachedLogoData);
        console.log('use cached img')
      } else {
        // If it doesn't exist, fetch the logo from the API
        //this.fetchLogoFromAPI();

        //console.log('****** write cached img')
        this.documentService.getFile(documentId).then((data: any) => {

          const reader = new FileReader();
  
          reader.onload = (event: any) => {
            // Convert the Blob data to a Base64 string
            const base64Data = event.target.result;
            
            // Store the Base64 string in localStorage
            localStorage.setItem('logoData', base64Data);
            
            // Use the Base64 string as the image source
            this.placeLogoIntoSection(base64Data);
          };
          
          reader.readAsDataURL(data); // Read the Blob data as a data URL
        });
      }
    }
  }

  addTrialMenuItem() {
    if (this.user.email === 'mirceah13@gmail.com' || this.user.email === 'alan@finance.co.jp') {
      const settingsMenu = find(this.menuItems, mi => mi.text === 'Settings');
      const currentIndex = findIndex(settingsMenu.items, { text: 'Start a trial' });
      const newIndex = findIndex(settingsMenu.items, { text: 'Create Organisation' });

      if (currentIndex > -1) { return; }
      if (newIndex > -1) {
        const trialMenuItem = {text: 'Start a trial', link: 'trial', icon: '', roles: ['admin'] }
        settingsMenu.items.splice(newIndex + 1, 0, trialMenuItem);
      }
    } 
  }

  placeLogoIntoSection(imageUrl) {
    var imgCtrlTop: any = document.querySelector('#imageHeaderOrgTop');
    if (imgCtrlTop) {
        imgCtrlTop.style.width = 'auto';
        imgCtrlTop.style.height = '32px';
        imgCtrlTop.src = imageUrl;                        
    }   
  }

  async gotoActionMenuItem(item: any) {
    const url = item.link;
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    return this.router.navigateByUrl(url);
  }

  async Goto(link: string) {
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    return this.router.navigateByUrl(link);
   
  }

}
