import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EditClinicNoteComponent } from '@components/modal/edit-clinic-note/edit-clinic-note.component';
import { JobService } from '@core/services/job.service';
import { ToastrService } from 'ngx-toastr';
import { BusinessService } from 'src/app/services/business.service';

import dayjs from 'dayjs';
import Swal from 'sweetalert2'
import cloneDeep from 'lodash-es/cloneDeep';
import filter from 'lodash-es/filter';
import last from 'lodash-es/last';
import find from 'lodash-es/find';
import remove from 'lodash-es/remove';
import each from 'lodash-es/each';
import { SettingsService } from '@core/settings/settings.service';
import { PriceGuideService } from '@core/services/price-guide.service';
import { v4 as uuidv4 } from 'uuid';
import { UtilityService } from 'src/app/services/utility.service';
import clone from 'lodash-es/clone';
import { SharedModule } from '../../shared/shared.module';
import { EditClinicNoteComponent as EditClinicNoteComponent_1 } from '../modal/edit-clinic-note/edit-clinic-note.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskDirective } from 'ngx-mask';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { flatMap, map, orderBy, some, uniq } from 'lodash-es';
import { ClinicNoteService } from '@core/services/clinic-note.service';

@Component({
  selector: 'schedules-component',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TooltipModule, BsDatepickerModule, FormsModule, NgxMaskDirective, NgSelectModule, ModalModule, EditClinicNoteComponent_1, DecimalPipe, SharedModule]
})
export class SchedulesComponent implements OnInit {
  @ViewChild('clinicNoteModal') private EditClinicNoteComponent!: EditClinicNoteComponent;
  @Output() scheduleDateSelect = new EventEmitter<any>();
  @Input() job: any;
  @Input() allJobTypes: any;
  @Input() rosterCallback: any;
  @Input() user: any;
  @Input() workTypes: any = [];
  @Input() employees: any = [];
  @Input() guides: any = [];
  @Input() client: any = []; //only used when adding new job and no job object yet
  @Input() isInModal: any;
  @Input() scheduleDate: any;
  @Input() modalCode: any;
  @Input() groupings: any;
  jobTypes: any = [];
  officeJobTypes: any = [];
  selectedNote: any;
  selectedScheduleItem: any;
  timeReg: any = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  notesActive: any = [];

  constructor(private toastrService: ToastrService,
    private router: Router,
    private businessService: BusinessService,
    private jobService: JobService,
    private settingsService: SettingsService,
    private priceGuideService: PriceGuideService,
    private clinicNoteService: ClinicNoteService,
    private utilityService: UtilityService) { }

  async ngOnInit() {
    this.user = this.settingsService.getUserSetting(null);

    await new Promise(f => setTimeout(f, 2000)); // pause to make sure not already processing call of api twice
    this.guides = await this.priceGuideService.load();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.client && changes.client.currentValue) {
      const client = changes.client.currentValue;
      if (client.id) {
        this.job.client = client;     
        
        this.notesActive = await this.getNotesActive(client.id);
        console.log('this.notesActive', this.notesActive);
      }

      if (this.is_IM()) {
        this.onIsNDISJobChange('office')
      }
    }

    // limit choices if job is a group
    if (changes.job && changes.job.currentValue) {
      //console.log('this.allJobTypes', this.allJobTypes)
      if (this.job.isGroupActivity) { this.allJobTypes = filter(this.allJobTypes, wt => wt.name?.includes('Group')) }
      this.job.summary = this.businessService.getJobMarginEstimates(this.job);
      this.job.schedules = orderBy(this.job.schedules, 'startTimeDisplay');
      //if any of the schedules have workType == 'ShadowShift' then set the job.isShadowShift = true
      this.job.isShadowShift = some(this.job.schedules, s => s.workType == 'SS');
      this.enrichGroupings();

      if (this.job != null && this.job.clientId) {
        this.notesActive = await this.getNotesActive(this.job.clientId)
        //console.log('call this.notesActive in schedule component', this.notesActive)
      }
    }
  }

  onIsNDISJobChange(item: any) {
    console.log('item', item)
    const prefixPattern = item.isNDISJob ? /^NDIS/ : /^(?!NDIS|.*Team Meeting)/;
    const officePattern =  /^.* Team Meeting/;

    this.jobTypes = filter(this.allJobTypes, jt => prefixPattern.test(jt.name));    
    this.officeJobTypes = filter(this.allJobTypes, jt => officePattern.test(jt.name));

    console.log('this.jobTypes', this.jobTypes)
    console.log('this.officeJobTypes', this.officeJobTypes)
    // if (item.isNDISJob) {
    //   this.jobTypes = filter(this.allJobTypes, jt => jt.name.substring(0, 4) === 'NDIS');
    // } else {
    //   this.jobTypes = filter(this.allJobTypes, jt => jt.name.substring(0, 4) !== 'NDIS');
    // }
    // only show jobtypes for this paySource
  }

  openEditClinicNoteModal(note: any) {
    this.selectedNote = cloneDeep(note);
    this.EditClinicNoteComponent.open();
  }

  goToStaffDetail(item) {
    this.router.navigate(['staff/detail/' + item.id]);
  }

  openModal(modalCtrl: any, item: any) {
    this.selectedScheduleItem = item;
    modalCtrl.show();
  }

  scheduleGotoEditMode(item: any) {
    item.jobTypeId = item.jobType?.id;
    item.beforeEdit = cloneDeep(item);
    item.editMode = true;
    this.onTimeChange(item);
    this.onIsNDISJobChange(item);
  }

  scheduleAddNew() {

    const date: any = last(this.job.schedules);
    const newScheduleDate = this.scheduleDate ? this.scheduleDate.format('DD/MM/YYYY') : (this.job.schedules?.length ? date.startDate : dayjs().format('DD/MM/YYYY'));

    const newSchedule =
    {
      editMode: true,
      employees: [],
      startDate: newScheduleDate,
      endDate: newScheduleDate,
      isNDISJob: true,
      isMobile: false,
      isKmsPayable: false,
      isHrsPayable: false,
      isChargeable: false,
      isSleepover: false,
      isTravelTimeChargeable: false,
      preTravelHours: 0,
      postTravelHours: 0,
      description: '',
      taskTypeId: 122318, //WORK
      newRow: true,
      name: '',
      idx: uuidv4()
    };

    if (!this.job) { this.job = { schedules: [] } };
    this.onIsNDISJobChange(newSchedule);
    this.job.schedules.push(newSchedule);
  }

  cancelSchedule(item: any) {
    if (item.newRow) {
      remove(this.job.schedules, (s: any) => s.newRow === true);
    } else {
      item.editMode = false;
      if (item.beforeEdit) { Object.assign(item, item.beforeEdit); }
    }
  }

  scheduleDelete(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      html: 'Schedule Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (res: any) => {
      if (res.value) {
        if (item.newRow) { remove(this.job.schedules, (s: any) => s.idx === item.idx); return; }
        item.inProgress = true;
        await this.jobService.deleteJobSchedule(item.id)
          .then(() => remove(this.job.schedules, (s: any) => s.id === item.id))
          .catch((err) => {
            this.toastrService.error('Error', `Something went wrong while trying to delete. Err: ${err}`);
          });
        if (this.rosterCallback) { this.rosterCallback(); }
        this.job.summary = this.businessService.getJobMarginEstimates(this.job);
        item.editMode = false;
      }
    });
  }

  isScheduleValid(item) {
    if (item) {
      let isValid = item.isStartValid && item.isEndValid && (item.startDate && this.utilityService.isValidDate(item.startDate));
      // if (this.modalCode === 'ta') { isValid &&= (item.endDate && this.utilityService.isValidDate(item.endDate)) }
      // if (this.hasJobType) { isValid &&= (item.jobTypeId || item.jobType) }
      return isValid;
    }
    return false;
  }

  onSaveScheduleLineItem(item: any) {  
    if (this.isInModal) {

      // console.log('item', item)
      // console.log('this.job', this.job)

      // < 2 because this is the 1st one itself
      if (this.job.schedules.length < 2 && item.workType == 'SS') {
        this.toastrService.error('Error', `You can not have a Shadow Shift without the main shift being created first`);
        return false;
      }

      this.onTimeChange(item);
      let startDate = !isNaN(item.startDate) ? dayjs(item.startDate).format('YYYY-MM-DD') : dayjs(item.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      let endDate = !isNaN(item.endDate) ? dayjs(item.endDate).format('YYYY-MM-DD') : dayjs(item.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

      item.startDateTime = startDate + 'T' + this.businessService.extractTime(item.startTime) + ':00';
      item.endDateTime = endDate + 'T' + this.businessService.extractTime(item.endTime) + ':00';
      if (item.beforeEdit) { delete item.beforeEdit; }

     

      if (!this.job.id) { // if job is new we cannot save until we have job object saved so we can have job id to pass to schedule line items
        item.jobType = find(this.jobTypes, jt => jt.id === item.jobTypeId);
        item.workTypeItem = find(this.workTypes, wt => wt.code === item.workType);
        item.editMode = false;
      } else {
        this.saveSchedule(item);
      }
    } else {
      this.saveSchedule(item);
    }
  }

  async saveSchedule(item: any, jobId: any = null) {

    if (this.job.schedules.length < 2 && item.workType == 'SS') {
      this.toastrService.error('Error', `You can not have a Shadow Shift without the main shift being created first`);
      return false;
    }

    // dont allow travel to be charged if not mobile shift
    if (item.isMobile == false) { item.isTravelTimeChargeable = false;}
    //console.log('item', item  )

    item.inProgress = true;
    this.onTimeChange(item);
    let startDate = this.formatDateFromTime(item.startDate);
    let endDate = this.formatDateFromTime(item.endDate);

    // save with TZ based on the State of ServiceAddress
    // const state = item.job?.serviceAddress?.servicePostcode;
    // let tz = this.businessService.getTimeZone(state); //TODO: remove at some point;

    const startDateTime = startDate + 'T' + this.businessService.extractTime(item.startTime) + ':00';
    const endDateTime = endDate + 'T' + this.businessService.extractTime(item.endTime) + ':00';

    const officeLeaveDateTime = item.officeLeaveTime ? startDate + 'T' + this.businessService.extractTime(item.officeLeaveTime) + ':00' : null;
    const officeReturnDateTime = item.officeReturnTime ? endDate + 'T' + this.businessService.extractTime(item.officeReturnTime) + ':00' : null;
    const travelTime = item.jobType?.name && item.jobType?.name.includes('Therapy') ? 0.5 : 1;

    const payload: any = {
      jobId: jobId !== null ? jobId : this.job.id,
      name: item.name,
      workType: item.workType,
      description: item.description,
      start: startDateTime,
      end: endDateTime,
      isKmsPayable: item.isKmsPayable,
      isHrsPayable: item.isHrsPayable,
      isChargeable: item.isChargeable,
      isSleepover: item.isSleepover,
      isMobile: item.isMobile,
      officeLeaveTime: officeLeaveDateTime,
      officeReturnTime: officeReturnDateTime,
      isTravelTimeChargeable: item.isTravelTimeChargeable,
      preTravelHours: travelTime / 2,
      postTravelHours: travelTime / 2,
      //paySource: item.paySource,
      grouping: item.grouping,
      isNDISJob: item.isNDISJob,
      taskTypeId: item.taskTypeId || 122318, //WORK
      jobTypeId: item.jobTypeId,
      employees: item.employees
    };

    if (item.beforeEdit) { delete item.beforeEdit; }
    if (item.id) { payload.id = item.id; }

    let action;
    if (item.id) {
      action = this.jobService.updateJobSchedule(payload);
    } else {
      action = this.jobService.createJobSchedule(payload);
    }

    await action.catch((err) => { console.error(err); this.toastrService.error('Error', 'Something went wrong while trying to save Schedule') });
    item.editMode = false;
    item.start = item.startDateTime;
    item.end = item.endDateTime;
    this.businessService.curateDates([item], 'schedules');
    item.inProgress = false;
    this.job.summary = this.businessService.getJobMarginEstimates(this.job);
    this.enrichGroupings();
    if (this.rosterCallback) {
      this.rosterCallback();
    }
  }

  scheduleUnlock(item: any) {
    // roles - array code = 'A' 
    if (this.user?.email !== 'alan@finance.co.jp') {
      this.toastrService.error('Error', 'Admin Role is required to unlock charges');
      return false;
    }

    Swal.fire({
      title: 'Are you sure?',
      html: 'Unlock Schedule',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((res: any) => {
      if (res.value) {
        const patchItem = {
          id: item.id,
          isLocked: false
        }
        item.inProgress = true;
        this.jobService.patchJobSchedule(patchItem).then(() => {
          if (this.rosterCallback) {
            // this.getRosterData(this.id);
            this.rosterCallback();
          }
          this.toastrService.success('Success', 'Schedule Unlocked');
        }).catch((error: any) => {
          this.toastrService.error('Error', `Something went wrong while trying to unlock schedule. ${error.message}`);
        }).finally(() => item.inProgress = false)
      }
    });
  }

  onTimeChange(item: any, setHours: boolean = true) {
    const isStartValid = item.startTime ? this.businessService.extractTime(item.startTime).match(this.timeReg) : false;
    const isEndValid = item.endTime ? this.businessService.extractTime(item.endTime).match(this.timeReg) : false;
    item.isStartValid = isStartValid;
    item.isEndValid = isEndValid;

    if (item.isStartValid && item.isEndValid) {
      this.businessService.calcHours(item, setHours);
    }
  }

  onJobTypeChange(item: any) {
    if (item.jobTypeId) {
      if (!item.workType || item.workType === 'DS') {
        item.workType = 'SUPP';
      }
    }
    if (item.workType == 'SUPP') { item.isChargeable = true; }
    let jobType = find(this.jobTypes, a => a.id === item.jobTypeId);
    let jobScheduleItem = find(this.job.schedules, a => a.idx === item.idx);
    if (jobType && jobScheduleItem) {
      // TODO need 2 way binding
      item.isKmsPayable = jobType.isKmsPayable;
      item.isHrsPayable = jobType.isHrsPayable;
    }
  }

  jobChargeAssistAddNewCharge(modalCtrl: any, item: any) {   
    const inEffectPriceGuide: any = this.businessService.getSupportItemNumbers(this.guides, item.start);
    const prs: any = [];
    if (this.job.isGroupActivity) {
      each(filter(this.job.groupClientBookings, c => !!c.checked), booking => {
        let newItem: any = (item === this.job)
          ? this.businessService.jobChargeKmsAssistCreateItem(inEffectPriceGuide, this.job)
          : this.businessService.jobChargeAssistCreateItem(inEffectPriceGuide, this.job, item);

        newItem.clientId = booking.client.id;
        prs.push(this.saveCharge(newItem));
      });
    } else {
      // check if schedule has travel time on it to be created as a separate charge line
      //console.log('item in schedules component', item);

      if (item.isTravelTimeChargeable) {
        var travelTime = item.jobType?.name && item.jobType?.name.includes('Therapy') ? 0.5 : 1;
        // take off the added hours for travel time
        item.hours = item.hours - travelTime;

        const itemTravelTime = cloneDeep(item);
        itemTravelTime.hours = travelTime;

        let newItemTravelTime: any = this.businessService.jobChargeAssistCreateItem(inEffectPriceGuide, this.job, itemTravelTime);
        newItemTravelTime.clientId = this.job.client.id;
        newItemTravelTime.supportItemNumber = newItemTravelTime.supportItemNumber + ' (Provider Travel - labour costs)'
        newItemTravelTime.claimType = 'TRAN';
        //console.log('newItemTravelTime', newItemTravelTime);

        prs.push(this.saveCharge(newItemTravelTime));
      }

      // Medicare Rebate
      // if item is tagged as medicareRebate then we need to adjust the invoice and add an extra line item
      item.isMedicareRebate = item.jobType?.name.includes('Medicare');
      if (item.isMedicareRebate) {
        const medicareCode = item.jobType?.name.includes('Medicare Speech') ? '10970' : item.jobType?.name.includes('Medicare Occupational') ? '10958' : 'N/A';
        const itemMedicareRebate = cloneDeep(item);
        itemMedicareRebate.hours = 1;
        let newItemMedicareRebate: any = this.businessService.jobChargeAssistCreateItem(inEffectPriceGuide, this.job, itemMedicareRebate);
        newItemMedicareRebate.clientId = this.job.client.id;
        newItemMedicareRebate.supportItemNumber = newItemMedicareRebate.supportItemNumber + ' (Medicare Rebate Item ' + medicareCode + ')' ;
        newItemMedicareRebate.unit = -58;
        //10970 speech code $58
        //
        // 10958 OT code

        prs.push(this.saveCharge(newItemMedicareRebate));
      }

      let newItem: any = (item === this.job)
        ? this.businessService.jobChargeKmsAssistCreateItem(inEffectPriceGuide, this.job)
        : this.businessService.jobChargeAssistCreateItem(inEffectPriceGuide, this.job, item);

      newItem.clientId = this.job.client.id;
      prs.push(this.saveCharge(newItem));
    }

    modalCtrl.hide();
  }

  createShadowShift(modalCtrl: any, item: any) {

    let startDate = this.formatDateFromTime(item.startDate);
    let endDate = this.formatDateFromTime(item.endDate);

    const startDateTime = startDate + 'T' + this.businessService.extractTime(item.startTime) + ':00';
    const endDateTime = endDate + 'T' + this.businessService.extractTime(item.endTime) + ':00';

    const officeLeaveDateTime = item.officeLeaveTime ? startDate + 'T' + this.businessService.extractTime(item.officeLeaveTime) + ':00' : null;
    const officeReturnDateTime = item.officeReturnTime ? endDate + 'T' + this.businessService.extractTime(item.officeReturnTime) + ':00' : null;
    const travelTime = item.jobType?.name && item.jobType?.name.includes('Therapy') ? 0.5 : 1;

    const newSchedule: any = {
      jobId: this.job.id,
      name: item.name,
      workType: 'SS',
      description: item.description,
      start: startDateTime,
      end: endDateTime,
      isKmsPayable: item.isKmsPayable,
      isHrsPayable: item.isHrsPayable,
      isChargeable: item.isChargeable,
      isSleepover: item.isSleepover,
      isMobile: item.isMobile,
      officeLeaveTime: officeLeaveDateTime,
      officeReturnTime: officeReturnDateTime,
      isTravelTimeChargeable: item.isTravelTimeChargeable,
      preTravelHours: travelTime / 2,
      postTravelHours: travelTime / 2,
      isNDISJob: item.isNDISJob,
      taskTypeId: item.taskTypeId || 122318, //WORK
      jobTypeId: item.jobType?.id,
      employees: item.selectedEmployees
    };

    this.jobService.createJobSchedule(newSchedule)
      .catch((err) => { console.error(err); this.toastrService.error('Error', 'Something went wrong while trying to save Schedule') });

    this.onIsNDISJobChange(newSchedule);
    this.job.schedules.push(newSchedule);

    if (this.rosterCallback) {
      this.rosterCallback();
    }

    modalCtrl.hide();
  }

  async saveCharge(item: any) {
    item.inProgress = true;
    const payload: any = {
      jobId: this.job.id,
      itemId: 0,
      isFixedTotal: item.isFixedTotal,
      description: item.description,
      details: item.details,
      unit: item.unit,
      taxType: item.taxType,
      claimType: item.claimType,
      cancellationReason: item.cancellationReason,
      quantity: item.quantity,
      chartAccountCode: item.chartAccountCode,
      itemCode: item.supportItemNumber,
      itemCodeType: item.itemCodeType,
      //supportItemNumber: item.supportItem?.number,
      total: item.total,
    };

    if (item.isFixedTotal) {
      //delete payload.quantity;
      //delete payload.unit;
      payload.quantity = 1;
      //payload.unit = item.total;
    }
    if (this.job.isGroupActivity) { payload.clientId = item.clientId; }
    if (item.beforeEdit) { delete item.beforeEdit; }

    let action;
    if (item.id) {
      payload.id = item.id;
      action = this.jobService.updateJobCharge(payload);
    } else {
      action = this.jobService.createJobCharge(payload);
    }

    await action;
    item.inProgress = false;
    if (this.rosterCallback) {
      this.rosterCallback();
    }
  }

  formatDateFromTime(date) {
    return !isNaN(date) ? dayjs(date).format('YYYY-MM-DD') : dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  closeModal(modal: any) { modal.hide(); }

  onModalHide(event: any) { }

  showSleepOver(item: any) {
    return item.jobTypeId === 'd09a14bc-c57f-4bc2-f300-08da7dca92e9'  // NDIS STA
      || item.jobTypeId === '4c95dbfd-5992-4ba3-9d41-08daf50154d3' // NDIS Assist-Personal Activities 
      || item.jobTypeId === '130ef6f6-a0e2-4c7f-1ef7-08dc6a6b2876'; // NDIS Assistance in SIL

  }

  showIsMobileBox(item: any) {
    return !this.job.isGroupActivity &&
      (
        // item.jobType?.name == 'NDIS Occupational Therapy' || 
        // item.jobType?.name == 'NDIS Speech Therapy' || 
        // item.jobType?.name == 'Medicare Speech Therapy' || 
        // item.jobType?.name == 'Medicare Occupational Therapy' || 
        // item.jobType?.name == 'Private Speech Therapy' || 
        // item.jobType?.name == 'Private Occupational Therapy' || 
        // item.jobType?.name == 'NDIS Behaviour Early Intervention' || 
        // item.jobType?.name == 'NDIS Therapy Support Other' || 
        // item.jobType?.name == 'NDIS Therapy Assistant' 

        // item.jobTypeId === 'c27fe4fb-886c-47dd-2bb8-08da74f2a1f5' // Medicare Occupational Therapy
        // || item.jobTypeId === '05838922-d059-4bcc-1be2-08da6dfcbebd' // Medicare Speech Therapy
        // || item.jobTypeId === '719bf9f5-1f1d-4f0b-2b88-08da5a51d92e' // NDIS Occupational Therapy 
        // || item.jobTypeId === '7daad05a-9c8f-458c-2b89-08da5a51d92e' // NDIS Speech Therapy
        // || item.jobTypeId === '4a236b01-c852-4f42-5344-08d9df14bac4' // NDIS Therapy Support Other
        true
      )
  }

  is_CE_TA_IM_PD() {
    const jclass = this.modalCode || this.job?.jobClass?.toLowerCase();
    if (jclass) {
      return jclass === 'ce' || jclass === 'ta' || jclass ==='im' ||  jclass === 'pd' || jclass === 're';
    } else return false;
  }

  is_IM() {
    const jclass = this.modalCode || this.job?.jobClass?.toLowerCase();
    if (jclass) {            
      return jclass === 'im';
    } else return false;
  }

  getGroupById(id: any) {
    return this.businessService.getGroupNameById(id);
  }

  enrichGroupings() {
    if (this.job?.schedules?.length) {
      map(this.groupings, grouping => {
        grouping.name = grouping.name.split('-')[0].trim();
        const matchedSchedules = filter(this.job.schedules, (schedule: any) => schedule.grouping === grouping.id);
        const employeeNames = uniq(flatMap(matchedSchedules, (schedule: any) => map(schedule.employees, emp => emp.fullName)));
        if (employeeNames.length) {
          const employeeNamesString = employeeNames.join(',');
          grouping.name += ` - ${employeeNamesString}`;
        }
      });
    }
  }

  canAddNew() {
    return !some(this.job.schedules, s => s.editMode && s.newRow);
  }

  onStartDateSelected(event) {
    this.scheduleDateSelect.emit(event);
  }

  async getNotesActive(clientId: any) {
    //console.log('call getNotesActive', clientId)
    const notesActive = await this.clinicNoteService.getNotesByClient(clientId, "", "true");
    return notesActive;
  }
}
