import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const ENDPOINT = 'sil';

@Injectable({
  providedIn: 'root'
})
export class SilService {

  constructor(private baseApi: CarestartApiService) { }

    /* OUTLET */

    getByIdObs(id: any) {
      const endpoint = `${ENDPOINT}/${id}`;
      return this.baseApi.getObs(endpoint);
    }

    getById(id: any) {
      return this.baseApi.basicGet(`${ENDPOINT}/${id}`)
    }
  
    getAll() {
      return this.baseApi.basicGet(ENDPOINT);          
    }

    getByState(state: any) {
      const endpoint =  `${ENDPOINT}/state/${state}`;
      return this.baseApi.basicGet(endpoint);
    }
  
    create(data: any) {
      delete data.id;
      return this.baseApi.basicCreate(ENDPOINT, data);
    }
  
    delete(id: any) {
      const endpoint = `${ENDPOINT}/${id}`;
      return this.baseApi.delete(endpoint);
    }
  
    patch(object: any) {
      const data: any = this.baseApi.createPatchObject(object);
      return this.baseApi.updatePartial(ENDPOINT, object.id, data);
    }

}
