import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';

@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    minsToExpire: number = 0;

    constructor(public settings: SettingsService) { }

    ngOnInit() {
        let tokenRefreshTime = this.settings.getAppSetting('tokenRefreshTime');
        const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
        const timeDifferenceSeconds = tokenRefreshTime - currentTimestamp;
        const minutesUntilTarget = Math.floor(timeDifferenceSeconds / 60);
        this.minsToExpire = minutesUntilTarget;
    }

}
