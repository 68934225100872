import { Injectable } from '@angular/core';
import { HttpService } from './../http.service';
import forOwn from 'lodash-es/forOwn';

@Injectable({
  providedIn: 'root'
})
export class CarestartApiService {

  constructor(private httpService: HttpService) { }

  basicCreate(endpoint: any, data: any) {
    const self = this;

    const promise = new Promise((resolve, reject) => {
      self.httpService.post(endpoint, data)
        .then((data: any) => {
          //console.log('data after create for ' + endpoint , data);
          resolve(data);
        })
        .catch(err => {
          console.log('data error after basicCreate', err);
          reject(err?.error || 'no error provided');  // dont change to resolve as it causes issues with signup
        });
    });
    return promise;
  }

  basicGet(endpoint: any) {
    const self = this;

    const promise = new Promise((resolve, reject) => {
      self.httpService.getData(endpoint).then((data: any) => {
        resolve(data);
      }).catch(error => {
        console.log('err for basicGet', error);
        reject(error);
      });
    });
    return promise;
  }

  update(endpoint: any, payload: any) {
    const self = this;

    const promise = new Promise((resolve, reject) => {
      self.httpService.put(endpoint, payload).then((data: any) => {
        resolve(data);
      }).catch(error => {
        console.log('err for update', error);
        reject(error);
      });
    });

    return promise;
  }

  delete(endpoint: any) {
    const self = this;

    const promise = new Promise((resolve, reject) => {
      self.httpService.delete(endpoint).then((data: any) => {
        resolve(data);
      }).catch(error => {
        console.log('err for delete', error);
        reject(error);
      });
    });

    return promise;
  }

  fileGet(endpoint: any, type) {
    const self = this;

    const promise = new Promise((resolve, reject) => {
      self.httpService.getFile(endpoint, type).then((data: any) => {
        resolve(data);
      }).catch(error => {
        console.log('err for fileGet', error);
        reject(error);
      });
    });
    return promise;
  }

  createPatchObject(object: any) {
    const updateItems: any = [];
    forOwn(object, function (value, key) {
      if (value !== undefined && key !== 'id') {
        const newItem = {
          //'id': object.id,
          'op': value !== undefined ? 'replace' : 'remove',
          'path': `/${key}`,
        }
        if (newItem.op === 'replace') newItem['value'] = value;
        updateItems.push(newItem);
      }
    });

    return updateItems;
  }

  updateSingleField(endpoint: any, id: any, item: any, value: any) {
    const self = this;
    console.log('item', item);
    const payload = [{
      "id": id,
      "op": "replace",
      "path": "/" + item,
      "value": value
    }];

    const promise = new Promise((resolve, reject) => {
      self.httpService.patch(endpoint + "/" + id, payload).then((data: any) => {
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });

    return promise;
  }

  updatePartial(endpoint: any, id: any, items: any) {
    const self = this;

    const payload = items;

    const promise = new Promise((resolve, reject) => {
      self.httpService.patch(endpoint + "/" + id, payload).then((data: any) => {
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });

    return promise;
  }

  postFile(formData: any) {
    return this.httpService.postFile(formData);
  }

  getObs(endpoint: any) {
    return this.httpService.getObs(endpoint);
  }

  // getObs<T>(endpoint: string): Observable<T> {
  //   return this.httpService.getObs(endpoint).pipe(shareReplay(1));
  // }

}
