<block-ui></block-ui>

<div class="auth-fluid auth-fluid-std">
    <!-- Auth fluid right content -->
    <div class="auth-fluid-right">
        <div class="auth-user-testimonial">
            <h3 class="mb-3 text-white">Simple & impressive!</h3>
            <p class="lead fw-normal"><i class="mdi mdi-format-quote-open"></i> I've been using Carestart for some time now and it has reduced the amount of time I spend on admin tasks. Our billing is always up-to-date and balanced. The interface is easy to understand and provides us with a level of comfort that no bills are getting left behind. <i class="mdi mdi-format-quote-close"></i>
            </p>
            <h5 class="text-white">
                - Plan Manager
            </h5>
        </div> <!-- end auth-user-testimonial-->
    </div>
    <!-- end Auth fluid right content -->

    <!--Auth fluid left content -->
    <div class="auth-fluid-form-box">
        <div class="align-items-center d-flex h-100">
            <div class="card-body">

                <!-- Logo -->
                <div class="auth-brand text-center text-lg-start">
                    <div class="auth-logo">
                        <a href="index.html" class="logo logo-dark text-center">
                            <span class="logo-lg">
                                <img src="../assets/img/logo/logo.png" alt="" height="75">
                            </span>
                        </a>
    
                        <a href="index.html" class="logo logo-light text-center">
                            <span class="logo-lg">
                                <img src="../assets/img/logo/logo.png" alt="" height="75">
                            </span>
                        </a>
                    </div>
                </div>

                <!-- title-->
                <h4 class="mt-0">Password Reset</h4>
                <p class="text-muted mb-4">Enter your email address to receive an email with a link to reset your password.</p>

                <!-- form -->
                <form [formGroup]="valForm" role="form" name="loginForm" class="needs-validation"
                        (submit)="submitForm($event, valForm.value)">
                    <div class="mb-2">
                        <label for="emailaddress" class="form-label">Email address</label>
                        <input type="email" id="emailaddress" class="form-control" formControlName="email" required placeholder="Enter your email">
                        <div id="validationServerUsernameFeedback" class="invalid-feedback">
                            Please enter a valid email address
                        </div>
                    </div>
                   
                    <div class="d-grid text-center mt-4">
                        
                        <button class="btn btn-purple" type="submit" [disabled]="isLoadingReset">
                            Reset
                            <i *ngIf="isLoadingReset" class="fa-solid fa-spinner fa-spin ms-1"></i> 
                        </button>
                    </div>
                   
                </form>
                <!-- end form-->

                <!-- Footer-->
                <footer class="footer footer-alt">
                    <p class="text-muted">Back to Login? <a [routerLink]="'/login'" class="text-primary fw-medium ms-1">Sign Up</a></p>
                </footer>

            </div> <!-- end .card-body -->
        </div> <!-- end .align-items-center.d-flex.h-100-->
    </div>
    <!-- end auth-fluid-form-box-->
</div>