<div class="row">
  <div class="col-rosters offset">
    <div class="card mt-0 mb-1">
      <div class="card-body pt-2">
        <div class="">
          <div class="filter-section">
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.fullName" [term]="t"></ngb-highlight>
            </ng-template>
            <ng-select class="dd me-1" [items]="clients" bindLabel="fullName" bindValue="id" style="width: 100%;"
              placeholder="Select Client" [(ngModel)]="selectedClientId" (change)="onSearchItemSelected()">
            </ng-select>
          </div>
          <div>
            <div>Client: <span class="text-primary hoverable"
              (click)="gotoClientDetail(selectedClient?.id)">{{selectedClient?.fullName}}</span></div>
            <div>Phone: <span class="text-muted">{{selectedClient?.phone || 'N/A'}}</span></div>
          </div>
          <hr />
          <div class="no-data" *ngIf="!schedules?.length">No Data</div>
          <loading-spinner *ngIf="isLoading"></loading-spinner>
          <scrollable [height]="screenHeight">
            <ng-container *ngFor="let schedule of schedules">
              <div class="job-container">
                <div class="job-detail">
                  <div>{{schedule.jobType?.name}}</div>
                  <div>{{schedule.employeeList}}</div>
                </div>
                <div class="date-detail">
                  <div>Date: <span class="text-muted">{{schedule.start | date}}</span></div>
                  <div>Time: <span class="text-muted">{{schedule.start}}</span></div>
                </div>
              </div>
            </ng-container>
          </scrollable>
        </div>
      </div>
    </div>
  </div>
