import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InternalMailService {

  private _data: any = { title: 'default' };
  subject = new Subject();

  private _panelDetail: any = { title: '' }
  panelDetail = new Subject();

  private _modalDetail: any = { title: '' }
  modalDetail = new Subject();

  private _pdfData: any = { id: null };
  pdfDocumentId = new Subject();

  private _imgData: any = { id: null };
  imgDocumentId = new Subject();

  tasksUpdate = new Subject();

  constructor() { }

  changeHeaderTitle(data: any) {
    this._data = data;
    console.log('data to send to offsidepanel has changed: ', this._data);
    this.subject.next(this._data);
  }

  clear() {
    this._data = { title: 'Default Header Name' };
    this.subject.next(this._data);
  }


  /** Panel Detail */
  changePanelDetail(data: any) {
    this._panelDetail = data;
    this.panelDetail.next(this._panelDetail);
  }

  clearPanelDetail() {
    this._panelDetail = { title: '' };
    this.panelDetail.next(this._panelDetail);
  }

  /** Modal Detail */
  changeModalDetail(data: any) {
    this._modalDetail = data;
    //console.log('modal Name to send to has changed: ', this._modalDetail);
    this.modalDetail.next(this._modalDetail);
  }

  clearModalDetail() {
    this._modalDetail = { title: '' };
    this.modalDetail.next(this._modalDetail);
  }


  /** PDF */
  changePdfDocumentId(data: any, location: any = 'right') {
    if (!data) return false;
    //console.log('data', data)
    
    data.location = location;
    this._pdfData = data;
    this.pdfDocumentId.next(this._pdfData);
  }

  clearPdfDetail() {
    this.pdfDocumentId.next('close');
  }

  /** image */
  changeImageDocumentId(data: any) {
    if (data === null) return false;

    this._imgData = data;
    this.imgDocumentId.next(this._imgData);
  }

  clearPdf() {
    this._pdfData = { id: null };
    this.pdfDocumentId.next(this._pdfData);
  }

  /** Tasks events */
  refreshTasks(task: any) {
    this.tasksUpdate.next(task);
  }

}
