import { Component, ErrorHandler, HostBinding, Inject, OnInit } from '@angular/core';
import { SettingsService } from './core/settings/settings.service';
import { ActivatedRoute, GuardsCheckEnd, GuardsCheckStart, RouterOutlet } from '@angular/router';
//import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
//import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { NgIf } from '@angular/common';
import { GlobalErrorHandler } from '@core/errors/global-error-handler';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NgIf],
    // providers: [
    //     { provide: ErrorHandler, useClass: GlobalErrorHandler },
    //   ],
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settingsService.getLayoutSetting('isFixed'); }
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settingsService.getLayoutSetting('isCollapsed'); }
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settingsService.getLayoutSetting('isBoxed'); }
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settingsService.getLayoutSetting('useFullLayout'); }
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settingsService.getLayoutSetting('hiddenFooter'); }
    @HostBinding('class.layout-h') get horizontal() { return this.settingsService.getLayoutSetting('horizontal'); }
    @HostBinding('class.aside-float') get isFloat() { return this.settingsService.getLayoutSetting('isFloat'); }
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settingsService.getLayoutSetting('offsidebarOpen'); }
    @HostBinding('class.offsideMessagePanel-open') get offsideMessagePanelOpen() { return this.settingsService.getLayoutSetting('offsideMessagePanelOpen'); }
    //@HostBinding('class.offsidebarPanel-open') get offsidebarPanelOpen() { return this.settingsService.getLayoutSetting('offsidebarPanelOpen'); }
    @HostBinding('class.offsidePanel-open') get offsidePanelOpen() { return this.settingsService.getLayoutSetting('offsidePanelOpen'); }
  
    @HostBinding('class.offsidePdfPanel-open') get offsidePdfPanelOpen() { return this.settingsService.getLayoutSetting('offsidePdfPanelOpen'); }
    @HostBinding('class.offsidePdfPanel-left') get offsidePdfPanelLeft() { return this.settingsService.getLayoutSetting('offsidePdfPanelLeft'); }
    @HostBinding('class.offsidePdfPanel-further-left') get offsidePdfPanelFurtherLeft() { return this.settingsService.getLayoutSetting('offsidePdfPanelFurtherLeft'); }
    @HostBinding('class.offsidePdfPanel-w1000') get offsidePdfPanelCenter() { return this.settingsService.getLayoutSetting('offsidePdfPanelCenter'); }
    
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settingsService.getLayoutSetting('asideToggled'); }
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settingsService.getLayoutSetting('isCollapsedText'); }

    isLoading: any = false;

    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private settingsService: SettingsService,

        //@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
        //private broadcastService: MsalBroadcastService, 
        //private authService: MsalService
    ) { }

    ngOnInit() {
        const self = this;
        // prevent empty links to reload the page
        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;
            if (target.tagName === 'A' && ['', '#'].indexOf(target.getAttribute('href')) > -1)
                e.preventDefault();
        });

        // this.broadcastService.inProgress$
        // .pipe(
        //     filter((status: InteractionStatus) => status === InteractionStatus.None),
        //     takeUntil(this._destroying$)
        // )
        // .subscribe(() => {
        //     this.setLoginDisplay();
        // })

        this.router.events.subscribe(event => {
            if (event instanceof GuardsCheckStart) {
                self.isLoading = true;
            }     
            if (event instanceof GuardsCheckEnd) {
                self.isLoading = false;
            } 
        });

        this.router.events.pipe(  
            filter(event => event instanceof NavigationEnd),  
          ).subscribe(() => {  
                const rt: ActivatedRoute = this.getChild(this.activatedRoute);  
                rt.data.subscribe(data => {  
                //  console.log('router data', data);  
                // console.log('pageRequiredRoles', data.roles);  
                // console.log('pageRequiredModules', data.modules);  
                //this.titleService.setTitle(data.title)
                
                // TODO couldn't get title or path to pass through
                this.settingsService.setAppSetting('pageRequiredRoles', data.roles);
                this.settingsService.setAppSetting('pageRequiredModules', data.modules);	
            });  
          });  
    }

    getChild(activatedRoute: ActivatedRoute) {  
        if (activatedRoute.firstChild) {  
            return this.getChild(activatedRoute.firstChild);  
        } else {  
            return activatedRoute;  
        }
    } 

    setLoginDisplay() {
        //this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
