<div class="topnav">
    <div class="container-fluid">
        <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

            <div class="collapse navbar-collapse" id="topnav-menu-content">
                <ul class="navbar-nav d-flex flex-column flex-lg-row gap-2">

                    <li class="nav-item dropdown">
                        <a class="nav-link arrow-none" routerLink="/" id="topnav-apps" role="button"
                             aria-haspopup="true" aria-expanded="false">
                            <i class="ri-apps-2-line me-1"></i>
                            <em class="fa fa-house"></em>
                        </a>
                    </li>

                    <li class="nav-item dropdown" *ngFor='let item of menuItems'>
                        <a *ngIf="item?.heading" class="nav-link arrow-none" href="#" id="topnav-dashboard" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="ri-dashboard-line"></i>
                                <span *ngIf="item.text != 'Settings' && item.text != 'Files' ">
                                    {{(item.translate | translate) || item.text}}
                                </span>
                                <span *ngIf="item.text == 'Settings'">
                                    <em class="fa fa-cog text-muted"></em>
                                </span>
                                <span *ngIf="item.text == 'Files'">
                                    <!-- <em class="fa fa-file text-muted"></em> -->
                                    Files
                                    
                                </span>
                            <div *ngIf="item.text != 'Settings' && item.text != 'Files'" class="arrow-down"></div>
                        </a>

                        <div *ngIf="item.text != 'Files'" class="dropdown-menu" title="{{item?.text}}" aria-labelledby="topnav-dashboard">
                            <!-- <a href="/{{item.link}}" class="dropdown-item">{{(item.translate | translate) || item.text}}</a> -->
                            <ng-container  *ngFor="let menuItem of item.items">

                                <a *ngIf="menuItem.link && !menuItem.breakLine && !menuItem.submenu" [routerLink]="['/'+menuItem.link]" class="dropdown-item">{{menuItem.text}}</a>
                                <ng-container *ngIf="menuItem.breakLine"><hr></ng-container>

                                <!-- submenu -->
                                <div *ngIf="menuItem.submenu" class="dropdown">
                                    <a class="dropdown-item arrow-none" href="#" id="topnav-email"
                                        role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="ri-dashboard-line align-middle me-1"></i>{{(menuItem.translate | translate) || menuItem.text}}<div class="arrow-down"></div>
                                    </a>
                                   
                                    <div class="dropdown-menu" aria-labelledby="topnav-email">
                                        <ng-container *ngFor='let subItem of menuItem.submenu'>
                                            <!-- <em class="{{subItem.icon}}" *ngIf="subItem.icon"></em> -->
                                            <a *ngIf="subItem.link && !subItem.breakLine" [routerLink]="['/'+subItem.link]" class="dropdown-item">{{subItem.text}}</a>
                                            <ng-container *ngIf="subItem.breakLine"><hr></ng-container>
                                        </ng-container>
                                    </div>
                                </div>

                            </ng-container>        
                        </div>      
                        
                        <div *ngIf="item.text == 'Files'" class="menu-content dropdown-menu pt-0 pb-0" title="{{item?.text}}" aria-labelledby="topnav-dashboard">
                            <ng-container *ngTemplateOutlet="menuFiles"></ng-container>
                        </div>
                    </li>
                   
                </ul> <!-- end navbar-->

                
                <div class="d-flex flex-column ms-auto flex-lg-row gap-2" >
                    <ng-container *ngFor='let item of actionMenuItems'>
                        <a *ngIf="item && item.link !== null" class="btn btn-sm btn-light-primary fw-bolder" (click)="gotoActionMenuItem(item)"  href="#">{{item.title}}</a>                       
                    </ng-container>


                                   

                </div>

                <div class="ms-auto me-3">
                    <img id="imageHeaderOrgTop">
                </div>

                <!-- test of action btn obs into header from pages-->
                <!-- <ul class="ms-auto navbar-action">
                    <li class="nav-item btn btn-primary btn-xs">
                        Clients
                    </li>
                </ul> -->
            </div> <!-- end .collapsed-->
        </nav>
    </div> <!-- end container-fluid -->
</div> <!-- end topnav-->



<ng-template #menuFiles>   
    <div class="d-flex p-0 m-0">
        <div class="col-md-8 left px-4 pt-4 pb-3">

            <a (click)="Goto('files')" class="row mb-3" id="f1">
                <!-- Left column with icon -->
                <div class="col-md-1 ps-1">                                                
                    <div class="fa-2x fa-regular fa-calendar icon-color"></div>                                              
                </div>
                <!-- Right column with two rows of text -->
                <div class="col-md-11 ps-3">
                    <div class="lead">Document Center</div>
                    <div class="small text-muted">All documents related to clients, jobs, invoices and organisation details can be uploaded here.</div>
                </div>                                           
            </a>
            
            <a (click)="Goto('settings/company-docs')" class="row mb-3" id="f2">
                <!-- Left column with icon -->
                <div class="col-md-1 ps-1">
                    <div class="fa-2x fa-regular fa-folder-open icon-color"></div>                                                
                </div>
                <!-- Right column with two rows of text -->
                <div class="col-md-11 ps-3">
                    <div class="lead">Company Docs</div>
                    <div class="small text-muted">Documents only related to the organisation governance and operation</div>
                </div>
            </a>

            <a (click)="Goto('files/remittances')" class="row mb-3" id="f3">
                <!-- Left column with icon -->                                          
                <div class="col-md-1 ps-1">
                    <div class="fa-2x fa-solid fa-file-invoice-dollar icon-color"></div>                                               
                </div>
                <!-- Right column with two rows of text -->
                <div class="col-md-11 ps-3">
                    <div class="lead">Remittances</div>
                    <div class="small text-muted">Find and record remittance files for invoices</div>
                </div>
            </a>
            <!-- Other menu items -->
        </div>

        <div class="col-md-4 right pt-2 px-3">
            <div class="small">
                <p class="text-uppercase text-muted mt-2 mb-2 small">Create Resource</p>
            </div>
            <a (click)="Goto('workflows/checklist')" class="mb-2">
                <div class="icon briefcase"></div>
                <span class="right-title">Checklists</span>
            </a>
            <a (click)="Goto('workflows/todo')" class="mb-2">
                <div class="icon draw-circle"></div>
                <span class="right-title">Tasks</span>
            </a>
            <!-- Other menu items -->
            <div class="small">
                <p class="text-uppercase text-muted mt-2 mb-2 small">NDIS Materials</p>
            </div>
            <a (click)="Goto('price-guides')" class="mb-2">
                <div class="icon briefcase"></div>
                <span class="right-title">Price Guides</span>
            </a>
            <a (click)="Goto('files/banning-orders')" class="mb-2">
                <div class="icon draw-circle"></div>
                <span class="right-title">Banning Orders</span>
            </a>
        </div>
    </div>

    <div class="bg-light p-3 border-top"><a href="/tasks" class="right-title">You can assign tasks to other staff members if required</a></div>                    
</ng-template>   