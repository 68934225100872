import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { OffsidebarComponent } from './offsidePanels/offsideSettingsBar/offsidebar.component';
import { OffsideMessagePanelComponent } from './offsidePanels/offsideMessagePanel/offside-message-panel.component';
import { OffsideClientPanelComponent } from './offsidePanels/offside-client-panel/offside-client-panel.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

//import { SharedModule } from '../shared/shared.module';
import { ModalContainerComponent } from '@components/modal/modal-container/modal-container.component';
import { OffsidePdfPanelComponent } from './offsidePanels/offsidePdfPanel/offside-pdf-panel.component';
import { OffsidePanelComponent } from './offsidePanels/offside-panel/offside-panel.component'
import { HorizontalComponent } from './menuHorizontal/horizontal/horizontal.component';
import { TodoComponent } from '@components/todo/todo.component';
import { RostersMiniComponent } from '@components/rosters-mini/rosters-mini.component';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
    imports: [
       // SharedModule,
        BsDropdownModule,
        ModalContainerComponent,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        CommonModule,
        TabsModule,
        PdfJsViewerModule,
        TodoComponent,
        RostersMiniComponent
    ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        OffsideMessagePanelComponent,
        OffsideClientPanelComponent,
        FooterComponent,
        OffsidePdfPanelComponent,
        OffsidePanelComponent,
        HorizontalComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        OffsideMessagePanelComponent,
        OffsideClientPanelComponent,
        FooterComponent
    ]
})
export class LayoutModule { }
