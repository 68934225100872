import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegexConstants, RegexValidator } from '@shared/validators/regex.validator';
import { ToastrService } from 'ngx-toastr';
import { OutletService } from '@core/services/outlet.service';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-outlet-component',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
		FormsModule,
		ReactiveFormsModule,
  ]
})
export class OutletComponent implements OnInit {
  outletForm: FormGroup;
  initialFormValue: any;
  @Input() isNew = null;
  @Input() container = null;
  @Input() callback = null;
  @Input() model = null
  @Input() clientId = null;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private outletService: OutletService
  ) { }

  ngOnInit(): void {
    this.initForms();
  }

  initForms() {
    this.outletForm = this.formBuilder.group({
      id: null,
      name: ['', Validators.required],
      address: [''],
      phone: [''],
      postcode: ['', [RegexValidator(RegexConstants.AUSTRALIAN_ZIPCODE)]],
      state: [''],
      suburb: [''],
      isActive: [true],
      clientId: this.clientId
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue) {
      const val = changes.model.currentValue;
      this.outletForm.patchValue({
        id: val.id,
        name: val.name,
        address: val.address,
        phone: val.phone,
        postcode: val.postcode,
        state: val.state,
        suburb: val.suburb,
        isActive: val.isActive,
        clientId: val.clientId
      });
      this.initialFormValue = this.outletForm.value;
    } else if (changes.model) {
      this.initForms();
    }
  }

  async saveOutlet() {
    const ot = this.outletForm.value;
    const outlet = {
      id: ot.id,
      name: ot.name,
      address: ot.address,
      phone: ot.phone,
      postcode: ot.postcode,
      state: ot.state,
      suburb: ot.suburb,
      isActive: ot.isActive,
      clientId: ot.clientId || this.clientId
    }

    let action = this.isNew ? this.outletService.createOutlet(outlet) : this.outletService.patchOutlet(outlet);
    action.then(() => {
      this.container.hide();
      this.toastrService.success('Success', 'Saved Outlet');
      this.outletForm.reset();
      this.outletForm.controls['isActive'].setValue(true);
    }).catch(err => {
      if (err) { this.toastrService.error('Error', err.message); }
    }).finally(() => {
      if (this.callback) {
        this.callback();
      }
    });
  }

  closeModal() {
    if (this.isNew) { this.outletForm.reset(); }
    else {
      this.outletForm.patchValue(this.initialFormValue);
    }
    this.container.hide();
  }
}
