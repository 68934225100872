import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { OrganisationService } from '@core/services/organisation.service';
import { RoleService } from '@core/services/role.service';
import { filter, find, includes, map, some } from 'lodash-es';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { FacebookLoginProvider, GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../core/settings/settings.service';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { UserProfileService } from '@core/services/user-profile.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2'
import dayjs from 'dayjs';

@Component({
    selector: 'trial',
    templateUrl: './trial.component.html',
    styleUrls: ['./trial.component.scss']
})
export class TrialComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    valForm: FormGroup;
    passwordForm: FormGroup;

    organisationName: any;
    emailAddress: any;
    startDate: any;
    allModules: any = [];
    moduleIcons = [
        { code: 'DS', icon: 'fa-clipboard-user' },
        { code: 'NDISP', icon: 'fa-file-csv' },
        { code: 'P', icon: 'fa-sack-dollar' },
        { code: 'CS', icon: 'fa-sitemap' },
        { code: 'PM', icon: 'fa-list-check' },
        { code: 'NDISAPI', icon: 'fa-circle-nodes' },
        { code: 'XERO', icon: 'fa-file-invoice-dollar' },
        { code: 'PHONE', icon: 'fa-phone-flip' },
        { code: 'ALPHA', icon: 'fa-flask-vial' },
        { code: 'INV', icon: 'fa-file-invoice-dollar' },
        { code: 'LOAN', icon: 'fa-file-invoice-dollar' },
    ]

    constructor(private roleService: RoleService,
        private authService: AuthService,
        private fb: FormBuilder,
        private socialAuthService: SocialAuthService,
        private toastrService: ToastrService,
        private userProfileService: UserProfileService,
        private organisationService: OrganisationService,
        private utilityService: UtilityService,
        public router: Router,
        public settings: SettingsService
    ) {
        this.router = router;
    }

    ngOnInit() {
        this.initForms();
    }

    initForms() {
        this.passwordForm = this.fb.group({
            'password': [null, [Validators.required, Validators.pattern('(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$')]],
            'password2': [null, [Validators.required]]
        }, { validators: passwordMatchValidator });

        this.valForm = this.fb.group({
            'email': [null, [Validators.required, Validators.email]],
            'accountagreed': [null, Validators.required],
            'passwordGroup': this.passwordForm,
            'organisationName': [null, Validators.required],
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();

        var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
        if (form.checkValidity() === false) {
            $ev.preventDefault();
            $ev.stopPropagation();
        }
        form.classList.add('was-validated');

        if (this.valForm.value?.passwordGroup?.password !== this.valForm.value?.passwordGroup.password2) {
            this.toastrService.warning('Passwords do not match');
            return;
        }

        if (this.valForm.valid) {
            this.createTrial(value);
        }
    }

    isValid() {
      return !!this.valForm.valid;
      return this.organisationName &&
          this.emailAddress &&
          this.startDate &&
          this.utilityService.isEmailValid(this.emailAddress) &&
          some(this.allModules, m => m.hasModule);
    }

    async getModules() {
        const modules: any = await this.roleService.getAllModules();
        map(modules, m => {
            m.hasIcon = find(this.moduleIcons, i => i.code === m.code);
        })

        this.allModules = filter(modules, m => m.isActive);
    }

    async createTrial(userData: any) {
      const createdUser: any = await this.createUserCarestart(userData);
      if (createdUser) {
        const createdOrg: any = await this.createOrganisation();
        if (createdOrg && createdOrg.id) {
          this.setUserRoles(createdUser, createdOrg.id);
          this.setTrialModules();
        }
      }
    }

    async setTrialModules() {
      await this.organisationService.addModuleToOrg('a3bc0052-2838-4e4b-0425-08d8f27ff40a'); //PM
      await this.organisationService.addModuleToOrg('cd7cb0de-c0ca-43bb-b707-34a006711d29'); //XERO
      await this.organisationService.addModuleToOrg('3f4d2610-de40-4f2f-ac98-670d701802d5'); // PHONE
      await this.organisationService.addModuleToOrg('2a2dd6e4-5aec-4d43-8fc5-c84f194709d4'); //NDISAPI
    }

    async createUserCarestart(credentials: any) {
      this.blockUI.start();
      const newUser = {
          email: credentials.email,
          firstName: '',
          lastName: '',
          password: credentials.passwordGroup.password
      }

      const createdUser = await this.authService.createUserWithEmail(newUser)
      .catch(e => {
        this.blockUI.stop();
        // 500 would be an error returned
        Swal.fire('Error', 'There was a problem in registering', 'error');
      })

      return createdUser;
      this.blockUI.stop();
      // check if we have id
      // redirect to login or home ?
      Swal.fire('Success', 'You have been registered', 'success');
      this.router.navigate([`login`]);
    }

    signInWithGoogle(): void {
        const self = this;
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(googleUser => {
            this.blockUI.start();
            this.authService.googleAuthenticate(googleUser).then((data: any) => {
                console.log('returnd ata from googleAuth', data);
                this.authService.setAuth(data);
                this.userProfileService.getUserProfile().then(res => {
                    //this.userProfile = res;
                    //this.profileService.userProfile = res;
                    this.blockUI.stop();
                    //this.menuService.setMenu();
                    self.router.navigate(['/home']);
                },
                    err => {
                        this.authService.logout();
                        this.blockUI.stop();
                        console.error('getUserProfile error', err);
                        Swal.fire({
                            title: 'Not able to login',
                            //text: error.message,
                            icon: 'warning',
                            confirmButtonColor: '#DD6B55',
                        });
                    });
            });
        });
    }

    async createOrganisation() {
        const newOrg = {
            name: this.valForm.value.organisationName,
            isActive: true,
            isTrial: true,
            trialStartDate: dayjs().format('DD/MM/YYYY')
        }

        const createdOrg = await this.organisationService.createOrganisation(newOrg)
            .catch(() => {
                this.toastrService.error('Error', 'An Error occured while tring to create new organisation');
            })

        return createdOrg;
    }

    async setUserRoles(userId, orgId) {
      var sAccess = {
          userId: userId,
          organisationId: orgId,
          roleId: 'cf1ae93f-8b0c-420d-067c-08d8f1faff58' //Admin role
      };

      await this.roleService.createAccessRoleForUser(sAccess);
    }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
    if (formGroup.get('password').value === formGroup.get('password2').value)
        return null;
    else
        return { passwordMismatch: true };
};
