const Home = { text: 'Home', link: '/home', icon: 'icon-home', roles: ['all'], modules: ['all'] };
const Profile = { text: 'Profile', link: '/staff/profile', icon: 'icon-user', roles: ['all'], modules: ['all'] };
const FilesUpload = { text: 'Document Center', link: '/files', icon: 'fas fa-upload', roles: ['all'], modules: ['all'] };
const Clients: any = { text: 'Clients', link: '/clients', icon: 'icon-people', roles: ['all'], modules: ['all'] };
const ContactsOther: any = { text: 'External', link: '/contacts', icon: 'icon-people', roles: ['all'], modules: ['all'] };

const Carers = { text: 'Staff', link: '/staff', icon: 'icon-emotsmile', roles: ['all'], modules: ['all'] };
const Users = { text: 'Login Access', link: '/users', icon: 'icon-people', roles:['admin'], modules: ['all'] };
const Rosters = { text: 'Generate Invoices', link: '/rosters', icon: 'icon-calendar', roles: ['all'], modules: ['DS'] };
const Jobs = { text: 'Jobs', link: '/rosters/manage', icon: 'icon-calendar', roles: ['all'], modules: ['DS'] };
const RosterMaps = { text: 'Map', link: '/rosters/map', icon: 'icon-calendar', roles: ['all'], modules: ['DS'] };
const Quotes = { text: 'Quotes', link: '/activity/quotes', icon: 'icon-magic-wand' };
const NDISRevenueGraphs = { text: 'Revenue Graphs', link: '/business/ndis-revenue-graphs', icon: 'icon-graph' };
const CSVExports = { text: 'CSV Export', link: '/ndis-portal/csv-export', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['PM', 'DS', 'COS'] };
const PMMonthlyFees = { text: 'Monthly Fees', link: '/ndis-portal/pm-fees', icon: 'icon-credit-card', roles: ['all'], modules: ['PM']  };
const PMEstFees = { text: 'Establishment Fees', link: '/ndis-portal/pm-establishment-fees', icon: 'icon-credit-card', roles: ['PM-DE', 'admin'], modules: ['PM']  };
const ClientStatements = { text: 'Monthly Client Statements', link: '/clients/statements', icon: 'icon-credit-card', roles: ['PM-DE', 'admin'], modules: ['PM']  };

const Loans = { text: 'Loans', link: '/loans', icon: 'icon-credit-card', roles: ['admin'], modules: ['XERO']  };

const Remittances = { text: 'Remittances', link: '/files/remittances', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['DS'] };
const Timesheets = { text: 'Timesheets', link: '/payroll/timesheets', icon: 'icon-arrow-right-circle' };
const PMPaymentRequests = { text: 'PayReq (NDIS)', link: '/ndis-portal/payment-requests', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['DS', 'PM', 'COS'] };

//const XeroSummary = { text: 'Summary', link: '/xero', icon: 'icon-arrow-right-circle', roles:['admin'], modules: ['XERO'] };
const XeroInvoices = { text: 'Xero Invoices', link: '/xero/invoices', icon: 'icon-arrow-right-circle', roles:['admin'], modules: ['XERO'] };
const XeroBillingLink = { text: 'Billable Expense', link: '/xero/reconcile', icon: 'icon-arrow-right-circle', roles:['admin'], modules: ['XERO'] };

const WeeklyBilling = { text: 'Weekly Billing', link: '/reports/weekly-billing', icon: 'icon-arrow-right-circle', roles:['admin'], modules: ['DS'] };
const WeeklyHours = { text: 'Weekly Hours', link: '/reports/weekly-hours', icon: 'icon-arrow-right-circle', roles:['admin'], modules: ['DS'] };
const PhoneReports = { text: 'Phone Records', link: '/reports/phones', icon: 'icon-arrow-right-circle', roles:['all'], modules: ['all'] };
const Incidents = { text: 'Incidents', link: '/reports/incidents', roles:['admin'], modules: ['DS'] };
const ShiftReports = { text: 'Shift Reports', link: '/reports/shift', roles:['admin'], modules: ['DS'] };
const Hazards =  { text: 'Hazards', link: '/reports/hazards', roles:['admin'], modules: ['DS'] };
const MessageReports = { text: 'SMS Messages', link: '/reports/messages', icon: 'icon-arrow-right-circle', roles:['admin'], modules: ['DS'] };
const NotesReports = { text: 'Notes', link: '/reports/notes', icon: 'icon-arrow-right-circle', roles:['admin'] };
const ProfitLossReports = { text: 'Financials', link: '/reports/profitLoss', icon: 'icon-arrow-right-circle', roles:['admin'] };
const PMBillingMonthly = { text: 'PM Monthly Report', link: '/reports/pm-billing-monthly', icon: 'icon-people', roles: ['PM-DE', 'PM', 'admin'], modules: ['PM'] };
const PMStatementsApprove = { text: 'Statements Approve', link: '/statements/approve', icon: 'icon-arrow-right-circle', roles:['all'], modules: ['PM'] };
const ClientPlanManagersReports = { text: 'Client PMs', link: '/reports/client-planmanagers', icon: 'icon-arrow-right-circle', roles:['admin'] };

const ClientPlans = { text: 'Client Plans', link: '/reports/clientPlans', icon: 'icon-arrow-right-circle', roles:['all'] };

const ServiceAgreements = { text: 'Service Agreements', link: '/service-agreements', icon: 'icon-arrow-right-circle', roles:['admin', 'PM', 'PM-DE'], modules: ['DS', 'PM', 'COS'] };
const NDISPlans = { text: 'NDIS Plans', link: '/clients/plans', icon: 'icon-arrow-right-circle', roles:['admin', 'OM', 'PM', 'PM-DE'], modules: ['DS', 'PM', 'COS'] };

const PMBillstoPay = { text: 'Provider Invoices', link: '/plan-manage/invoices', icon: 'icon-arrow-right-circle', roles: ['PM-DE', 'PM', 'admin'], modules: ['PM'] };
const PMProviders = { text: 'Providers', link: '/providers', icon: 'icon-arrow-right-circle', roles:['all'], modules: ['DS', 'PM', 'COS'] };
const PMPlanManagers = { text: 'Plan Managers', link: '/providers/planmanagers', icon: 'icon-arrow-right-circle', roles:['all'], modules: ['DS', 'PM', 'COS'] };
const PMNotes = { text: 'Notes', link: '/rosters/manage', icon: 'icon-arrow-right-circle', roles:['all'], modules: ['PM'] };

const PMPriceGuides = { text: 'Price Guides', link: '/price-guides', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['all'] };
const COSBilling = { text: 'Billing Hours', link: '/cos/billing', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['COS'] };
const COSSummary = { text: 'Summary', link: '/cos/summary', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['COS'] };
const COSManager = { text: 'Manager Allocate', link: '/cos/manager', icon: 'icon-arrow-right-circle', roles: ['admin'], modules: ['COS'] };
const COSRecent = { text: 'Recent Communication', link: '/cos/recent', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['COS'] };
const COSManagerSummary = { text: 'Manager Summary', link: '/cos/manager-summary', icon: 'icon-arrow-right-circle', roles: ['admin'], modules: ['COS'] };
const COSClients = { text: 'Clients Summary', link: '/cos/clients', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['COS'] };

const BanningOrders = { text: 'Banning Orders', link: '/files/banning-orders', icon: 'icon-arrow-right-circle', roles: ['all'], modules: ['all'] };

const ConveyorBelt = { text: 'Conveyor Belt', link: '/activity/conveyor-belt', icon: 'icon-arrow-right' };
const Account = { text: 'Account', link: '/settings/account', icon: 'icon-people', roles: ['all'], modules: ['all'] };
const Organisation = { text: 'Organisation', link: '/settings/organisation', icon: 'icon-hourglass', roles: ['all'], modules: ['all'] };
const CreateOrganisation = { text: 'Create Organisation', link: '/settings/account', icon: 'icon-hourglass', roles: ['all'], modules: ['all'] };

const XeroSettings = { text: 'Xero', link: '/settings/xero', icon: 'icon-hourglass', roles: ['all'], modules: ['all'] };
const NDISSettings = { text: 'NDIS', link: '/settings/ndis', icon: 'icon-hourglass', roles: ['all'], modules: ['all'] };

const Types = { text: 'Types', link: '/settings/types', icon: 'icon-hourglass', roles: ['all'], modules: ['all'] };
const Templates = { text: 'Templates', link: '/settings/template', icon: 'icon-hourglass', roles: ['all'], modules: ['all'] };
const CompanyDocs = { text: 'Company Docs', link: '/settings/company-docs', icon: 'far fa-file', roles: ['all'], modules: ['all']}
const Import = { text: 'Import', link: '/settings/import', icon: 'fas fa-cloud-upload-alt', roles: ['all'], modules: ['all']}
const Payroll = { text: 'Payroll', link: '/payroll', icon: 'icon-arrow-right-circle', roles: ['admin'], modules: ['P'] };
const PLSLeave = { text: 'PLS Leave', link: '/payroll/pls-leave', icon: 'icon-arrow-right-circle', roles: ['admin'], modules: ['P'] };
const Workflows = { text: 'Workflows', link: '/workflows', icon: 'icon-arrow-right-circle', roles:['admin'], modules: ['ALPHA'] };
const FinanceAccounts = { text: 'Finance Accounts', link: '/settings/finance-accounts', icon: 'icon-hourglass', roles: ['admin'], modules: ['all'] };
const Regions = { text: 'Regions', link: '/settings/regions', icon: 'icon-hourglass', roles: ['all'], modules: ['all'] };

const Departments = { text: 'Departments', link: '/departments', icon: 'icon-map' };
const SILs = { text: 'SILs Calc', link: '/business/sil', icon: 'icon-flag', roles:['admin'], modules: ['DS'] };
const Sils = { text: 'Sil Houses', link: '/sils', icon: 'icon-notebook', roles:['admin'], modules: ['DS'] };
const GroupActivity = { text: 'Group Activity', link: '/group-activity', icon: 'icon-notebook', roles:['admin'], modules: ['DS'] };
const Outlets = { text: 'Outlets', link: '/outlets', icon: 'icon-notebook', roles:['admin'], modules: ['DS'] };
const Invoices = { text: 'Invoices', link: '/invoices', icon: 'icon-credit-card', roles:['all'], modules: ['DS', 'PM', 'INV'] };
const BillsToPay = { text: 'Bills To Pay', link: '/invoices/billstopay', icon: 'icon-credit-card', roles: ['all'], modules: ['PM'] };
const DPNInvoices = { text: 'Invoices for DPN', link: '/invoices/dpn', icon: 'icon-credit-card', roles:['all'], modules: ['DS'] };

const Referrals = { text: 'Referrals', link: '/referrals', icon: 'icon-book-open' };
const KmsTraveled = { text: 'Kms Traveled', link: '/activity/kms', icon: 'icon-directions' };
const Feedback = { text: 'Feedback', link: '/feedback', icon: 'icon-people', roles:['admin'], modules: ['ALPHA'] };
const Competency = { text: 'Competency', link: '/staff/competency', icon: 'icon-grid', roles: ['admin'], modules: ['DS'] };
const Teams = { text: 'Teams', link: '/staff/team', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['all'] };
const Awards = { text: 'Awards', link: '/staff/awards', icon: 'icon-grid', roles: ['admin'], modules: ['DS'] };
const Network = { text: 'Network', link: '/network/providers', icon: 'icon-credit-card', roles:['all'], modules: ['ALPHA'] };
const CI = { text: 'Improvements', link: '/audit/improvement', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const Courses = { text: 'Courses', link: '/courses', icon: 'icon-grid', roles: ['all'], modules:['COURSE'] };
const CoursesAdmin = { text: 'Courses Admin', link: '/courses/admin', icon: 'icon-grid', roles: ['admin', 'OM'], modules:['COURSE'] };
const CoursesProgress = { text: 'Courses Summary', link: '/courses/summary', icon: 'icon-grid', roles: ['admin', 'OM'], modules:['COURSE'], };
const Enrollment = { text: 'Enrollment', link: '/courses/enrollment', icon: 'icon-grid', roles: ['admin', 'OM'], modules:['COURSE'] };

const RiskRegister = { text: 'Risk Register', link: '/audit/risk', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const TrainingRegister = { text: 'Training Register', link: '/audit/training', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const IncidentRegister = { text: 'Incident Register', link: '/audit/incident', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const FeedbackRegister = { text: 'Feedback Register', link: '/audit/feedback', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const HazardRegister = { text: 'Hazard Register', link: '/audit/hazard', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const InterestRegister = { text: 'Interest Conflict Register', link: '/audit/interest', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const CommunityEngagementRegister = { text: 'Community Engagement Register', link: '/audit/community-engagement', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };

const Policies = { text: 'Policies', link: '/audit/policies', icon: 'icon-grid', roles: ['admin', 'OM'], modules: ['COMP'] };
const Checklists = { text: 'Checklists', link: '/workflows/checklist', icon: 'icon-grid', roles: ['admin', 'OM'], };
const Todo = { text: 'Tasks', link: '/workflows/todo', icon: 'icon-grid', roles: ['all'] };

const Handbook = { text: 'Handbook', link: '/audit/handbook', icon: 'icon-grid', roles: ['all'], modules: ['COMP'] };
const COSManagers = { text: 'COS Managers', link: '/cosmanager', icon: 'icon-grid', roles:['all'], modules: ['DS', 'PM'] };


const Reports = { text: 'Reports', link: '/reports', icon: 'icon-layers', roles:['admin'], modules: ['DS'],
    submenu: [
        { text: 'Leave Request', link: '/reports/leave-request', roles:['admin'], modules: ['DS'] },
        { text: 'Incidents', link: '/reports/incidents', roles:['admin'], modules: ['DS'] },
        { text: 'Hazards', link: '/reports/hazards', roles:['admin'], modules: ['DS'] },
        { text: 'Shifts', link: '/reports/shift', roles:['admin'], modules: ['DS'] },
        { text: 'Clients Rosters',  link: '/reports/clients-rosters', roles:['admin'], modules: ['DS'] },
        { text: 'Referrals',  link: '/reports/referrals', roles:['admin'], modules: ['DS'] }
    ]
};

const subMenuXero =  { text: 'Xero', link: '/xero', icon: 'icon-cup', roles:['all'], modules: ['XERO'],
        submenu: [    
           // XeroSummary,
            XeroInvoices,
            XeroBillingLink,
        ]
}

const subMenuReports =  { text: 'Reports', link: '/reports', icon: 'icon-cup', roles:['admin'], modules: ['DS'],
        submenu: [    
            WeeklyBilling,
            //PhoneReports,
            Incidents,
        ]
}

const planManagementMenu =  { text: 'Plan Management', link: '/', icon: 'icon-doc', roles: ['all'], modules: ['PM'],
        submenu: [    
            PMBillstoPay,
            PMMonthlyFees,
            PMBillingMonthly,
        ]
}

const invoicesMenu =  { text: 'Invoices', link: '/', icon: 'icon-graph', modules: ['DS', 'PM', 'COS'],
        submenu: [    
            Invoices,
            BillsToPay,
            Rosters
        ]
}

const companiesMenu =  { text: 'Services', link: '/', icon: 'icon-grid', modules: ['DS', 'PM', 'COS'],
        submenu: [    
            PMProviders,
            COSManagers,
        ]
}

const auditMenu =  { text: 'Audit', link: '/', icon: 'icon-grid', modules: ['ALPHA'],
        submenu: [    
            CI,
        ]
}

const headingGeneral = { text: 'Contacts', heading: true, roles: ['all'], modules: ['all'] };

const headingPlanManagement = { text: 'Plan Management', heading: true, roles:['all'], modules: ['PM'] };

const headingCOS = { text: 'COS', heading: true, roles: ['all'], modules: ['COS'] };

const headingBillingNDIS = { text: 'Billing', heading: true, roles: ['all'], modules: ['DS', 'PM', 'COS'] };

const headingOther = { text: 'Settings', heading: true, roles: ['all'], modules: ['all'] };

const headingTraining = { text: 'Courseware', heading: true, roles: ['all'], modules: ['COURSE'] };

const headingCompliance = { text: 'Compliance', heading: true, roles: ['all'], modules: ['COMP'] };

const headingRosters = { text: 'Rosters', heading: true, roles: ['all'], modules: ['DS'] };

const headingFiles = { text: 'Files', heading: true , roles: ['all'], modules: ['all']};

const headingReports = { text: 'Reports', heading: true , roles: ['all'], modules: ['all']};

const breakLine = { text: 'breakLine', breakLine: true, link: '/rosters', icon: 'icon-calendar', roles: ['all'], modules: ['all'] };

export const menu = [
    
    Home,
    //ChangePassword,
    headingGeneral, //HEADING
    //Profile,
    Clients,
    Carers,
    ContactsOther,
    breakLine,
    PMProviders,
    PMPlanManagers,
    COSManagers,
    breakLine,
    PhoneReports,
   // companiesMenu,

    headingRosters,
    Jobs,
    RosterMaps,
    breakLine,
    Payroll,
    PLSLeave,
    breakLine,
    //SILs,
    Outlets,
    Sils,
    Teams,
    GroupActivity,

    headingPlanManagement,
    PMBillstoPay,
    PMNotes,
    breakLine,
    PMMonthlyFees,
    PMEstFees,
    breakLine,
    ClientStatements,
    PMStatementsApprove,

    headingCOS,
    //COSSummary,
    COSClients,
    breakLine,
    COSBilling,
    COSRecent,
    breakLine,
    COSManager,
    //COSManagerSummary,

    headingBillingNDIS,
    PMPaymentRequests,
    CSVExports,
    breakLine,
    Invoices,
    BillsToPay,
    DPNInvoices,
    breakLine,
    Rosters,
    WeeklyBilling,
    ServiceAgreements,
    NDISPlans,
    //Remittances,
    breakLine,
    subMenuXero, 

   headingReports,
   WeeklyBilling,
   WeeklyHours,
   ClientPlans,
   breakLine,
   ShiftReports,
   Incidents,
   breakLine,
   ClientPlanManagersReports,
   Competency,
   breakLine,
   MessageReports,
   NotesReports,
   ProfitLossReports,
   breakLine,
   PMBillingMonthly,
   //Loans,

   

    // PMPaymentRequests,
    // CSVExports,
    //PaymentsDetail,
    //PhoneCalls,

    auditMenu,
    
   

    headingCompliance,
    CI,
    Handbook,
    Policies,
    breakLine,
    RiskRegister,
    IncidentRegister,
    HazardRegister,
    breakLine,
    FeedbackRegister,
    InterestRegister,
    TrainingRegister,
    CommunityEngagementRegister,

    headingTraining,
    Courses,
    Enrollment,
    breakLine,
    CoursesAdmin,
    CoursesProgress,


    headingFiles,
    FilesUpload,
    CompanyDocs,
    breakLine,
    Checklists,
    Todo,
    breakLine,
    Remittances,
    PMPriceGuides,
    BanningOrders,

    headingOther,
    Account,
    breakLine,

    Organisation,
    CreateOrganisation,
    Users,
    breakLine,

    XeroSettings,
    NDISSettings,
   
   
    //Timesheets,
   
    breakLine,
    Awards,
    Types,
    Templates,
    Regions,
    FinanceAccounts,
    breakLine,
    Import,
    Network,
    Workflows,
   
];

