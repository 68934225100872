import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { JobService } from '@core/services/job.service';
import { BusinessService } from 'src/app/services/business.service';
import { UtilityService } from 'src/app/services/utility.service';
import { ClientService } from '@core/services/client/client.service';

import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import week from 'dayjs/plugin/weekOfYear';

import cloneDeep from 'lodash-es/cloneDeep';
import each from 'lodash-es/each';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgIf } from '@angular/common';
import { WeekPickerComponent } from '@components/week-picker/week-picker-component';

dayjs.extend(dayOfYear);
dayjs.extend(week);

@Component({
    selector: 'clone-job-component',
    templateUrl: './clone-job.component.html',
    styleUrls: ['./clone-job.component.scss'],
    standalone: true,
    imports: [NgIf, BsDatepickerModule, FormsModule, ReactiveFormsModule, WeekPickerComponent]
})
export class CloneJobComponent implements OnInit {
  @Input() container = null;
  @Input() containerVisible = false;
  @Input() currentOrganisation: any;
  @Input() model: any = {};
  @Input() multiple: any = false;
  cloneStartDate: any;
  endDate: string;
  isCloneCharges: boolean = true;
  isCloneStaff: boolean = true;
  isCloneShadowShifts: boolean = false;
  isCloneKmCharges: boolean = false;
  startDate: string;
  timeReg: any = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  weekFilterForm: FormControl = new FormControl();
  weekStart: any;
  week: any;

  constructor(private toastrService: ToastrService,
    private jobService: JobService,
    private clientService: ClientService,
    private businessService: BusinessService,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    const now = dayjs();
    const month = now.month();
    const week = now.week();
    if (month === 11 && week === 1) {
      this.weekFilterForm = new FormControl({ year: now.year() + 1, week: now.week() });
    }
  }

  async process() {
    if (this.multiple) { //clone multiple jobs
      const s = this.model[0].schedules[0];
      let scheduleDate = dayjs(s.start.split('T')[0]);
      let scheduleDateYear = scheduleDate.year();
      let newScheduleDateYear = this.weekStart.getFullYear();

       let s0, plusD;
       if (this.currentOrganisation.weekStart === 'MON') {
          if (dayjs(s.start.split('T')[0]).day() === 0) { s0 = 7 } else { s0 = 0; }
          plusD = -1;
       }

       if (this.currentOrganisation.weekStart === 'SUN') {
          if (dayjs(s.start.split('T')[0]).day() === 0) { s0 = 0; }
          plusD = 0;
       }

      let scheduleDateStartWeekDay = dayjs(s.start.split('T')[0]).startOf('week').dayOfYear();
      let selectedWeekStartWeekDay = dayjs(this.weekStart).startOf('week').dayOfYear() + (s0 || 0);
      let weekCount = (selectedWeekStartWeekDay - scheduleDateStartWeekDay) / 7;

      if (scheduleDateYear > newScheduleDateYear) {
        this.toastrService.error('Error', 'Must Select Week in the future');
        return false;
      }

      if (weekCount < 1) {
        this.toastrService.error('Error', 'Must Select Week in the future');
        return false;
      }

      const prs: any = [];
      each(this.model, async cJob => {
        if (cJob.schedules.length) {
          each(cJob.schedules, s => {
            if (this.currentOrganisation.weekStart === 'MON') {
              if (dayjs(s.start.split('T')[0]).day() === 0) { s0 = 7 } else { s0 = 0; }
              plusD = -1;
            }

            if (this.currentOrganisation.weekStart === 'SUN') {
              if (dayjs(s.start.split('T')[0]).day() === 0) { s0 = 0; }
              plusD = 0;
            }

            scheduleDate = dayjs(s.start.split('T')[0]);
            scheduleDateYear = scheduleDate.year();
            newScheduleDateYear = this.weekStart.getFullYear();

            if (scheduleDateYear === newScheduleDateYear) {
              scheduleDateStartWeekDay = dayjs(s.start.split('T')[0]).startOf('week').dayOfYear();
              selectedWeekStartWeekDay = dayjs(this.weekStart).startOf('week').dayOfYear() + (s0 || 0);
              weekCount = (selectedWeekStartWeekDay - scheduleDateStartWeekDay) / 7;
              if (weekCount >= 1) {
                s.newStart = `${scheduleDate.add(weekCount, 'week').format('YYYY-MM-DD')}T${s.start.split('T')[1]}`;
                s.newEnd = `${scheduleDate.add(weekCount, 'week').format('YYYY-MM-DD')}T${s.end.split('T')[1]}`;
              }
            }

            if (scheduleDateYear < newScheduleDateYear) {
              const dayOfWeek = dayjs(s.start.split('T')[0]).day();
              s.newStart = `${dayjs(this.weekStart ).add(dayOfWeek + plusD,'day').format('YYYY-MM-DD')}T${s.start.split('T')[1]}`;
              s.newEnd = `${dayjs(this.weekStart).add(dayOfWeek + plusD, 'day').format('YYYY-MM-DD')}T${s.end.split('T')[1]}`;
            }
          });
        }
        prs.push(this.processJobClone(cJob));
      });

      Promise.allSettled(prs).then(() => {
        this.resetForm();
        this.container.hide();
      })
    } else {
      const jobToClone = this.model;
      jobToClone.newScheduleDate = this.cloneStartDate;
      const newStartDate = dayjs(this.cloneStartDate);

      each(jobToClone.schedules, s => {
        const scheduleStart = dayjs(s.start.split('T')[0]);
        const hours = newStartDate.diff(scheduleStart, 'hour');
        const diff = Math.floor(hours / 24);
        s.newStart = `${scheduleStart.add(diff, 'day').format('YYYY-MM-DD')}T${s.start.split('T')[1]}`;
        s.newEnd = `${scheduleStart.add(diff, 'day').format('YYYY-MM-DD')}T${s.end.split('T')[1]}`;
      });
      
      await this.processJobClone(jobToClone);
      this.resetForm();
      this.container.hide();
    }
  }

  async processJobClone(jobToClone: any) {
    const promise = new Promise<void>(async (resolve, reject) => {

      const jobObj = {
        name: jobToClone.name,
        clientId: jobToClone.client?.id,
        //providerId: jobToClone.providerId, //TODO: do we need this?
        isGroupActivity: jobToClone.isGroupActivity,
        jobClass: jobToClone.jobClass,
        notes: jobToClone.notes,
        status: 'scheduled',
        minClients: jobToClone.minClients,
        maxClients: jobToClone.maxClients,
        outletId: jobToClone.outlet?.id
      }

      try {
        const newJob: any = await this.jobService.createJob(jobObj);
        // save job and schedules after reorganising schedule date
        // map schedules and change date

        await each(jobToClone.schedules, async (schedule) => {
          if (this.isCloneShadowShifts || schedule.workType != 'SS') {
            schedule = cloneDeep(schedule);
            delete schedule.id;  // cant have an id yet as we are cloning
            if (!this.isCloneStaff) {
              delete schedule.employees;
              delete schedule.employeeIds;
            }
            schedule.jobId = newJob.id;
            schedule.jobTypeId = schedule.jobType.id;
            schedule.startDate = schedule.newStart;
            schedule.endDate = schedule.newEnd;
            await this.saveSchedule(schedule, newJob.id);
          }
        });

        if (this.isCloneCharges) {
          await each(jobToClone.charges, async (charge) => {
            if (this.isCloneKmCharges || charge.chartAccountCode != '207') {
              delete charge.id;
              charge.jobId = newJob.id;                      
              await this.saveCharge(charge, newJob.id, newJob.isGroupActivity);
            }
          })
        }

        // group activity
        if (jobToClone.isGroupActivity) { await this.saveGroupClientBookings(jobToClone, newJob.id); }
        this.toastrService.success('Success', 'Cloned Job(s)');
        this.container.hide();
        resolve();
      } catch (err) {
        console.error(err);
        reject(err);
      }
    })
    return promise;
  }

  async saveSchedule(item: any, jobId) {
    // save with TZ based on the State of ServiceAddress
    //const state = item.job?.serviceAddress?.servicePostcode;
    // let tz = this.businessService.getTimeZone(state); //TODO: remove at some point;

    const payload: any = {
      jobId: jobId,
      name: item.name,
      workType: item.workType,
      description: item.description,
      start: item.startDate,
      end: item.endDate,
      isKmsPayable: item.isKmsPayable,
      isChargeable: item.isChargeable,
      isSleepover: item.isSleepover,
      taskTypeId: item.taskTypeId || 122318, //WORK
      jobTypeId: item.jobTypeId,
      employees: item.employees,
      isNDISJob: item.isNDISJob,
      isMobile: item.isMobile,
      isTravelTimeChargeable: item.isTravelTimeChargeable,
      preTravelHours: item.preTravelHours,
      postTravelHours: item.postTravelHours,
      officeLeaveTime: item.officeLeaveTime,
      officeReturnTime: item.officeReturnTime,
      grouping: item.grouping
    };

    if (item.beforeEdit) { delete item.beforeEdit; }

    let action;
    if (item.id) {
      payload.id = item.id;
      action = this.jobService.updateJobSchedule(payload);
    } else {
      action = this.jobService.createJobSchedule(payload)
    }

    await action.catch((err) => {
      console.error(err); this.toastrService.error('Error', 'Something went wrong while trying to save Schedule')
    });
  }

  async saveCharge(item: any, jobId: any, isGroupActivity: any) {
    const payload: any = {
      jobId: jobId,
      itemId: 0,
      isFixedTotal: item.isFixedTotal,
      description: item.description,
      details: item.details,
      unit: item.unit,
      taxType: item.taxType,
      quantity: item.quantity,
      chartAccountCode: item.chartAccountCode,
      itemCode: item.itemCode,   // just get the code number
      itemCodeType: item.itemCodeType,
      total: item.total,
    };

    if (isGroupActivity) { payload.clientId = item.clientId; }
    if (item.beforeEdit) { delete item.beforeEdit; }

    let action;
    if (item.id) {
      payload.id = item.id;
      action = this.jobService.updateJobCharge(payload)
    } else {
      action = this.jobService.createJobCharge(payload)
    }

    await action.catch((err) => {
      console.error(err); this.toastrService.error('Error', 'Something went wrong while trying to save Charge')
    });

  }

  async saveGroupClientBookings(job: any, newJobId: any) {
    const prs: any = [];
    each(job.groupClientBookings, (booking: any) => {
      const saveObj: any = {
        clientId: booking.client.id,
        jobId: newJobId,
        isCancelled: false, //don't carry over cancelled status to cloned job
        isTransport: booking.isTransport || false,
        isBillable: booking.isBillable || false,
        isCopay: booking.isCopay || false,
        cancelledDate: booking.cancelledDate || null,
        clientRatio: booking.clientRatio || 0,
        grouping: booking.grouping,
        enrolledDate: dayjs().format('YYYY-MM-DD'),
      }

      prs.push(this.clientService.createGroupBooking(saveObj));
    });

    await Promise.all(prs).catch(() => this.toastrService.error('Error', 'Something went wrong while trying to save group activity clients. Please contact administrator'));
  }

  isFormValid() {
    if (this.multiple) { return !!this.weekStart; }
    else { return this.cloneStartDate && this.utilityService.isValidDate(this.cloneStartDate); }
  }

  resetForm() {
    this.weekFilterForm = new FormControl();
    this.cloneStartDate = this.isCloneCharges = this.isCloneStaff = null;
  }

  async weekSelected(weekStart: any) {
    if (dayjs(weekStart).format() !== 'Invalid Date') {
      this.week = this.weekFilterForm.value.week;
      this.weekStart = weekStart;
      this.startDate = dayjs(this.weekStart).format('YYYY-MM-DD');
      this.endDate = dayjs(this.weekStart).add(5, 'day').format('YYYY-MM-DD');
    }
  }

  async nextWeek() {
    this.weekStart = dayjs(this.weekStart).add(7, 'day');

    let week = this.weekStart.week();
    const month = this.weekStart.month();
    const year = this.weekStart.year();

    if (week === this.week) { week++ };
    if (month === 11 && week === 1) {
      this.weekFilterForm = new FormControl({ year: year + 1, week: week });
    } else {
      this.weekFilterForm = new FormControl({ year: year, week: week });
    }
  }

  async prevWeek() {
    this.weekStart = dayjs(this.weekStart).add(-7, 'day');

    let week = this.weekStart.week();
    const month = this.weekStart.month();
    const year = this.weekStart.year();

    if (week === this.week) { week-- };
    if (month === 11 && week === 1) {
      this.weekFilterForm = new FormControl({ year: year, week: 53 });
    } else {
      this.weekFilterForm = new FormControl({ year: year, week: week });
    }
  }
}
