import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const ENDPOINT = 'todo';

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  constructor(private baseApi: CarestartApiService) { }

    /* TODO */

    getByIdObs(id: any) {
      const endpoint = `${ENDPOINT}/${id}`;
      return this.baseApi.getObs(endpoint);
    }

    getById(id: any) {
      return this.baseApi.basicGet(`${ENDPOINT}/${id}`)
    }

    getByEmployeeId(id: any) {
      return this.baseApi.basicGet(`${ENDPOINT}/employee/${id}`)
    }
  
    getAll() {
      return this.baseApi.basicGet(ENDPOINT);          
    }
  
    create(data: any) {
      delete data.id;
      return this.baseApi.basicCreate(ENDPOINT, data);
    }
  
    delete(id: any) {
      const endpoint = `${ENDPOINT}/${id}`;
      return this.baseApi.delete(endpoint);
    }
  
    patch(object: any) {
      const data: any = this.baseApi.createPatchObject(object);
      return this.baseApi.updatePartial(ENDPOINT, object.id, data);
    }

    updateStatus(id: any, status: any) {
      const payload = { 
        id, status 
      };

      return this.patch(payload);
    }

}
