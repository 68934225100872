import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

import forEach from 'lodash-es/forEach';

const ORGANISATION_ENDPOINT = 'organisation';
const XERO_CONNECT_ENDPOINT = 'xeroConnect';
const ORGANISATION_INFO_ENDPOINT = 'organisationInfo';
const ORGANISATION_MODULE_ENDPOINT = 'organisationModule';
const ORGANISATION_NDISREGISTRATIONS_ENDPOINT = 'organisationNDISRegistration'

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  constructor(private baseApi: CarestartApiService) { }

  /* Organisation */
  getOrganisation(id: any) {
    if (!id) { console.log('no id'); return null; }
    const endpoint = `${ORGANISATION_ENDPOINT}/${id}`;

    const organisation: any = this.baseApi.basicGet(endpoint);

    organisation.hasModule = [];
    forEach(organisation.modules, m => {
      organisation.hasModule.push(m.code);
    });

    return organisation;
  }

  //obs
  getOrganisationObs(id: any) {
    if (!id) { console.log('no id'); return null; }
    const endpoint = `organisation/${id}`;

    return this.baseApi.getObs(endpoint);
  }

  createOrganisation(data: any) {
    return this.baseApi.basicCreate(ORGANISATION_ENDPOINT, data);
  }

  patchOrganisation(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(ORGANISATION_ENDPOINT, payload.id, data);
  }

  // xeroConnect
  organisationConnectXero() {
    const endpoint = `${XERO_CONNECT_ENDPOINT}/authorization`;
    return this.baseApi.basicGet(endpoint);
  }

  organisationDisconnectXero() {
    const endpoint = `${XERO_CONNECT_ENDPOINT}/disconnect`;
    return this.baseApi.basicGet(endpoint);
  }

  // xero
  getXeroAuthorisationUrl() {
    const endpoint = `${XERO_CONNECT_ENDPOINT}/authorization`;
    return this.baseApi.basicGet(endpoint);
  }

  getXeroUserToken(id: any) {
    const endpoint = `xeroToken/user/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getXeroAccessToken() {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/accessToken`;
    return this.baseApi.basicGet(endpoint);
  }

  getXeroGroups(id: any) {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/groups`;
    return this.baseApi.basicGet(endpoint);
  }

  getXeroTrackingCategories(id: any) {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/trackingCategories`;
    return this.baseApi.basicGet(endpoint);
  }

  getXeroOrganisationDetails() {
    return this.baseApi.basicGet(ORGANISATION_INFO_ENDPOINT);
  }

  syncXeroTimesheets(timesheet: any) {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/syncPayroll`;
    return this.baseApi.basicCreate(endpoint, timesheet);
  }

  getPayruns(status: string = "") {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/payruns?status=${status}`;
    return this.baseApi.basicGet(endpoint);
  }

  getPayrunById(id: string = "") {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/payrun/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getPayslipById(id: string = "") {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/payslip/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  syncXeroPayslip(earningsLine: any, payslipId: string) {
    const endpoint = `${ORGANISATION_INFO_ENDPOINT}/syncPayslip/${payslipId}`;
    return this.baseApi.basicCreate(endpoint, earningsLine);
  }

  // disconnectUser(userId: any) {
  //   const endpoint = `disconnect/${userId}`;
  //   return this.baseApi.basicGet(endpoint);
  // }

  // modules for org
  addModuleToOrg(moduleId: any) {
    const endpoint = `${ORGANISATION_MODULE_ENDPOINT}/${moduleId}`;
    return this.baseApi.basicCreate(endpoint, null);
  }

  removeModuleToOrg(moduleId: any) {
    const endpoint = `${ORGANISATION_MODULE_ENDPOINT}/${moduleId}`;
    return this.baseApi.delete(endpoint);
  }



  // organisationNDISRegistration
  getNDISRegistration() {
    const endpoint = `${ORGANISATION_NDISREGISTRATIONS_ENDPOINT}`;
    return this.baseApi.basicGet(endpoint);
  }

  createNDISRegistration(data: any) {
    return this.baseApi.basicCreate(ORGANISATION_NDISREGISTRATIONS_ENDPOINT, data);
  }

  patchNDISRegistration(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(ORGANISATION_NDISREGISTRATIONS_ENDPOINT, payload.id, data);
  }

  removeNDISRegistration(id: any) {
    const endpoint = `${ORGANISATION_NDISREGISTRATIONS_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }
}
