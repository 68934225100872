import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const ENDPOINT = 'userProfile';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private baseApi: CarestartApiService
  ) { }

  /* USER PROFILE */
  getUserProfileObs() {
    return this.baseApi.getObs(ENDPOINT);
  }

  getUserProfile() {
    //console.log('api call UserProfileService userProfile');
    return this.baseApi.basicGet(ENDPOINT);
  }

  getUserProfileById(id) {
    //console.log('api call UserProfileService userProfile');
    return this.baseApi.basicGet(ENDPOINT + '/' + id);
  }

  patchUserProfile(userProfile: any) {
    const data: any = this.baseApi.createPatchObject(userProfile);
    return this.baseApi.updatePartial(ENDPOINT, userProfile.id, data);
  }
}
