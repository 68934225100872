import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ROUTER_CONFIGURATION, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { JwtService } from '../jwt.service';

import map from 'lodash-es/map';
import some from 'lodash-es/some';
import indexOf from 'lodash-es/indexOf';

import { SettingsService } from 'src/app/core/settings/settings.service';

@Injectable({
	providedIn: 'root'
})
export class RoleGuardService implements CanActivate, CanActivateChild {

	constructor(
		public authService: AuthService,
		public router: Router,
		//private userProfileService: UserProfileService,
		private settingsService: SettingsService,
		private jwtService: JwtService
	) { }

	// canActivateChild(route: any): any {
	// 	const self = this;
	// 	const promise = new Promise((resolve, reject) => {
	// 		this.checkRoles(route).then((value: any) => {
	// 			// if (!value) {
	// 			// 	self.router.navigate(['/unauthorized']);
	// 			// } else {
	// 				resolve(true);
	// 			//}
	// 		});
	// 	});
	// 	return promise;
	// }

	async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		console.log('*** RoleGuardService - canActivateChild ***');
		const user: any = await this.authService.userValue();
		const org : any = this.settingsService.getOrganisationSetting(null);
		
		const pageRoles = route.data.roles;
		const pageModules = route.data.modules;

		// TODO
		// instead of checking from the route we should load in our menu.ts and get the role/module permissions from there
		// that wlll keep consistent with menu showing them and we only have to save in 1 place
		// later consider a move to db for this data if required to let orgs customise their security

		// console.log('route', route);
		// console.log('route.data.roles', route.data.roles);
		// console.log('route.data.modules', route.data.modules);
		// console.log('user', user);

		const userRoles = user.roles; // JSON.parse(user.roles);
		const orgModules = org.modules; 

		//console.log('userRoles', userRoles);

		// check if route does not require role or module, if so proceeed
		if ((!route.data.roles || !route.data.roles.length) && (!route.data.modules || !route.data.modules.length)) {
			//console.log('no userRoles and noModules reequired this page');
			return true;
		}

		// if no user role and one required then redirect
		// consider going to account page if no roles at all
		if (!user.roles && route.data.roles?.length) {
			//console.log('no userRoles on page that requires roles of', route.data.roles);
			this.router.navigate(['/']);
			return false;
		}

		// if no module and one requird then redirect
		if (!org.modules && route.data.modules?.length) {
			//console.log('no modules on page that requires modules of', route.data.modules);
			this.router.navigate(['/']);
			return false;
		}
		
		// console.log('pageRoles', pageRoles);
		// console.log('pageModules', pageModules);
		const userRolesNames = map(userRoles, a => { return a.name; });  userRolesNames.push( 'any' );
		const orgModulesCodes = map(orgModules, a => { return a.code; });
		// console.log('userRolesNames', userRolesNames);
		// console.log('orgModulesCodes', orgModulesCodes);

		// if no roles/modules req then must be true that we have one, otherwise check
		const hasRole = pageRoles ? pageRoles?.some((r: any) => userRolesNames.indexOf(r) > -1) : true;
		const hasModule = pageModules ? pageModules?.some((r: any) => orgModulesCodes.indexOf(r) > -1) : true;
		// console.log('hasRole', hasRole);
		// console.log('hasModule', hasModule);

		// if no role required then redirect
		if (!hasRole)
		{
			// role not authorised so redirect to home page
			console.log('restricted by role', user);
			this.router.navigate(['/']);
			return false;
		}

		// if no required module then redirect
		if (!hasModule)
		{
			// module not authorised so redirect to home page
			console.log('restricted by module', org);
			this.router.navigate(['/']);
			return false;
		}

		// proceed
		return true;
	}

	canActivate(route: any): any {
		console.log('RoleGuardService - canActivate');
		const self = this;
		const promise = new Promise((resolve, reject) => {
			this.checkRoles(route).then((value: any) => {
				if (!value) {
					self.router.navigate(['/unauthorized']);
				} else {
					resolve(true);
				}
			});
		});
		return promise;
	}

	async checkRoles(route: any) {
		console.log('+++ RoleGuardService - checkRoles +++');
		const yes = new Promise((resolve) => {
			resolve(true);
		});

		const promise = new Promise((resolve) => {
			if (route._routerState.url === '/login' || route._routerState.url === '/home') { resolve(true); }
			resolve(false);
		});

		const user: any = this.jwtService.getUser();
		console.log('user in checkRoles', user);
		if (!user || !user.role) {
			
		} else {
			return this.isAllowed(route);
		}

		return promise;
	}

	isAllowed(route: any): Promise<boolean> {
		const self = this;
		const promise = new Promise<boolean>(function (resolve, reject) {
			self.isUserAllowed(route).then(function (data: any) {
				resolve(data);
			});
		});

		return promise;
	}

	isUserAllowed(route: any): Promise<boolean> {
		let roles = {};
		let user: any = this.jwtService.getUser();
		map(user.roles, role => {
			roles[role.name.toLowerCase()] = true;
		});
		const self = this;
		const promise = new Promise<boolean>(function (resolve, reject) {
			self.func(roles, route).then(function (result: any) {
				resolve(result);
			});
		});
		return promise;
	}

	func(roles: any, route: any) {
		const self = this;

		const promise = new Promise(function (resolve, reject) {
			self.getAllowedRolesForPage(route).then(function (pageRoles) {
				const isAllowed = some(Object.keys(roles), function (rKey) {
					return (roles[rKey] === true && pageRoles[rKey] === true);
				});
				if (isAllowed === true) {
					resolve(true);
				} else {
					resolve(false);
				}
			});
		});
		return promise;
	}

	getAllowedRolesForPage(route: any): any {
		let rt = route._routerState.url;
		console.log('getAllowedRolesForPage', rt);
		if (route.routeConfig.path.indexOf(':') > -1) {
			const idx = route.routeConfig.path.substring(0, indexOf(route.routeConfig.path, ':'));
			const idx2 = rt.indexOf(idx);
			const res = rt.substring(0, idx2);
			rt = res + idx;
		} else if (rt.indexOf(';') > -1) {
			rt = rt.substring(0, rt.indexOf(';'));
		}
		const promise = new Promise(function (resolve, reject) {
		
			resolve([]);
		});

		return promise;
	}
}
