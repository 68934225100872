import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserTokenResponse } from '../core/models/response/user-token';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { UserProfileService } from "../core/services/user-profile.service";
import { jwtDecode } from "jwt-decode";

@Injectable()
export class JwtService {
    
    private TOKEN_KEY =  environment.tokenKey;

    constructor(public http: HttpClient, public settingsService: SettingsService, private userProfileService: UserProfileService) { 
    }

    getUser(): any {
        const tokenArray: any = this.getResponseTokenArray();

        // TODO should we using public key here to check whether this token was properly provided
        // otherwise a toke may be created by user and copy/pasted into the session var

        // we only do this because we want to control the access of pages using roleAccess from token locally

        return tokenArray;
    }

    getUserId(): any {
        const tokenArray: any = this.getResponseTokenArray();
        const userId = tokenArray.uid;
        return userId;
    }

    isLoggedIn(): boolean {
        let jwtToken = this.getResponseTokenArray();
        if (!jwtToken) {
          return false;
        }
     
        // check if token is expired
        const tokenExpired = Date.now() > (jwtToken.exp * 1000);
     
        return !tokenExpired;
      }

    saveUser(user: any) {
        console.log('saveUser in authService', user);
        localStorage.setItem(this.TOKEN_KEY, JSON.stringify(user));
    }

    destroyUser() {
        localStorage.removeItem(this.TOKEN_KEY);
    }

    getTokenResponse(): UserTokenResponse {
        const token = localStorage.getItem(this.TOKEN_KEY);
        if (token) {
            return JSON.parse(token);
        } else {
            //return null;
            return {jwtToken: '', refreshToken: '', id: null, firstName: null, lastName: null, email: null};
        }
    }

    getRefreshToken(): string {
        const tokenResponse: UserTokenResponse = this.getTokenResponse();
        if (tokenResponse)
            return tokenResponse.refreshToken;
        else
            return null;
    }

    getTokenExpirationTime(token: string): number {
        if (!token) { return null; }
        const decoded = jwtDecode(token) as {exp: number};
        if (!decoded.exp) {
          return null;
        }
        //console.log(' decoded.exp',  decoded.exp)
        const expirationTime = decoded.exp * 1000; // convert from Unix timestamp to milliseconds
        return expirationTime;
      }

    refreshToken(): Observable<{accessToken: string; refreshToken: string}> {
        const token: UserTokenResponse = this.getTokenResponse();
        const refreshToken = token.refreshToken;

        console.log('get started on refreshToken api call', refreshToken);
       // debugger;
       
        return this.http.post<{refreshToken: string}>(
            `${environment.carestartApiUri}auth/refresh-token`,
            {
                refreshToken
            }).pipe(
                tap(async (response: any) => {
                    console.log('response', response);
                    //debugger; 
                    if (response.Status === 0) {
                        // error
                        console.log('invalid token');
                        //return null;
                    } else {
                    // save token to local storage
                    this.saveUser(response);
                    console.log('!!!!!! response in userValue auth.service from refresh token ******', response);                                       
                    }
                    this.settingsService.setUserSetting('id', null);
                    this.settingsService.setOrganisationSetting('id', null);
                    // vals will be restored shorly on next call of auth.userValue
                })
            );
    }

    private getResponseTokenArray() {
        const token: UserTokenResponse = this.getTokenResponse();

        if (!token) return false;
        
        const jwtToken = token.jwtToken;

        if (jwtToken == null) return null;

        const b = window.atob(jwtToken.split('.')[1])
        const bArray = JSON.parse(b);

        return bArray;
    }

}