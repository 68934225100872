import { AuthService } from '@core/services/auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import Swal from 'sweetalert2'

import map from "lodash-es/map";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

constructor(
	private router: Router, 
	private authService: AuthService
) { }
  
	// check if Authenticated and also check that user has access role above FieldStaff
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		console.log('*** AuthGuardService - canActivate ***');
		//console.log('authguard make request for userValue');
		const user: any = await this.authService.userValue();
		//console.log('user back from authService', user);
		// if trying to access an outsie page then we dont need userprofile

		
		if (user) {
			// check if user is just fieldstaff
			let hasSitePermission = this.hasSitePermission(user);
			//console.log('hasSitePermission', hasSitePermission);
			if (!hasSitePermission) {
				Swal.fire(
					'Not allowed',
					'You need an appropriate access role to proceed. Please contact your Admin for support',
					'error'
				);
				this.router.navigate(['/login']);
				return false;
			}

			//console.log('hasSitePermission');
            // authorised so return true
            return true;
		} 

		console.log('not logged in');
		// not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;

	}

	hasSitePermission(user: any) {
		const roles: any = map(user.roles, 'code') as Array<string>;
		//console.log('roles from hasSitePermission', roles);
		
		const countRoles = roles.length;
		//console.log('roles count from hasSitePermission', countRoles);

		// refuse access if FS or CLIENT is only role 
		const allowAccess = !(roles.FS || roles.CLIENT);

		// if (countRoles === 1 && roles.FS) return false;
		// if (countRoles === 1 && roles.CLIENT) return false;
		// if (countRoles === 2 && roles.CLIENT && roles.FS) return false;

		return allowAccess;		
	}
}