<div class="row">
  <div class="col-tasks offset">
    <div class="card mt-0 mb-1">
      <div class="card-body pt-2">
        <div class="">
          <div class="filter-section">
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.description" [term]="t"></ngb-highlight>
            </ng-template>
            <input type="text" class="form-control" [(ngModel)]="searchInput" [ngbTypeahead]="search"
              [resultTemplate]="rt" [inputFormatter]="formatter" (selectItem)="onSearchItemSelected($event)" />
            <button class="btn btn-primary btn-sm ms-2" (click)="searchTodos()">Search</button>
          </div>
          <div class="filter-section">
            <ng-select [items]="statusOptions" [(ngModel)]="selectedStatuses" style="width: 100%"
              [multiple]="true" placeholder="Select statuses" (change)="applyStatusFilter()">
            </ng-select>
          </div>
        </div>
        <div class="d-grid gap-2 ms-2 me-2 mt-0">
          <button *ngIf="!showAddEditComponent" class="btn btn-success btn-xs btn-block" type="button"
            (click)="toggleAddEditComponent()">
            <em class="fa fa-plus-circle fa-fw"></em> Add New Task
          </button>
          <button *ngIf="showAddEditComponent" class="btn btn-secondary btn-xs btn-block" type="button"
            (click)="toggleAddEditComponent()">
            Cancel
          </button>
        </div>
        <hr />

        <ng-container *ngIf="!showAddEditComponent">
          <div class="row">
            <div class="col">
              <div class="btn-group d-flex" role="group" aria-label="Category Filter Buttons">
                <button *ngFor="let category of categories" type="button" class="btn btn-xs btn-gray me-1"
                  [class.filter-selected]="selectedCategory === category" (click)="applyCategoryFilter(category)">
                  {{ category }}
                </button>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-hover mt-1">
              <thead class="table-light">
                <tr>
                  <th style="width: 10%;"></th>
                  <th style="width: 20%;">Category</th>
                  <th>Description</th>
                  <th style="width: 20%;">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr class="no-data" *ngIf="!todos?.length">
                  <td colspan="4">No data</td>
                </tr>
                <tr *ngFor="let item of todos">
                  <td>
                    <em class="fa fa-fw" (click)="confirmChangeToCompleted(item)" [attr.title]="item.status | titlecase"
                      [style.color]="getStatusLabelColor(item.status)" [class.fa-check-circle]="item.status === 'done'"
                      [class.fa-circle]="item.status !== 'done'"></em>
                  </td>
                  <td>{{item.category}}</td>
                  <td class="hoverable" (click)="toggleAddEditComponent(item)">{{item.description}}</td>
                  <td>
                    <span *ngIf="item.category === 'Course' && item.courseId">
                      <button class="btn btn-xs btn-primary" (click)="gotoCourse(item.courseId)">Course <i
                          class="fa-solid fa-arrow-right"></i></button>
                    </span>
                    <span *ngIf="item.category === 'Job' && item.jobId">
                      <button class="btn btn-xs btn-primary" (click)="gotoJob(item.jobId)">Job <i
                          class="fa-solid fa-arrow-right"></i></button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>

        <edit-todo-component *ngIf="showAddEditComponent" id="editTodoComponent" #editTodoComponent [employees]="null"
          [callback]="getTodoItems.bind(this)" [model]="selectedItem"
          (save)="toggleAddEditComponent()"></edit-todo-component>
      </div>
    </div>
  </div>
</div>