

<div class="modal modal-dialog-scrollable fade" bsModal #jobModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
    [config]="{backdrop: 'static'}" (onHide)="onModalHide()">
    <edit-job-component [container]="jobModal" [modalCode]="modalCode" [model]="model" [isNew]="isNew" [showDetail]="showDetail" 
    [containerVisible]="jobModal.isShown" [callback]="callback" [scheduleDate]="scheduleDate" #editJobComponent></edit-job-component>
</div>

<div class="modal fade" bsModal #cloneJobModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
    [config]="{backdrop: 'static'}" (onHide)="onModalHide()">
    <clone-job-component [container]="cloneJobModal" [model]="model" [containerVisible]="cloneJobModal.isShown" 
    [multiple]="genericFlag" [currentOrganisation]="currentOrganisation" #cloneJobComponent></clone-job-component>
</div>

<app-edit-clinic-note #clinicNoteModal editMode="true" [managedType]="managedType" caller="top-navbar"></app-edit-clinic-note>