<form [formGroup]="outletForm" (ngSubmit)="saveOutlet()">
    <div class="row mb-3">
        <div class="col">
            <div class="row">
                <label class="text-bold col-sm-2 col-form-label text-end">Name</label>
                <div class="col-sm-9">
                    <div class="input-group">

                        <span class="input-group-text bg-white">
                            <i class="fa fa-user"></i>
                        </span>

                        <input placeholder="Name" formControlName="name" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <label class="text-bold col-sm-2 col-form-label text-end">Street Address</label>
                <div class="col-sm-9">
                    <div class="input-group">

                        <span class="input-group-text bg-white">
                            <i class="fa-solid fa-road"></i>
                        </span>

                        <input placeholder="Address" formControlName="address" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <label class="text-bold col-sm-2 col-form-label text-end">Suburb</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <span class="input-group-text bg-white">
                            <i class="fa fa-home"></i>
                        </span>
                        <input placeholder="Suburb" formControlName="suburb" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <label class="text-bold col-sm-2 col-form-label text-end">State</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <span class="input-group-text bg-white">
                            <i class="fa-solid fa-flag"></i>
                        </span>
                        <input placeholder="State" formControlName="state" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <label class="text-bold col-sm-2 col-form-label text-end">Postcode</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <span class="input-group-text bg-white">
                            <i class="fa-solid fa-street-view"></i>
                        </span>
                        <input placeholder="Postcode" [class.err]="!outletForm.get('postcode').valid" formControlName="postcode" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <label class="text-bold col-sm-2 col-form-label text-end">Phone Number</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <span class="input-group-text bg-white">
                            <i class="fa fa-phone"></i>
                        </span>
                        <input name="phone" type="tel" pattern="[0-9]* [0-9]* [0-9]*" formControlName="phone" placeholder="Phone Number"
                            class="form-control" [class.err]="!outletForm.get('phone').valid">
                    </div>
                </div>
            </div>
            <div class="row mt-2 valign-center">
                <label class="text-bold col-sm-2 col-form-label text-end">Is Active</label>
                <div class="col-sm-9">
                    <div class="input-group">
                        <div class="checkbox c-checkbox">
                            <label class="checklabel">
                                <input type="checkbox" formControlName="isActive" />
                                <span class="fa fa-check"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-xs btn-gray" (click)="closeModal()">Cancel</button>
        <button type="submit" class="btn btn-xs btn-primary" [disabled]="!outletForm.valid">{{isNew ? 'Create' : 'Save' }}</button>
    </div>
</form>