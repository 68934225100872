import {
  Component,
  ViewChild,
  forwardRef,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { FormControl, NgControl, ControlValueAccessor, AbstractControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormsModule } from "@angular/forms";
import { NgbDate, NgbCalendar, NgbDatepicker, NgbDateStruct, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { NgIf } from '@angular/common'; 
import dayjs from 'dayjs';
import { BusinessService } from 'src/app/services/business.service';
import { takeWhile, debounceTime, startWith } from "rxjs/operators";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

@Component({
    selector: "ngc-week-picker",
    template: `
      <div id="datepicker-container">
      <div class="input-group">
      <div class="input-group-prepend" *ngIf="iconDisplay">
          <span class="input-group-text bg-white">
              <i class="fas fa-calendar-day" style="color:gray"></i>&nbsp;
          </span>
      </div>
      <input #inputpicker class="form-control date-picker-input" placeholder="Select week"
                name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelection($event)"
                [showWeekNumbers]="true" [disabled]="disabled" [firstDayOfWeek]=firstDayOfWeek
                [dayTemplate]="t" (click)="d.toggle()" [markDisabled]="isDisabled">
        </div>
      </div>
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)">
          {{ date.day }}
        </span>
      </ng-template>
    `,
    styles: [
        `
        .custom-day {
          text-align: center;
          padding: 0.185rem 0.25rem;
          display: inline-block;
          height: 2rem;
          width: 2rem;
        }
        .custom-day.range {
          background-color: rgb(2, 117, 216);
          color: white;
        }.date-picker-input {
          min-width: 174px;
        }`
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WeekPickerComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgbInputDatepicker, FormsModule, NgIf]
})
export class WeekPickerComponent implements ControlValueAccessor, AfterViewInit {
  fromDate: NgbDate;
  toDate: NgbDate;
  model: NgbDateStruct;
  firstDayOfWeek: any;
  @ViewChild(NgbDatepicker, { static: false }) datePicker;
  @ViewChild('inputpicker', { static: false }) input;

  @Input() iconDisplay: boolean = true;
  @Input() disabled: boolean = false;
  @Input() emptyInit: boolean = false;
  @Input() startDay: any;
  @Input() restricted: any = false;
  @Output() change: EventEmitter<object> = new EventEmitter<object>();

  isDisabled = (date: NgbDate, current: { month: number }) => {
    if (this.restricted) {
      return date.year <= dayjs().year() &&
      date.month <= (dayjs().month() + 1) &&
      (date.day < dayjs().startOf('week').date()) 
    }
    return false;
  }

  set week(value) {
    this.onChange({ year: this.fromDate.year, week: value });
    const date = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0);
    this.change.emit(date);
  }

  onChange: (_: any) => void;
  onTouched: any;
  constructor(private ngbCalendar: NgbCalendar, private businessService: BusinessService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.startDay && changes.startDay.currentValue) {
      if (!this.firstDayOfWeek) {
        this.firstDayOfWeek = this.businessService.mapStartDay(changes.startDay.currentValue, false);
      }
    }
  }

  onDateSelection(date: NgbDate) {
    const startDay = this.businessService.mapStartDay(this.startDay, false);
    let fromDate = new Date(date.year + "-" + date.month + "-" + date.day);
    let time = fromDate.getDay() - startDay + (startDay < 7 ? 0 : 7);
    fromDate = new Date(fromDate.getTime() - time * 24 * 60 * 60 * 1000);
    this.fromDate = new NgbDate(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      fromDate.getDate()
    );
    const toDate = new Date(fromDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    this.toDate = new NgbDate(
      toDate.getFullYear(),
      toDate.getMonth() + 1,
      toDate.getDate()
    );
    if (this.onTouched) this.onTouched();
    if (this.onChange) this.week = this.calculateWeek(fromDate);
    if (this.input) {
      this.input.nativeElement.value = `${dayjs(fromDate).format('YYYY-MM-DD')} - ${dayjs(toDate).format('YYYY-MM-DD')}`;
    }
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date)
    );
  }

  calculateWeek(date: any) {
    const time = date.getTime() + 4 * 24 * 60 * 60 * 1000;
    const firstDay = new Date(date.getFullYear() + "-1-1");
    return (
      Math.floor(Math.round((time - firstDay.getTime()) / 86400000) / 7) + 1
    );
  }
  
  calculateDate(week: number, year: number) {
    const firstDay = dayjs.utc(`${year}-01-01`);
  
    const targetDate = firstDay.add(week - 1, 'week').startOf('week'); // Move to the start of the week
  
    const selectDate = new NgbDate(
      targetDate.year(),
      targetDate.month() + 1,
      targetDate.date()
    );
  
    this.onDateSelection(selectDate);
  }

  ngAfterViewInit() {
    if (this.fromDate) {
      if (!this.emptyInit) {
        setTimeout(() => {
          const start = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0, 0);
          const end = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 0, 0, 0, 0);
          this.input.nativeElement.value = `${dayjs(start).format('YYYY-MM-DD')} - ${dayjs(end).format('YYYY-MM-DD')}`;
          //this.datePicker.navigateTo(this.fromDate);
        });
      }
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  writeValue(value: any): void {
    if (value) {
      this.calculateDate(value.week, value.year);
    }
  }
}
