import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';

//import { MsalGuard } from '@azure/msal-angular';
import { AuthGuardService as AuthGuard } from './../services/auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './../services/auth/role-guard.service';
import { ResetComponent } from './pages/reset/reset.component';
import { TrialComponent } from './pages/trial/trial.component';

export const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        //canActivate: [MsalGuard],
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full', title: 'home' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),  canActivateChild: [RoleGuard] },
         //   { path: 'facilities', loadChildren: () => import('./facilities/facilities.module').then(m => m.FacilitiesModule),   canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'sils', loadChildren: () => import('./sils/sils.module').then(m => m.SilsModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'group-activity', loadChildren: () => import('./group-activity/groupActivity.module').then(m => m.GroupActivityModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'clients', title: 'Clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),  canActivateChild: [RoleGuard], data: { modules: ['PM', 'DS', 'COS'] }},
            { path: 'cos', loadChildren: () => import('./cos/cos.module').then(m => m.COSModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager', 'COS'], modules: ['COS'] }},
            { path: 'plan-manage', loadChildren: () => import('./plan-manage/plan-manage.module').then(m => m.PlanManageModule),  canActivateChild: [RoleGuard], data:{ modules: ['PM'] }},
            { path: 'price-guides', loadChildren: () => import('./price-guides/price-guides.module').then(m => m.PriceGuidesModule),  canActivateChild: [RoleGuard]},
            { path: 'ndis-portal', title: 'NDIS Portal', loadChildren: () => import('./ndis-portal/ndis-portal.module').then(m => m.NDISPortalModule),  canActivateChild: [RoleGuard], data: {modules: ['PM', 'DS', 'COS'] }},
            { path: 'invoices', title: 'Invoices', loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'], modules: ['PM', 'DS', 'COS'] }},
            { path: 'cosmanager', title: 'COS Managers', loadChildren: () => import('./cosManager/cos-manager.module').then(m => m.CosManagerModule),  canActivateChild: [RoleGuard], data: { roles: ['Admin', 'Office Manager'],  modules: ['PM', 'DS'] }},
            { path: 'providers', loadChildren: () => import('./providers/providers.module').then(m => m.ProvidersModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager', 'Office Staff'] }},           
            { path: 'business', loadChildren: () => import('./business/business.module').then(m => m.BusinessModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'activity', loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'rosters', title: 'Rosters', loadChildren: () => import('./rosters/rosters.module').then(m => m.RostersModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager', 'Office Staff', 'Plan Manager'], modules: ['PM', 'DS', 'COS'] }},
            { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager'] }},
            { path: 'statements', title: 'Statements', loadChildren: () => import('./statements/statements.module').then(m => m.StatementsModule),  canActivateChild: [RoleGuard], data: { modules: ['PM'] }},            
            { path: 'mailbox', loadChildren: () => import('./mailbox/mailbox.module').then(m => m.MailboxModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager', 'Mailbox'] }},
            { path: 'audit', loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager', 'Office Staff', 'Audit'], modules: ['COMP'] }},
            { path: 'outlets', loadChildren: () => import('./outlets/outlets.module').then(m => m.OutletsModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
           // { path: 'more', loadChildren: () => import('./more/more.module').then(m => m.MoreModule),  canActivateChild: [RoleGuard]},
            { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule), canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'feedback', loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'settings', title: 'Settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),  canActivateChild: [RoleGuard]},
            { path: 'service-agreements', title: 'Service Agreements', loadChildren: () => import('./service-agreements/service-agreements.module').then(m => m.ServiceAgreementsModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin',  'Office Manager'], modules: ['PM', 'DS'] }},
            { path: 'staff', title: 'Staff', loadChildren: () => import('./staff/staff.module').then(m => m.StaffModule), canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager'] }},
            { path: 'loans', title: 'Loans', loadChildren: () => import('./loans/loans.module').then(m => m.LoansModule), canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},            
            { path: 'files', title: 'Files', loadChildren: () => import('./files/files.module').then(m => m.FilesModule), canActivateChild: [RoleGuard]},
            { path: 'payroll', title: 'Payroll', loadChildren: () => import('./payroll/payroll.module').then(m => m.PayrollModule), canActivateChild: [RoleGuard], data: {roles: ['Admin'], modules: ['P'] }},
            { path: 'users', title: 'Users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'courses', title: 'Courses', loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule), canActivateChild: [RoleGuard], data: { modules: ['COURSE'] }},
            { path: 'network', loadChildren: () => import('./network/network.module').then(m => m.NetworkModule), canActivateChild: [RoleGuard], data: {roles: ['Admin', 'Office Manager'] }},
            { path: 'workflows', loadChildren: () => import('./workflows/workflows.module').then(m => m.WorkflowsModule), canActivateChild: [RoleGuard], data: {roles: ['Admin'] }},
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            // { path: 'admin-stats', loadChildren: () => import('./admin-stats/admin-stats.module').then(m => m.AdminStatsModule) },
            //   { path: 'departments', loadChildren: () => import('./departments/departments.module').then(m => m.DepartmentsModule),  canActivateChild: [RoleGuard] },            
            { path: 'xero', title: 'Xero', loadChildren: () => import('./xero/xero.module').then(m => m.XeroModule),  canActivateChild: [RoleGuard], data: {roles: ['Admin'], modules: ['XERO'] }},
        ]
    },
    { 
        //Anonymous access
        path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent, title: 'Carestart - login' },    //, canActivate: [AuthGuard]
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'reset', component: ResetComponent },
    { path: 'lock', component: LockComponent },
    { path: 'trial', component: TrialComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },
    { path: 'unauthorized', component: UnauthorizedComponent },
    

    // Not found
    { path: '**', redirectTo: 'home' }

];
