import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { timeout, catchError, map, tap, retry, finalize, take } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private apiUrlCarestart = environment.carestartApiUri ;
  private apiUrlABNLookup = environment.abnLookupUri; 
  private apiURLABNNameLookup = environment.abnNameLookupUri;

  private token: any;
  private apiKey =  environment.apiKey;

  constructor(public http: HttpClient) { 
  }

  // HTTPOPTIONS HEADERS //
 
  getHttpOptionsJson() {
    const storageToken = localStorage.getItem('token');
    const lsToken: any = storageToken && storageToken !== undefined && (typeof storageToken === 'string') ? JSON.parse(storageToken) : null;
    let token = lsToken != null && lsToken["jwtToken"] ? lsToken["jwtToken"] : "";
    //console.log('token', token);
    //debugger;

    if (!token) {
        token = 'test'
    }

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ApiKey': this.apiKey,
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Credentials': 'true',
        //'Access-Control-Max-Age': '7200'
      }),
      //withCredentials: true
    }
  }

  getHttpOptionsFile(type: any) {
    const storageToken = localStorage.getItem('token');
    const lsToken: any = storageToken && storageToken !== undefined && (typeof storageToken === 'string') ? JSON.parse(storageToken) : null;
    const token = lsToken != null && lsToken["jwtToken"] ? lsToken["jwtToken"] : "";
    //console.log('token', token);

    var x =  {
      headers: new HttpHeaders({
        //'Content-Type': type,
        'ApiKey': this.apiKey,
        'Authorization': 'Bearer ' + token,
        'Access-Control-Allow-Credentials': 'true',
      }),
      responseType: 'blob' as 'blob'
      //withCredentials: true
    }
    return x;
  }

  // CARESTART API //
  getData(endPoint: string, t: number = 120000): Promise<any> {
    const self = this;
    const url = this.apiUrlCarestart + endPoint;

    const headers = self.getHttpOptionsJson()
    // console.log('url', url)
    // console.log('headers', headers)
    
    const promise = new Promise(function (resolve, reject) {
      console.log('httpService getData:', url);
      self.http.get(url, headers)
        .pipe(
          timeout(t),
          retry(2),
          //catchError(self.handleError)
        )
        .subscribe({
            next: (v) => {
              //console.log('Response from getData:', v); // Log the response value
              resolve(v)
            },
            error: (e) => reject(e.error),  // send back to original call so it can handle error 
            //complete: () => console.info('complete') 
        })
    });
    return promise;
  }

  getObs(endPoint: any, t = 120000): Observable<any> {
    const url = this.apiUrlCarestart + endPoint;
    return this.http.get(url, this.getHttpOptionsJson())
      .pipe(
        tap(_ => this.log('fetched data from ' + endPoint)),
        catchError(this.handleErrorObs(endPoint, []))
      );
  }

  getFile(endPoint: any, type: any, t = 120000) {
    const self = this;
    const url = this.apiUrlCarestart + endPoint;
    // console.log('url', url);
    // console.log('type', type);

    const promise = new Promise(function (resolve, reject) {
      self.http.get(url, self.getHttpOptionsFile(type))
        .pipe(
          timeout(t),
          retry(2),
          catchError(self.handleError)        
        )
        .subscribe({
          next: (v) => resolve(v),
          error: (e) => console.error(e),
          //complete: () => console.info('complete') 
        })
    });
    return promise;
  }

  post(endPoint: any, payload: any) {
    const self = this;
    const url = self.apiUrlCarestart + endPoint;

    const promise = new Promise(function (resolve, reject) {
      self.http.post(url, payload, self.getHttpOptionsJson())
        .pipe(
          timeout(120000),
          catchError(e => {
            return throwError(e);
          }),
          take(1) // Take only the first emitted value (either success or error)          
        )
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (err) => {
            err.url = url;
            reject(err) 
          },
          () => { }
        );
    });
    return promise;
  }

  put(endPoint: any, payload: any) {
    const self = this;
    const url = self.apiUrlCarestart + endPoint;

    const promise = new Promise(function (resolve, reject) {
      self.http.put(url, payload, self.getHttpOptionsJson())
        .pipe(
          timeout(120000),
          catchError(e => {
            let msg = 'Something went wrong. Please contact Administrator.';
            if (e.error) {
              msg = e.error.title;
            }
            reject(msg);
            return [];
          })
        )
        .subscribe({
          next: (v) => resolve(v),
          error: (e) => console.error(e),
          //complete: () => console.info('complete') 
        })
    });
    return promise;
  }

  patch(endPoint: any, payload: any) {
    const self = this;
    const url = self.apiUrlCarestart + endPoint;

    const promise = new Promise(function (resolve, reject) {
      self.http.patch(url, payload, self.getHttpOptionsJson())
        .pipe(
          timeout(120000),
          catchError(e => {
            let msg = 'Something went wrong. Please contact Administrator.';
            if (e.error) {
              msg = e.error.title;
            }
            reject(msg);
            return [];
          })
        )
        .subscribe({
          next: (v) => resolve(v),
          error: (e) => console.error(e),
          //complete: () => console.info('complete') 
        })
    });
    return promise;
  }

  delete(endPoint: any) {
    const self = this;
    const url = self.apiUrlCarestart + endPoint;

    const promise = new Promise(function (resolve, reject) {
      self.http.delete(url, self.getHttpOptionsJson())
        .pipe(
          timeout(120000),
          retry(2),
          catchError(self.handleError)  
        )
        .subscribe({
          next: (v) => resolve(v),
          error: (e) => console.error(e),
          //complete: () => console.info('complete') 
        })
    });
    return promise;
  }

  postFile(formData) {
    const url = this.apiUrlCarestart + 'upload';
    return this.http.post(url, formData, {reportProgress: true, observe: 'events'})
  }

  // ABN LOOKUP //

  getABNLookup(type: any, endPoint: any, t = 120000) {
    const self = this;
    const url = type === 'ABN' ? this.apiUrlABNLookup + endPoint : this.apiURLABNNameLookup + endPoint;
  
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
       // 'Authorization': "Basic " + btoa(client_id + ":" + client_secret)
      })
    };
  
    return this.http.jsonp(url, 'callback').pipe(
      map((response: any) => {
        // Parse the response data here
        console.log('response', response);
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('HTTP Error:', error);
        return throwError(error);
      })
    );
  }

  // UTILITIES //

  private log(message: string) {
    console.log('httpService message:', message);
    //this.messageService.add(`httpService: ${message}`);
  }

  errorHandler(error: any) {
    console.log(error);
  }

  private handleErrorObs<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // Handle API errors
  private handleError(resp: HttpErrorResponse) {
    if (resp.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', resp.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log('resp', resp)
      console.error(
        `Backend returned code ${resp.error?.status}, ` +
        `body was: ${resp.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error(
      'Something bad happened; please try again later.'));
  };

}
