import { AuthService } from '@core/services/auth.service';
import { Component, OnInit, Injector } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../core/settings/settings.service';
import { UserProfileService } from '@core/services/user-profile.service';
import { SocialUser, SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, MicrosoftLoginProvider } from "@abacritt/angularx-social-login";
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import Swal from 'sweetalert2'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    GoogleLoginProvider = GoogleLoginProvider;
    socialUser: SocialUser | undefined;
    userProfile: any;
    valForm: FormGroup;
    return = '';

    constructor(public settings: SettingsService,
        formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private userProfileService: UserProfileService,
        private readonly socialAuthService: SocialAuthService,
    ) {
        this.authService = authService;
        this.router = router;
        this.valForm = formBuilder.group({
            'email': [null, [Validators.required, Validators.email]],
            'password': [null, Validators.required]
        });
    }

    ngOnInit() {
        const token = localStorage.getItem('token');
        if (token !== null) {
            this.router.navigate(['/home']);
        }

        this.socialAuthService.authState.subscribe((user) => {
            if (user) {
                this.sendGoogleSignInToOurServer(user);
                this.socialUser = user;
                // this.loggedIn = (user !== null);
            }
        });
        // Get the query params
        this.route.queryParams.subscribe(params => this.return = params['return'] || '/');
    }

    refreshGoogleToken(): void { //NOT USED
        this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    }

    submitForm($ev: any, value: any) {
        $ev.preventDefault();

        var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        form.classList.add('was-validated');

        if (this.valForm.valid) {
            this.loginUserCarestart(value);
        }
    }

    loginUserCarestart(credentials: any) {
        this.blockUI.start();
        this.authService.carestartEmailLogin(credentials.email, credentials.password)
            .then((authData: any) => {
                if (!authData.id && authData.Status === 0 && authData.Message) {
                    this.blockUI.stop();
                    Swal.fire({
                        title: 'Not able to login',
                        icon: 'warning',
                        text: authData.Message,
                        confirmButtonColor: '#DD6B55',
                    });
                    return;
                }
                this.authService.setAuth(authData);
                this.userProfileService.getUserProfile()
                    .then(res => {
                        this.userProfile = res;
                        this.blockUI.stop();
                        this.router.navigate(['/home']);
                    })
                    .catch((err) => {
                        this.authService.logout();
                        this.blockUI.stop();
                        Swal.fire({
                            title: 'Not able to get user profile',
                            icon: 'warning',
                            //text: err,
                            confirmButtonColor: '#DD6B55',
                        });
                    });
            }).catch((err) => {
                this.blockUI.stop();
                Swal.fire({
                    title: 'Not able to login',
                    icon: 'warning',
                    //text: err,
                    confirmButtonColor: '#DD6B55',
                });
            });
    }

    sendGoogleSignInToOurServer(googleUser) {
        this.blockUI.start();
        this.authService.googleAuthenticate(googleUser).then((data: any) => {
            this.authService.setAuth(data);
            this.userProfileService.getUserProfile()
                .then(res => {
                    this.userProfile = res;
                    //this.profileService.userProfile = res;
                    this.blockUI.stop();
                    //this.menuService.setMenu();
                    this.router.navigate(['/home']);
                }).catch((err) => {
                    this.authService.logout();
                    this.blockUI.stop();
                    Swal.fire({
                        title: 'Not able to login',
                        //text: err,
                        icon: 'warning',
                        confirmButtonColor: '#DD6B55',
                    });
                });
        });
    }

    socialSignInWithFB(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(fbUser => {
            console.log('fbUser', fbUser);
        });
    }

    signInWithFB(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    //   signInWithAmazon(): void {
    //     this.socialAuthService.signIn(AmazonLoginProvider.PROVIDER_ID);
    //   }

    //   signInWithVK(): void {
    //     this.socialAuthService.signIn(VKLoginProvider.PROVIDER_ID);
    //   }

    signInWithMicrosoft(): void {
        this.socialAuthService.signIn(MicrosoftLoginProvider.PROVIDER_ID).then((data: any) => {
            console.log('data'), data
            this.authService.setAuth(data);
            this.userProfileService.getUserProfile()
                .then(res => {
                    this.userProfile = res;
                    //this.profileService.userProfile = res;
                    this.blockUI.stop();
                    //this.menuService.setMenu();
                    this.router.navigate(['/home']);
                }).catch((err) => {
                    this.authService.logout();
                    this.blockUI.stop();
                    Swal.fire({
                        title: 'Not able to login',
                        //text: err,
                        icon: 'warning',
                        confirmButtonColor: '#DD6B55',
                    });
                });
        });
    }
}
