<div class="mt-4">
    <div class="d-flex">
        <h4>Outlets</h4>
        <div class="ms-auto">
            <button type="button" class="btn btn-sm rounded-pill btn-gray" (click)="showOutletModal(null, outletModal)">Add Outlet</button>
        </div>
    </div>
    <!-- <div class="ms-0"><b>Optional</b>: If client has multiple outlets you can enter them here</div> -->
    <div class="optional-box" *ngIf="outlets?.length">
        <scrollable class="list-group" height="140">
            <div class="mt-2 ms-2">
                <div *ngFor="let outlet of outlets" class="d-flex mb-1">
                    <span class="flex-grow-1"><b>{{outlet.name}}</b></span>
                    <button class="btn btn-gray btn-xs me-2" type="button" (click)="showOutletModal(outlet, outletModal)">Edit</button>
                    <button class="btn btn-danger btn-xs" type="button" (click)="deleteOutlet(outlet)">Del</button>
                </div>
            </div>
        </scrollable>
    </div>
   
</div>

<div class="modal fade" bsModal #outletModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true"
    [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg shadow">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{isOutletNew ? 'New' : 'Edit' }} Outlet</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close" (click)="outletModal.hide()"></button>
            </div>
            <div class="modal-body">
                <app-outlet-component [container]="outletModal" [model]="selectedOutlet" [isNew]="isOutletNew"
                    [clientId]="clientId" [callback]="callback"></app-outlet-component>
            </div>
        </div>
    </div>
</div>