import { Inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';
import { JwtService } from "src/app/services/jwt.service";
import { SettingsService } from '../settings/settings.service';
import { UserProfileService } from './user-profile.service';
import { OrganisationService } from './organisation.service';
//import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Subject } from 'rxjs';
import { map, take } from "rxjs/operators";
import { UserTokenResponse } from '../models/response/user-token';
import { OrganisationResponse } from '../models/response/organisation';
import { GoogleUserRequest } from '../models/request/google-user';
import { environment } from '../../../environments/environment';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import forEach from 'lodash-es/forEach';
import find from 'lodash-es/find';

@Injectable({ providedIn: 'root' })
export class AuthService {

  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();

  // private _currentUserSubject = new BehaviorSubject<UserTokenResponse>({} as UserTokenResponse);
  // public readonly currentUser = this._currentUserSubject.asObservable();

  // private _currentOrganisationSubject = new BehaviorSubject<OrganisationResponse>({} as OrganisationResponse);
  // public readonly currentOrganisation = this._currentOrganisationSubject.asObservable();

  public currentUser = signal<UserTokenResponse>({} as UserTokenResponse);
  public currentOrganisation = signal<OrganisationResponse>({} as OrganisationResponse);

  public carestartApiUri = environment.carestartApiUri;
  private running = false;
  private sub: any;

  constructor(
    private baseApi: CarestartApiService,
    private httpClient: HttpClient,
    private jwtService: JwtService,
    private settingsService: SettingsService,
    private userProfileService: UserProfileService,
    private organisationService: OrganisationService,
    private router: Router,
    private readonly socialAuthService: SocialAuthService,

    // @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    // private authService: MsalService,
    // private msalBroadcastService: MsalBroadcastService

  ) {
    console.log('*** AuthService ***');
  }

  async userValue(retryCount = 0) {
    //console.group('userValue');
    // Add a maximum retry limit to prevent infinite loop
    if (retryCount >= 3) {
      console.error('Maximum retry limit reached. Aborting.');
      return null; // or handle the error appropriately
    }
  
    // Check if we have token and if we have settingService values
    // If only token then load the userProfile and/or OrganisationSettings to save in settingService
    // If no token then return null as normal
    let user = this.settingsService.getUserSetting(null);
    let org = this.settingsService.getOrganisationSetting(null);
  
    if (!user?.id) {
      if (!this.running) {
        this.running = true;
        //debugger;
  
        try {
          // Fetch the user profile asynchronously
          console.log('Fetching user profile...');
          user = await this.userProfileService.getUserProfile();
          console.log('User profile fetched:', user);
  
          // Extract user roles and store them in user.hasRole
          user.hasRole = [];
          forEach(user.roles, m => {
            user.hasRole.push(m.code);
          });
  
          // TODO: Will this work on login as well?
          console.log('userProfile in authService userValue', user);
  
          // Set the app setting for API version
          this.settingsService.setAppSetting('apiVersion', user.version);
          console.log('API version set:', user.version);
  
          // Set the user in the settings service
          this.settingsService.setUser(user);
          console.log('User set in settings service:', user);
  
          // Get the JWT token array
          const jwtTokenArray = this.jwtService.getUser();
  
          // Set the current organization and user settings
          console.log('Setting current organization...');
          await this.setOrg(jwtTokenArray?.primarysid);
          console.log('Current organization set:', jwtTokenArray?.primarysid);
          this.settingsService.setUserSetting('currentOrganisationId', jwtTokenArray?.primarysid);
  
          // Set the current user and organization
          if (user) this.currentUser.set(user);
          if (user) this.currentOrganisation.set(org);
  
          this.running = false;
  
          console.log('UserValue completed successfully:', user);
  
          return user;
        } catch (error) {
          // Handle errors that occur during the async operation
          console.error('Error in userValue:', error);
          this.running = false;
  
          // Retry the operation with an incremented retry count
          console.log('Retrying userValue (Retry Count:', retryCount + 1, ')');
          console.groupEnd();
          return await this.userValue(retryCount + 1);
        }
      } else {
        // If another instance of userValue is already running, log a message and retry
        this.running = false;
        console.log('userValue was already running. Retrying...');
        console.groupEnd();
        return await this.userValue(retryCount + 1); // Retry with incremented retry count
      }
    } else {
      // If user data is already available, proceed to check organization data
      if (!org?.id) {
        const jwtTokenArray = this.jwtService.getUser();
        await this.setOrg(jwtTokenArray?.primarysid);
        console.log('Current organization set (from cache):', jwtTokenArray?.primarysid);
      }
  
      // Set current user and organization
      if (user) this.currentUser.set(user);
      if (org) this.currentOrganisation.set(org);
  
      this.running = false;
  
      console.log('UserValue completed successfully:', user);
      console.groupEnd();
      
      return user;
    }
  }
  
  

  async setOrg(id: any) {
    var org = await this.organisationService.getOrganisation(id) || { modules: [] };

    org.hasModules = [];
    org.modules.forEach((module: any) => {
      org.hasModules[module.code] = true;
    });

    this.settingsService.setOrganisation(org);
  }


  changeCurrentOrgId(newOrgId: any) {
    return this.baseApi.basicGet('auth/changeOrg/' + newOrgId);
  }

  // TOKEN data tells us we are logged in
  // userProfile and roles can be called and saved in service
  // organisation can be called and saved in service

  // what data goes in token? are we happy using token just to establish userId to then get userProfile and organisation? 
  // or do we want to save some data like roles or currentOrgId in the token as well?

  /* SIGNIN */

  signInWithEmail(email: any, password: any) {
    const data = { 'Email': email, 'Password': password };
    return this.baseApi.basicCreate('auth/authenticate', data);
  }

  createUserWithEmail(credentials: any) {
    return this.baseApi.basicCreate('auth/signUp', credentials);
  }

  inviteUserWithEmail(credentials: any) {
    return this.baseApi.basicCreate('auth/invite', credentials);
  }

  resetPasswordFromAdmin(email: any, password: any) {
    const data = { 'Email': email, 'Password': password };
    return this.baseApi.basicCreate('auth/resetPasswordFromAdmin', data);
  }

  verifyEmail(token: any) {
    const data = { 'token': token, };
    return this.baseApi.basicCreate('auth/verify-email', data);
  }

  verifyEmailAndPassword(token: any, email: any, newPassword: any) {
    const data = { 'token': token, 'email': email, 'password': newPassword };
    return this.baseApi.basicCreate('auth/verify-email-and-password', data);
  }



  /* GoogleAuth */
  googleAuthenticate(googleUser: any) {
    const endpoint = 'auth/googleAuthenticate';
    const promise = new Promise((resolve) => {
      this.baseApi.basicCreate(endpoint, googleUser).then(data => {
        resolve(data);
      });
    });
    return promise;
  }

  googleLogin(googleUser: GoogleUserRequest): Observable<UserTokenResponse> {
    return this.httpClient
      .post<UserTokenResponse>(`${this.carestartApiUri}/api/auth/googleauthenticate`, googleUser)
      .pipe(map(profile => {
        this.setAuth(profile);
        return profile;
      }))
  }

  carestartEmailLogin(email: any, password: any) {
    return this.signInWithEmail(email, password).then(data => {
      console.log('token', data);
      return data;
    });
  }

  setAuth(user: UserTokenResponse) {
    console.log('usr', user);
    if (user == null) return false;

    this.jwtService.saveUser(user);
    //this.settingsService.setUser(user);

    const jwtToken = user.jwtToken;
    if (jwtToken == null) return null;

    const b = window.atob(jwtToken.split('.')[1])
    const bArray = JSON.parse(b);

    this.currentUser.set(bArray);
  }

  accountExists(email: any) {
    const endPoint = `auth/accountExists/${email}`;
    return this.baseApi.basicGet(endPoint);
  }

  logout() {
    // for own
    this.purgeAuth();

    // for MSAL
    //this.authService.logout();
  }

  purgeAuth() {
    this.jwtService.destroyUser();
    this.settingsService.setOrganisation(null)
    this.settingsService.setUser(null)
    this.currentUser.set(null);
    this.router.navigate(['/login']);

    let user = undefined;
    const sub = this.socialAuthService.authState.pipe(take(1)).subscribe(value => user = value);

    if (user) { this.socialAuthService.signOut(); }
    sub.unsubscribe();
  }

  resetPassword(email: any) {
    const endpoint = 'auth/forgot-password';
    const data = { 'email': email };
    
    return this.baseApi.basicCreate(endpoint, data);
  }

  confirmResetPassword(token: string, password: string, confirmPassword: string) {
    const endpoint = 'auth/reset-password';
    const data = { 'token': token, 'password': password, 'confirmPassword': confirmPassword };
    
    return this.baseApi.basicCreate(endpoint, data);
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}