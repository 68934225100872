import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';
import { SettingsService } from '../settings/settings.service';

const ENDPOINT = 'priceGuide';
@Injectable({
  providedIn: 'root'
})
export class PriceGuideService {

  constructor(private baseApi: CarestartApiService, private settingsService: SettingsService) { }

  // api functions

  async get() {
    let data;

    const latestUpdate: any = await this.getLatestUpdate();
    const cachedLatestUpdate = localStorage.getItem('priceGuides-latestUpdate');
    const isLatest = latestUpdate === cachedLatestUpdate;
    
    const cachedData = localStorage.getItem('priceGuides');

    if (cachedData && isLatest) {
      console.log('------> already have latest <------')
      // Parse the cached data back to its original format
      data = JSON.parse(cachedData);
      //console.log('json pare data', data)
      if (!data || data[0] == null) { // in case we accidentally save the promise
        console.log('------> was a promise <------', data)
        this.getFreshPriceGuideData(latestUpdate)
      }
    } else {
      console.log('Fetch data from the API ******````````;@@@@@@@@@@@')
      // Fetch data from the API
      this.getFreshPriceGuideData(latestUpdate)
    }

    //console.log('data', data)
    return data;
  }

  async getFreshPriceGuideData(latestUpdate) {
    let data = await this.baseApi.basicGet(ENDPOINT);
    // console.log('getFreshPriceGuideData data', data);
    // console.log('getFreshPriceGuideData latestUpdate', latestUpdate);

    // Store the fetched data in localStorage
    localStorage.setItem('priceGuides', JSON.stringify(data));

    // Update the latest update timestamp in localStorage
    localStorage.setItem('priceGuides-latestUpdate', latestUpdate);

    return data;
  }

  async getLatestUpdate() {
    const endpoint = `${ENDPOINT}/latestUpdate`;
    let data = await this.baseApi.basicGet(endpoint);
    return data;
  }

  getById(id: any) {
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getByItemCode(id: string) {
    const endpoint = `${ENDPOINT}/itemByCode/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getObs() {
    return this.baseApi.getObs(ENDPOINT);
  }

  updatePriceGuide(data: any) {
    const endpoint = `${ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  deletePriceGuide(id: any) {
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  createPriceGuide(data: any) {
    return this.baseApi.basicCreate(ENDPOINT, data);
  }

  patchPriceGuide(provider: any) {
    const data: any = this.baseApi.createPatchObject(provider);
    return this.baseApi.updatePartial(ENDPOINT, provider.id, data);
  }

  // memory functions

  async load() {
    const priceGuides = await this.checkPriceGuideSettings();
    //const priceGuides = this.settingsService.getPriceGuidesSetting(null);
    //console.log('finished checkprices and got priceGuides', priceGuides);
    return priceGuides;
  }

  private async checkPriceGuideSettings() {
    const priceGuides = this.settingsService.getPriceGuidesSetting(null);
    console.log('priceGuides from settings', priceGuides);
    if (priceGuides === null) {
        console.log('get price guides from db because not in memory yet');
       // get price guides and save to settings
        const data = await this.get();
        //console.log('data', data);
        this.settingsService.setPriceGuides(data);    
        //console.log('check updated price guide settings', this.settingsService.getPriceGuidesSetting(null));

        return data;   
    } else {
      console.log('price guides was not null, return data from memory', this.settingsService.getPriceGuidesSetting(null));
      return priceGuides;
    }
  }
}
