import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const EMPLOYEE_ENDPOINT = 'Employee';
const EMPLOYEE_COMPTENCY_ENDPOINT = 'EmployeeCompetency';
const EMPLOYEE_TEAM_ENDPOINT = 'EmployeeTeam';
const AWARD_SECTION_ENDPOINT = 'AwardSection';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(private baseApi: CarestartApiService) { }


  getEmployee(id: any) {
    const endpoint = `${EMPLOYEE_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getEmployees(pageSize: any = 10000, page: any = 1, activeStatus: any = 'Active', searchWords: any = "", employeeType: any = 'all') {
    const endpoint = `${EMPLOYEE_ENDPOINT}?activeStatus=${activeStatus}&employeeType=${employeeType}&searchWord=${searchWords}&Limit=${pageSize}&Page=${page}`;
    return this.baseApi.basicGet(endpoint);
  }

  // used for dropdownlists etc
  getIds(isActive: any = true) {
    const endpoint = `${EMPLOYEE_ENDPOINT}/ids?isActive=${isActive}`;
    return this.baseApi.getObs(endpoint);
  }

  getIdsObs(isActive: any = true) {
    const endpoint = `${EMPLOYEE_ENDPOINT}/ids?isActive=${isActive}`;
    return this.baseApi.getObs(endpoint);
  }

  getXeroIds() {
    let endpoint = `${EMPLOYEE_ENDPOINT}/xeroIds`;
    return this.baseApi.basicGet(endpoint);
  }

  getEmployeesWithLoginAccessActive(active: true) {
    const endpoint = `${EMPLOYEE_ENDPOINT}?type=` + active;
    return this.baseApi.basicGet(endpoint);
  }

  getEmployeeSummary() {
    const endpoint = `${EMPLOYEE_ENDPOINT}/summary`;
    return this.baseApi.basicGet(endpoint);
  }

  createEmployee(data: any) {
    delete data.id; // make sure there is no id set as null, since we are supposed to be creating a new one
    return this.baseApi.basicCreate(EMPLOYEE_ENDPOINT, data);
  }

  patchEmployee(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(EMPLOYEE_ENDPOINT, payload.id, data);
  }

  /** Competencies */
  getEmployeeCompetencies() {
    return this.baseApi.basicGet(EMPLOYEE_COMPTENCY_ENDPOINT);
  }

  getEmployeeCompetenciesByEmployee(id: any) {
    const endpoint = `${EMPLOYEE_COMPTENCY_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  updateCompetency(payload: any): any {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(EMPLOYEE_COMPTENCY_ENDPOINT, payload.id, data);
  }

  createCompetency(payload: any) {
    delete payload.id; // make sure there is no id set as null, since we are supposed to be creating a new one
    return this.baseApi.basicCreate(EMPLOYEE_COMPTENCY_ENDPOINT, payload);
  }

  deleteCompetency(id: any) {
    const endpoint = `${EMPLOYEE_COMPTENCY_ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  /** Teams */
  getEmployeeTeams(id: any) {
    const endpoint = `${EMPLOYEE_TEAM_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  updateEmployeeTeam(id: any, payload: any): any {
    const endpoint = `${EMPLOYEE_TEAM_ENDPOINT}/${id}`;
    return this.baseApi.update(endpoint, payload);
  }

  createEmployeeTeam(employeeId: any, teamId: any) {
    const payload = {
      employeeId: employeeId,
      teamId: teamId
    }
    return this.baseApi.basicCreate(`${EMPLOYEE_TEAM_ENDPOINT}`, payload);
  }

  deleteEmployeeTeam(employeeId: any, teamId: any) {
    const endpoint = `${EMPLOYEE_TEAM_ENDPOINT}/${employeeId}/${teamId}`;
    return this.baseApi.delete(endpoint);
  }

  /** Award Line Items */
  updateAwardLineItem(payload: any): any {
    const endpoint = `${EMPLOYEE_ENDPOINT}/${AWARD_SECTION_ENDPOINT}`;
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(endpoint, payload.id, data);
  }

  createAwardLineItem(payload: any) {
    delete payload.id; // make sure there is no id set as null, since we are supposed to be creating a new one
    return this.baseApi.basicCreate(`${EMPLOYEE_ENDPOINT}/${AWARD_SECTION_ENDPOINT}`, payload);
  }

  deleteAwardLineItem(employeeId: any, awardLineItemId: any) {
    const endpoint = `${EMPLOYEE_ENDPOINT}/AwardSection/${employeeId}/${awardLineItemId}`;
    return this.baseApi.delete(endpoint);
  }
}
