<div class="card pdf-sidebar shadow" id="pdfSidebar">
  <div id="invoice-preview" class="col-lg-12 preview">
    <div class="card">
      <ng-container *ngIf="!isLoading">
        <div class="card-header d-flex justify-content-center">
          <button type="button" class="btn btn-gray ms-1" (click)="movePDFLocation('left')" id="movleft"><em
              class="fas fa-arrow-left"></em> Move to Left</button>
          <button type="button" class="btn btn-gray ms-1" (click)="movePDFLocation('center')"><em
              class="fas fa-expand"></em>
            Center</button>
          <button type="button" class="btn btn-gray ms-1" (click)="movePDFLocation('right')" id="movright"><em
              class="fas fa-arrow-right"></em> Move to Right</button>
          <button type="button" class="btn btn-gray ms-1" (click)="closePDFView()" id="btn-closePdf"><em
              class="fa fa-fw fa-times"></em> Close</button>
        </div>
      </ng-container>
      <!-- <loading-spinner *ngIf="isLoading === true"></loading-spinner> -->
      <div class="card-body p-0 m-0 animated fadeInUp show" style="height: 800px"
        [style.display]="isLoading || !pdfPreview ? 'none' : 'block'">
        <ng2-pdfjs-viewer #pdfViewerOnDemand [zoom]="57" cursor="HAND"></ng2-pdfjs-viewer>
      </div>
      <div [style.display]="isLoading || imagePreview ? 'block' : 'none'">
        <div class="card-body p-0 m-0">
          <img id="image" class="preview animated fadeInUp show" />
        </div>
      </div>
    </div>
  </div>
</div>