import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const ENDPOINT = 'course';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
 
  constructor(private baseApi: CarestartApiService) { }
    
    get() {
      let endpoint = `${ENDPOINT}`;
      return this.baseApi.basicGet(endpoint);
    }

    getById(courseId: string) {
      let endpoint = `${ENDPOINT}/${courseId}`;
      return this.baseApi.basicGet(endpoint);
    }

    // get(pageSize: any = 100, page: any = 1) {
    //   let endpoint = `${ENDPOINT}?Limit=${pageSize}&Page=${page}`;
    //   return this.baseApi.basicGet(endpoint);
    // }

    // getByClient(clientId: string) {
    //   let endpoint = `${ENDPOINT}/client/${clientId}`;
    //   return this.baseApi.basicGet(endpoint);
    // }

    create(data: any) {
      delete data.id;
      return this.baseApi.basicCreate(ENDPOINT, data);
    }

    update(payload: any) {
      const endpoint = `${ENDPOINT}/${payload.id}`;
      return this.baseApi.update(endpoint, payload)
    }

    patch(data: any) {
      const patchData: any = this.baseApi.createPatchObject(data);
      return this.baseApi.updatePartial(ENDPOINT, data.id, patchData);
    }
  
    delete(id: any) {
      const endpoint = `${ENDPOINT}/${id}`;
      return this.baseApi.delete(endpoint);
    }
}
