<div *ngIf="!tabsOn">

    <h3 class="text-center text-thin mt-2 mb-4">Page Settings</h3>

    <div class="modal-body">
       
        <div class="row mb-3">

            <div class="col-md-6">
                <h4>Allowed Modules</h4>
                <!-- <h4 class="text-thin">Required Modules</h4> -->
                <div *ngIf="app.pageRequiredModules === null">
                    All modules have access
                </div>
                <div *ngFor="let module of app.modules; last as isLast">
                    <div [class.bg-success]="module.assigned" class="badge badge-sm bg-primary me-2">
                        {{module.code}}
                    </div>
                </div>
        
                <!-- <h4 class="text-thin mt-2">Your Organisation</h4>
                <div *ngFor="let module of organisation.modules">
                {{module.code}} - {{module.name}}
                </div>                 -->
            </div>


            <div class="col-md-6">
                <h4>Allowed Roles</h4>
                <!-- <h4 class="text-thin">Allowed</h4> -->
                <div *ngIf="app.pageRequiredRoles === null">
                    No Roles Required
                </div>
                <div *ngFor="let role of app.pageRequiredRoles; last as isLast">
                    {{role}}<span *ngIf="!isLast">, </span>
                </div>                                  
            </div>

        </div>

        <hr>

        
        
        <div class="mb-3">
            <h4>Users With Access</h4>

            <!-- <div *ngFor="let role of allUsers?.roles">
                {{role.name}}
            </div>   -->

        </div>


        <div class="mt-4 mb-3">
            <h4>Your Roles</h4>

            <div *ngFor="let role of user?.roles">
                {{role.name}}
            </div>     
        </div>

       
    </div>

</div>

<tabset *ngIf="tabsOn" [justified]="true" #offsidebarpaneltabs>
    <tab>
        <ng-template tabHeading>
            <em class="icon-envelope-letter fa-lg"></em>
        </ng-template>
        
        <h3 class="text-center  mt-4">Compose Internal Message</h3>

        <!-- <div class="card card-default"> -->
            <div class="modal-body">
                <form [formGroup]="mailForm" role="form-horizontal" method="post">
                    <div class="input-group mb-2">
                        <div class="input-group-prepend text-sm">
                            <span class="input-group-text">To</span>
                        </div>
                        <!-- <ng-select  class="dd me-1"
                            [items]="users" bindLabel="user.fullName" formControlName="recipientUserId" bindValue="user.id" placeholder="Select User">
                        </ng-select> -->

                        <select class="form-select form-select-sm" formControlName="recipientUserId">
                            <option value="">Select User ...</option>
                            <option *ngFor="let access of users" value="{{access.userId}}">
                              {{access.user?.fullName}}
                            </option>
                          </select>

                        <!-- <input type="text" placeholder="To" class="form-control halfling"
                         formControlName="recipientUserId"> -->
                       
                    </div>
                    <div class="input-group mb-2" *ngIf="showCC">
                        <div class="input-group-prepend text-sm">
                            <span class="input-group-text">CC</span>
                        </div>
                        <input class="form-control" type="text" />
                    </div>
                    <div class="input-group mb-2" *ngIf="showBCC">
                        <div class="input-group-prepend text-sm">
                            <span class="input-group-text">BCC</span>
                        </div>
                        <input class="form-control" type="text" />
                    </div>
                    <div class="input-group mb-2" *ngIf="showJobNumber">
                        <div class="input-group-prepend text-sm">
                            <span class="input-group-text">Job #</span>
                        </div>
                        <input type="text" placeholder="Job Number" class="form-control halfling"
                         formControlName="jobNumber">
                        <!-- <input class="form-control" type="text" value="{{jobNumber}}" /> -->
                    </div>
                    <div class="input-group mb-2" *ngIf="showClientName">
                        <div class="input-group-prepend text-sm">
                            <span class="input-group-text">Client</span>
                        </div>
                        <!-- <ng-select  class="dd me-1"
                            [items]="clients" bindLabel="fullName" formControlName="clientId" bindValue="id" placeholder="Select Client">
                        </ng-select> -->

                        <select class="form-select form-select-sm" formControlName="clientId">
                            <option value="">Select Client ...</option>
                            <option *ngFor="let client of clients" [value]="client.id">
                              {{client.fullName}}
                            </option>
                          </select>

                    </div>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend text-sm">
                            <span class="input-group-text">Subject</span>
                        </div>
                        <input type="text" placeholder="Title" class="form-control halfling"
                         formControlName="title">

                        <!-- <input class="form-control" type="text" value="{{title}}" /> -->
                    </div>
                    <div class="mb-editor">
                        <!-- START action buttons-->
                        <div class="btn-group btn-group-sm mb-3">
                            <div></div>
                            <div class="ms-auto">
                                <button class="btn btn-gray btn-xs" type="button" [ngClass]="{'active':showCC}" (click)="showCC = !showCC" href="#">CC</button>
                                <button class="btn btn-gray btn-xs" type="button" [ngClass]="{'active':showBCC}" (click)="showBCC = !showBCC" href="#">BCC</button>
                            
                                <button class="ms-2 btn btn-gray btn-xs" type="button" [ngClass]="{'active':showJobNumber}" (click)="showJobNumber = !showJobNumber" href="#">Job</button>
                                <button class="btn btn-gray btn-xs" type="button" [ngClass]="{'active':showClientName}" (click)="showClientName = !showClientName" href="#">Client</button>
                            
                            </div>
                        </div>
                        <!-- END action buttons  -->
                        <!-- <div id="summernote" class="summernote">
                            <span>Rich text mail here..</span>
                        </div> -->
                        <div class="mb-3">
                            <textarea class="form-control" rows="5" placeholder="Fill in message" formControlName="details">
                           
                            </textarea>
                        </div>
                        <div class="nav">
                            <div class="btn-group btn-group-sm mb-3">
                                <div></div>
                                <div class="ms-auto">
                                    <button class="btn btn-primary btn-xs me-1" type="button" [ngClass]="{'active':template[0]?.selected}" (click)="addTemplateText(0)" href="#">Check Charges</button>
                                    <button class="btn btn-primary btn-xs me-1" type="button" [ngClass]="{'active':template[1]?.selected}" (click)="addTemplateText(1)" href="#">Chacek Time Entries</button>                            
                                </div>
                            </div>
                        </div>
                        <div class="mb">
                            <button class="btn btn-sm" type="button" (click)="cancel()">Cancel</button>
                            <button class="btn btn-primary btn-sm  float-end" type="button" (click)="SaveEmail()">Send</button>
                        </div>
                    </div>
                </form>
            </div>
        <!-- </div> -->

    </tab>
    <tab>
        <ng-template tabHeading>
            <em class="icon-user fa-lg"></em>
        </ng-template>
        <h3 class="text-center text-thin mt-4">Admin Contacts</h3>

        <div class="modal-body">
            <table>
                <tr *ngFor="let item of users">
                    <td>
                        <div class="col" >
                            <div *ngIf="!item.user || !item.user.fullName">No Name</div>
                            {{item?.user?.fullName}}
                            <p><b>{{item?.user?.email}}</b></p>
                        </div>
                    </td>
                    <td>
                        {{item.organisationRole.name}}
                        
                    </td>
                    <td>
                        <button class="btn btn-sm btn-primary" (click)="addUserToMail(item)">
                            Select
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </tab>

    <tab>
        <ng-template tabHeading>
            <em class="icon-settings fa-lg"></em>
        </ng-template>
        
        <h3 class="text-center text-thin mt-4">Page Settings</h3>

        <div class="modal-body">
            <!-- <div class="mb-2">Path: {{app.pageName}}</div> -->
            <div class="mb-3">
                <h4>Allowed Modules</h4>
                <!-- <h4 class="text-thin">Required Modules</h4> -->
                <div *ngIf="app.pageRequiredModules === null">
                    All modules have access
                </div>
                <span *ngFor="let module of app.pageRequiredModules; last as isLast">
                    <span [class.bg-success]="module.assigned" class="badge badge-sm bg-primary me-2">
                        {{module.code}}
                    </span>
                </span>
           
                <!-- <h4 class="text-thin mt-2">Your Organisation</h4>
                <div *ngFor="let module of organisation.modules">
                   {{module.code}} - {{module.name}}
                </div>                 -->
            </div>


            <div class="mb-3">
                <h4>Roles</h4>
                <!-- <h4 class="text-thin">Allowed</h4> -->
                <div *ngIf="app.pageRequiredRoles === null">
                    No Roles Required
                </div>
                <div *ngFor="let role of app.pageRequiredRoles; last as isLast">
                    {{role}}<span *ngIf="!isLast">, </span>
                </div>

                <h4 class="text-thin mt-2">Your Roles</h4>
                <div *ngFor="let role of user.roles">
                    {{role.code}} - {{role.name}}
                </div>                
            </div>

           
        </div>

    </tab>
</tabset>
