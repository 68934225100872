import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const ENDPOINT = 'notetemplate';

@Injectable({
  providedIn: 'root'
})
export class NoteTemplateService {

  constructor(private baseApi: CarestartApiService) { }

  create(data: any) {
    return this.baseApi.basicCreate(ENDPOINT, data);
  }

  update(payload: any) {
    const endpoint = `${ENDPOINT}/${payload.id}`;
    return this.baseApi.update(endpoint, payload);
  }

  patch(data: any) {
    const patchData: any = this.baseApi.createPatchObject(data);
    return this.baseApi.updatePartial(ENDPOINT, data.id, patchData);
  }

  delete(id: any) {
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  getAll() { 
    const endpoint = `${ENDPOINT}`;
    return this.baseApi.basicGet(endpoint);
  }

  get(id: any) { 
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }
}
