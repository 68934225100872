import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { NgFor, NgIf, DecimalPipe, KeyValuePipe, DatePipe } from '@angular/common';
import { SharedModule } from './../../shared/shared.module'
import { ClinicNoteService } from '@core/services/clinic-note.service';
import {  filter, get, orderBy } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { BusinessService } from 'src/app/services/business.service';
import { SettingsService } from '@core/settings/settings.service';
@Component({
  selector: 'app-billing-notes',
  templateUrl: './billing-notes.component.html',
  styleUrls: ['./billing-notes.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, DecimalPipe, KeyValuePipe, DatePipe, SharedModule]
})
export class BillingNotesComponent implements OnInit {

  @Input() client: any = {};
  @Input() managedType: string;

  clinicNoteService = inject(ClinicNoteService);
  toastrService = inject(ToastrService);
  businessService = inject(BusinessService);
  settingsService = inject(SettingsService);

  notes: any = [];
  currentUser: any = {};

  constructor() { }

  async ngOnInit(): Promise<void> {
    // should load notes for client on change
    this.currentUser = await this.settingsService.getUserSetting(null);
		this.currentUser.isAdmin = get(this.currentUser, 'rolesString', '').includes('Admin');
  }

  ngOnChanges(changes: SimpleChanges) {
    this.notes = [];
    //console.log('ngOnChanges BillingNotesComponent', changes.client)
    if (changes.client) {
      if (this.client?.id != null) {
        console.log('this.client', this.client)
        this.notes = [];
         
        this.loadCOSNotes(this.client.id, this.managedType);
      }
    }
  }

  async loadCOSNotes(clientId: string, managedType: string) {

    this.clinicNoteService.getNotesByClient(clientId).then((data: any) => {
      // temp until the api call allows us to filter by managedType on client notes
        if (managedType) { data = filter(data, d => d.managedType == managedType); }
        console.log('managedType', managedType)
        console.log('data', data);
        this.notes = orderBy(data, a => a.date, 'desc');
    }).catch(() => {
        this.toastrService.error('Error', 'Something went wrong while trying to obtain cos notes list. Please try again');
    }).finally(() => { 
        //this.isLoading = false; 
    });
  }

  

}
