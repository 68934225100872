<div>
    <h4>Notes for {{managedType}} only</h4>
    <table class="table">
        <thead>
            <tr class="table-primary">
                <th>Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Employee</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let note of notes">
                <tr class="table-secondary">
                    <td>
                        <div>{{note.type}}</div>
                        <div>Level {{note.level}}</div>
                    </td>
                    <td class="align-top">                        
                        <div>{{note.noteDate | date}}</div>
                        <!-- <div>{{note.date | date}}</div> -->
                    </td>
                    <td>
                        <div class="d-flex">Req<span class="ms-auto me-3">{{note.timeTaken}}</span></div>
                        <div class="d-flex" *ngIf="currentUser?.isAdmin">App<span class="ms-auto me-3">{{note.approvedHours}}</span></div>
                    </td>
                    <td class="align-top">{{note.employee?.fullName}}</td>
                </tr>
                <tr>
                    <td colspan="4">
                        <div [innerHTML]="note.detail | htmlDisplay"></div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
   
</div>