/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.5.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import './vendor';
import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { createTranslateLoader } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RoutesModule } from './app/routes/routes.module';
import { LayoutModule } from './app/layout/layout.module';
import { CoreModule } from './app/core/core.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { GlobalErrorHandler } from './app/core/errors/global-error-handler';
import { GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { TokenInterceptor } from './app/core/interceptors/token.interceptors';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { JwtService } from './app/services/jwt.service';
import { MailService } from './app/services/mail-service';
import { UtilityService } from './app/services/utility.service';
import { NotificationService } from './app/core/services/notification.service';
import { InternalMailService } from './app/core/services/internal-mail.service';
import { BusinessService } from './app/services/business.service';
import { CarestartApiService } from './app/services/api/carestart-api-base.service';
import { Title, bootstrapApplication } from '@angular/platform-browser';
import { RoleGuardService } from './app/services/auth/role-guard.service';
import { AuthGuardService } from './app/services/auth/auth-guard.service';
import { AuthService } from './app/core/services/auth.service';
import { registerLicense } from '@syncfusion/ej2-base';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

// Registering Syncfusion license key
registerLicense(environment.syncFusionLicenseKey);

Sentry.init({
    dsn: "https://1721ae89db0a494c8c2c9d1a13c56c39@o1303426.ingest.sentry.io/6542394",
    integrations: [
        Sentry.replayIntegration(),
        Sentry.browserTracingIntegration(),
        // Sentry.browserTracingIntegration({
        //     tracingOrigins: ["localhost", "https://dev-carestart.web.app", "https://go.carestart.com.au", "https://carestart.web.app/"],
        //     routingInstrumentation: routingInstrumentation,
        // }),
    ],
  
    beforeSend(event, hint) {
      const error: any = hint.originalException;
      if (error?.name === 'ChunkLoadError') {
        console.log('new version of site/files exists and site needs to refresh');
        if(confirm("Web page needs to reload ok ? ")) {
          window.location.reload();
        }
      }
      // Modify the event here
      return event;
    },
    
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    enabled: environment.production, //  process.env.NODE_ENV !== 'development',
    tracesSampleRate: 1.0,
    //environment: environment.production ? 'production' : 'development',
    release: environment.release
});

if (environment.production) {
    console.log('production mode');
    enableProdMode();
    // platformBrowserDynamic()
    // .bootstrapModule(AppModule)
    // //.then(success => console.log(`Bootstrap success`))
    // .then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); })
    // .catch(err => console.error(err));
} else {
    console.log('dev mode');
   
}


let p = bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(// required for ng2-tag-input and toastr        
        CoreModule, LayoutModule, RoutesModule, SocialLoginModule, [SweetAlert2Module.forRoot()], TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })),
        importProvidersFrom(HttpClientModule),
        importProvidersFrom(HttpClientJsonpModule),
        AuthService,
        AuthGuardService,
        RoleGuardService,
        Title,
        CarestartApiService,
        BusinessService,
        InternalMailService,
        NotificationService,
        UtilityService,
        MailService,
        JwtService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.google.clientId)
                    },
                    // {
                    //     id: FacebookLoginProvider.PROVIDER_ID,
                    //     provider: new FacebookLoginProvider(environment.facebook.clientId)
                    // }
                    {
                        id: MicrosoftLoginProvider.PROVIDER_ID,
                        provider: new MicrosoftLoginProvider(environment.microsoft.clientId)
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
});
p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); })
.catch(err => console.error(err));

