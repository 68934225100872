import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const OUTLET_ENDPOINT = 'outlet';

@Injectable({
  providedIn: 'root'
})
export class OutletService {

  selectedOutlet: any;
  defaultOutlet: any;
  defaultOutletName: any;
  selectedOutletGlobal: any;
  outletObs: any;

  constructor(private baseApi: CarestartApiService) { }

    /* OUTLET */

    getByIdObs(id: any) {
      const endpoint = `${OUTLET_ENDPOINT}/${id}`;
      return this.baseApi.getObs(endpoint);
    }

    getById(id: any) {
      return this.baseApi.basicGet(`${OUTLET_ENDPOINT}/${id}`)
    }

    // used for dropdownlists etc
    getIds(isActive: any = true) {
      const endpoint = `${OUTLET_ENDPOINT}/ids?isActive=${isActive}`;
      return this.baseApi.getObs(endpoint);
    }
  
    getOutletByName(name: any) {
      const endpoint = `${OUTLET_ENDPOINT}/GetByName/${name}`;
      return this.baseApi.basicGet(endpoint);
    }
  
    getOutlets() {
      return this.baseApi.basicGet(OUTLET_ENDPOINT);
          
    }

    getOutletsByClient(clientId: any) {
      const endpoint =  `${OUTLET_ENDPOINT}/client/${clientId}`;
      return this.baseApi.basicGet(endpoint);
    }
  
    createOutlet(data: any) {
      delete data.id;
      return this.baseApi.basicCreate(OUTLET_ENDPOINT, data);
    }
  
    deleteOutlet(outletId: any) {
      const endpoint = `${OUTLET_ENDPOINT}/${outletId}`;
      return this.baseApi.delete(endpoint);
    }
  
    patchOutlet(outlet: any) {
      const data: any = this.baseApi.createPatchObject(outlet);
      return this.baseApi.updatePartial(OUTLET_ENDPOINT, outlet.id, data);
    }

}
