import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MenuService {

    menuItems: Array<any>;
    menuObs: Subject<any> = new Subject<any>();

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        this.menuItems = [];
        if (items) {
            items.forEach((item) => {
                this.menuItems.push(item);
            });
            this.menuObs.next(this.menuItems);
        }
    }

    getMenuObs(): any {
        return this.menuObs.asObservable();
    }

    getMenu() {
        return this.menuItems;
    }
}
