import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';
import { SettingsService } from '../settings/settings.service';

const JOB_TYPE_ENDPOINT = 'JobType';
const WORK_TYPE_ENDPOINT = 'WorkType';
const CLIENT_CATEGORY_TYPE_ENDPOINT = 'ClientCategoryType';

@Injectable({
  providedIn: 'root'
})
export class TypeService {

  constructor(
    private baseApi: CarestartApiService,
    private settingsService: SettingsService,
    ) { }

    /* General Types */
    getTypes(endpoint: any) {
      return this.baseApi.basicGet(endpoint);
    }
  
    createType(endpoint: any, data: any) {
      delete data.id; // make sure there is no id set as null, since we are supposed to be creating a new one
      return this.baseApi.basicCreate(endpoint, data);
    }
  
    patchType(endpoint: any, payload: any) {
      const data: any = this.baseApi.createPatchObject(payload);
      return this.baseApi.updatePartial(endpoint, payload.id, data);
    }
  
    deleteType(endpoint: any, id: any) {
      return this.baseApi.delete(`${endpoint}/${id}`);
    }
  
    /* Job Type */
    getJobTypes() {
      return this.baseApi.basicGet(JOB_TYPE_ENDPOINT);
    }

    getJobTypesObs() {
      return this.baseApi.getObs(JOB_TYPE_ENDPOINT);
    }
  
    createJobType(data: any) {
      delete data.id; // make sure there is no id set as null, since we are supposed to be creating a new one
      return this.baseApi.basicCreate(JOB_TYPE_ENDPOINT, data);
    }
  
    patchJobType(payload: any) {
      const data: any = this.baseApi.createPatchObject(payload);
      return this.baseApi.updatePartial(JOB_TYPE_ENDPOINT, payload.id, data);
    }
  
    deleteJobType(id: any) {
      const endpoint = `${JOB_TYPE_ENDPOINT}/${id}`;
      return this.baseApi.delete(endpoint);
    }
  
    /* Work Type */
    getWorkTypes() {
      return this.baseApi.basicGet(WORK_TYPE_ENDPOINT);
    }
  
    createWorkType(data: any) {
      delete data.id; // make sure there is no id set as null, since we are supposed to be creating a new one
      return this.baseApi.basicCreate(WORK_TYPE_ENDPOINT, data);
    }
  
    patchWorkType(payload: any) {
      const data: any = this.baseApi.createPatchObject(payload);
      return this.baseApi.updatePartial(WORK_TYPE_ENDPOINT, payload.id, data);
    }
  
    deleteWorkType(id: any) {
      const endpoint = `${WORK_TYPE_ENDPOINT}/${id}`;
      return this.baseApi.delete(endpoint);
    }

    /* Client Category Type */
    getClientCategoryTypes() {
      return this.baseApi.basicGet(CLIENT_CATEGORY_TYPE_ENDPOINT);
    }
  
    createClientCategoryType(data: any) {
      delete data.id; // make sure there is no id set as null, since we are supposed to be creating a new one
      return this.baseApi.basicCreate(CLIENT_CATEGORY_TYPE_ENDPOINT, data);
    }
  
    patchClientCategoryType(payload: any) {
      const data: any = this.baseApi.createPatchObject(payload);
      return this.baseApi.updatePartial(CLIENT_CATEGORY_TYPE_ENDPOINT, payload.id, data);
    }
  
    deleteClientCategoryType(id: any) {
      const endpoint = `${CLIENT_CATEGORY_TYPE_ENDPOINT}/${id}`;
      return this.baseApi.delete(endpoint);
    }
  



    // memory functions

    async load(type: any) {   // eg 'jobType'

      // need to do this for each type we are looking for
      await this.checkTypeSettings(type);
      const types = this.settingsService.getTypeSetting(type);
     // console.log('finished check types and got types', types);
      return types;
    }

    private async checkTypeSettings(type: any) {
      if (!this.settingsService.getTypeSetting(type) || this.settingsService.getTypeSetting(type) === null) {
        console.log('get job guides from db because not in memory yet', type);
        // get price guides and save to settings
          const data: any = await this.getTypes(type);
          console.log('returned types', data);
          this.settingsService.setTypeSetting(type, data);       
      } else {
        console.log('job types was not null, return data from memory', this.settingsService.getTypeSetting(type));
      }
    }


}
