import { Injectable } from '@angular/core';
import { filter, map, sortBy } from 'lodash-es';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const ORGANISATION_ROLE_ENDPOINT = 'organisationRole';
const SITE_ROLE_ENDPOINT = 'siteRole';
const ORGANISATION_ACCESS_ENDPOINT = 'organisationAccess';
const MODULE_ENDPOINT = 'module';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private baseApi: CarestartApiService) { }

  /* SiteRole */
  getAllPossibleSiteRoles() {
    return this.baseApi.basicGet(ORGANISATION_ROLE_ENDPOINT);
  }

  createSiteRole(data: any) {
    return this.baseApi.basicCreate(SITE_ROLE_ENDPOINT, data);
  }


  /* AccessRole */
  getAllOrganisationsAccessByUser(id: any) {
    const endpoint = `${ORGANISATION_ACCESS_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getAllOrganisationsAccesses() {
    return this.baseApi.basicGet(ORGANISATION_ACCESS_ENDPOINT);
  }

  getAllOrganisationsAccessesObs() {
    return this.baseApi.getObs(ORGANISATION_ACCESS_ENDPOINT);
  }

  createAccessRoleForUser(data: any) {
    return this.baseApi.basicCreate(ORGANISATION_ACCESS_ENDPOINT, data);
  }

  updateAccessRoleForUser(data: any) {
    return this.baseApi.update(ORGANISATION_ACCESS_ENDPOINT, data);
  }

  removeAccessRoleForUser(userId: any, roleId: any) {
    return this.baseApi.delete(`${ORGANISATION_ACCESS_ENDPOINT}/${userId}/${roleId}`)
  }

  removeAccessAllRoleForUser(userId: any) {
    return this.baseApi.delete(`${ORGANISATION_ACCESS_ENDPOINT}/all/${userId}`)
  }

  /* Modules */
  getAllModules() {
    return this.baseApi.basicGet(MODULE_ENDPOINT);
  }

  createModule(data: any) {
    return this.baseApi.basicCreate(MODULE_ENDPOINT, data);
  }

  setUserRoles(id: any, roles: any) {
    return new Promise(null); //TODO UPDATE User Roles
  }

  // getAllOrganisationsAccessesGrouped() {
  //   const self = this;
  //   this.getAllOrganisationsAccessesObs().subscribe(function (data: any) {
  //     const users = _.map(_.groupBy(data, d => d.userId), group => {
  //         return _.first(group);
  //     });
  //     _.map(users, function (access) {
  //         if (access.roles) {
  //             access.roles = _.map(Object.keys(access.roles), function (roleKey) {
  //                 if (access.roles[roleKey] === true) {
  //                     return roleKey;
  //                 }
  //             });
  //         } else {
  //             access.roles = [];
  //         }
  //     });
  //   });
  // }

  async getAllSiteRolesSorted(organisation: any) {
   
      let roles: any = await this.getAllPossibleSiteRoles();
      // only display roles for parents that match org modules
      const orgModuleCodes = map(organisation.modules, 'code');
      roles = filter(roles, r => orgModuleCodes.includes(r.parent) || r.parent === null);
      roles = sortBy(roles, ['parent', 'name']);
      // let rolesGroup = roles.reduce((r, { parent }) => {
      //   if (!r.some(o => o.parent === parent)) {
      //     r.push({ parent, groupItem: roles.filter(v => v.parent === parent) });
      //   }
      //   return r;
      // }, []);

      return roles;    
  }

}