import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EditTodoComponent } from '@components/modal/edit-todo/edit-todo.component';
import { TodoService } from '@core/services/todo.service';
import { SettingsService } from '@core/settings/settings.service';
import { SharedModule } from '@shared/shared.module';
import { filter, get, startCase } from 'lodash-es';
import { Observable, OperatorFunction, debounceTime, map } from 'rxjs';
import { BusinessService } from 'src/app/services/business.service';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { InternalMailService } from '@core/services/internal-mail.service';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, SharedModule, TitleCasePipe, EditTodoComponent, NgbTypeaheadModule]
})
export class TodoComponent implements OnInit {
  @ViewChild('editTodoComponent') private editTodoComponent: EditTodoComponent;
  allTodos: any = [];
  categories: any = [];
  currentUser: any
  searchInput: any;
  selectedStatuses: any[];
  selectedCategory: string = 'All';
  selectedItem: any = {};
  showAddEditComponent: boolean = false;
  todos: any;

  statusOptions: any[] = [ 'idea', 'not started', 'in progress', 'done', 'cancelled'];

  statusOptionsColors = {
    "idea": "#007bff",
    "not started": "#ffc107",
    "in progress": "#17a2b8",
    "done": "#28a745",
    "cancelled": "#dc3545"
  }

  search: OperatorFunction<string, readonly { description }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) =>
        term === ''
          ? []
          : this.allTodos.filter((v) => v.description.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );

  formatter = (x: { description: string }) => x.description;

  constructor(private router: Router, 
    private settingsService: SettingsService,
    private todoService: TodoService,
    private businessService: BusinessService,
    private internalMailService: InternalMailService) { }

  ngOnInit() {
    this.init();
    this.load();
    this.selectedStatuses = this.statusOptions.filter(s => s !== 'done');
    this.applyStatusFilter();
  }

  init() {
    let user = this.settingsService.getUserSettings()
    this.currentUser = user;
    this.currentUser.isAdmin = get(this.currentUser, 'rolesString', '').includes('Admin');
    this.currentUser.isOfficeManager = get(this.currentUser, 'rolesString', '').includes('Office Manager');

    this.internalMailService.tasksUpdate.subscribe(() => {
      this.getTodoItems();
    })
  }

  load() {
    this.getTodoItems();
    this.categories = this.businessService.getTodoCategories();
    this.categories.push("All");
  }

  async getTodoItems() {
    var employeeId = this.currentUser.employeeId;
    if (employeeId) {
      this.todoService.getByEmployeeId(employeeId).then((res: any) => {
        this.todos = res;
        this.allTodos = res;
        this.applyCategoryFilter(this.selectedCategory);
      });
    } else {
      console.log('no employeeId found')
    }
  }

  applyCategoryFilter(category: string) {
    this.selectedCategory = category;
    if (category === 'All') {
      this.todos = this.allTodos;
      this.applyStatusFilter();
      //this.searchTodos();
      return;
    }
    this.todos = filter(this.allTodos, td => td.category.toLowerCase() === category.toLowerCase());
    this.applyStatusFilter();
    const desc = this.searchInput?.description || this.searchInput || '';
    if (desc) {
      this.searchTodos(this.todos);
    }
  }

  applyStatusFilter() {
    if (!this.selectedStatuses.length) {
      this.todos = this.allTodos; 
    } else {
      this.todos = this.allTodos.filter(todo =>
        (todo.status && this.selectedStatuses.some(status =>
          status.toLowerCase() === todo.status.toLowerCase() || todo.status.toLowerCase() === 'pending'
        )) || !todo.status
      );      
    }
    this.searchTodos(this.todos);
  }

  onSearchItemSelected(item: any) {
    const desc = item.item.description;
    this.todos = filter(this.allTodos, td => td.description === desc);
  }

  searchTodos(source: any = this.allTodos) {
    const desc = this.searchInput?.description || this.searchInput || '';
    this.todos = source.filter(td => td.description.toLowerCase().includes(desc.toLowerCase()));

    if (this.selectedCategory && this.selectedCategory !== 'All') {
      this.todos = filter(this.todos, td => td.category.toLowerCase() === this.selectedCategory.toLowerCase());
    }
  }

  toggleAddEditComponent(item: any = null) {
    this.selectedItem = item;
    this.showAddEditComponent = !this.showAddEditComponent;
  }

  openTodoEditModal(item: any = null) {
    this.selectedItem = item;
    this.editTodoComponent.open();
  }

  confirmChangeToCompleted(item): void {
    let selectedStatus = null;

    const statusOptionsHTML = Object.entries(this.statusOptionsColors).map(([label, color]) => `
    <label class="status-option" style="display: flex; align-items: center; margin-bottom: 10px; cursor: pointer;">
        <input type="radio" name="status" value="${label.toLowerCase()}">
        <div style="width: 20px; height: 20px; background-color: ${color}; margin-left: 10px; margin-right: 5px;"></div>
        ${startCase(label)}
    </label>`).join('');

    Swal.fire({
      title: 'Change Status',
      html: `
      <div style="width: 400px;">
          <p>You are about to change the status of this todo.</p>
          <p>Please select the new status:</p>
          <div id="status-options">
              ${statusOptionsHTML}
          </div>
      </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      preConfirm: () => {
        if (!selectedStatus) {
          Swal.showValidationMessage('Please select a status');
        }
      },
      customClass: {
        popup: 'custom-popup-class',
        confirmButton: 'custom-confirm-button-class',
        cancelButton: 'custom-cancel-button-class'
      },
      didOpen: () => {
        Swal.getConfirmButton().disabled = true;
        const radios = document.querySelectorAll('input[name="status"]');
        radios.forEach(radio => {
          radio.addEventListener('change', (event) => {
            selectedStatus = (event.target as HTMLInputElement).value;
            Swal.getConfirmButton().disabled = false;
          });
        });
      }
    }).then((result) => {
      if (result.isConfirmed && selectedStatus) {
        console.log(item, selectedStatus);
        this.changeStatus(item, selectedStatus);
      }
    });
  }

  changeStatus(item, status) {
    item.status = status;
    this.todoService.updateStatus(item.id, item.status).then((res: any) => {
      this.getTodoItems();
    });
  }

  gotoCourse(courseId) {
    this.router.navigate(['/courses/view/' + courseId]);
  }

  gotoJob(jobId) {
      // make sure job route can accept the id instead of johNumber    
      this.router.navigate(['/rosters/detail/' + jobId]);        
  }

  getStatusLabelColor(status: any): string {
    if (typeof status === 'string' || status instanceof String) {
      return this.statusOptionsColors[status.toLowerCase()] || 'black';
    }
    return 'black';
  }
}
