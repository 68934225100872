import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { InternalMailService } from '@core/services/internal-mail.service';

import find from 'lodash-es/find';
import { CloneJobComponent } from '@components/modal/jobs/clone-job/clone-job.component';
import { EditJobComponent } from '@components/modal/jobs/edit-job/edit-job.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EditClinicNoteComponent } from '../edit-clinic-note/edit-clinic-note.component';

@Component({
    selector: 'modal-container',
    templateUrl: './modal-container.component.html',
    styleUrls: ['./modal-container.component.scss'],
    standalone: true,
    imports: [ModalModule, EditJobComponent, CloneJobComponent, EditClinicNoteComponent]
})
export class ModalContainerComponent implements OnInit {
  //@ViewChild('timeAwayModal', { static: true }) timeAwayModal;
  @ViewChild('jobModal', { static: true }) jobModal;
  @ViewChild('cloneJobModal', { static: true }) cloneJobModal;
  @ViewChild('cloneJobComponent', { static: true }) cloneJobComponent;
  @ViewChild('editJobComponent', { static: true }) editJobComponent;
  @ViewChild('clinicNoteModal') private clinicNoteModal!: EditClinicNoteComponent;
  //@ViewChild('timeAwayComponent', { static: true }) timeAwayComponent;
  @Input() modalId = null;
  @Input() trigger = null;
  modalCollection: any = [];
  modalCode: any;
  isNew: any;
  showDetail: boolean = false;
  model: any;
  callback: any;
  genericFlag: any;
  currentOrganisation: any;
  scheduleDate: any;
  managedType: string;

  id: any;
  constructor(
    private internalMailService: InternalMailService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modalId && changes.modalId.currentValue) {
      this.id = changes.modalId.currentValue;
      if (this.id) {
        const ctrl = find(this.modalCollection, mc => mc.id === this.id).control;
        ctrl.show();
      }
    }
  }
  ngOnInit(): void {
    let collection = [
      // {id: 'timeAwayModal', control: this.timeAwayModal, component: this.timeAwayComponent},
      { id: 'rosterModal', control: this.jobModal, component: this.editJobComponent },
      { id: 'j', control: this.jobModal, component: this.editJobComponent },
      { id: 'ce', control: this.jobModal, component: this.editJobComponent },
      { id: 'pd', control: this.jobModal, component: this.editJobComponent },
      { id: 'ta', control: this.jobModal, component: this.editJobComponent },
      { id: 'lc', control: this.jobModal, component: this.editJobComponent },
      { id: 's', control: this.jobModal, component: this.editJobComponent },
      { id: 'im', control: this.jobModal, component: this.editJobComponent },
      { id: 'cm', control: this.jobModal, component: this.editJobComponent },
      { id: 're', control: this.jobModal, component: this.editJobComponent },
      { id: 'cloneJob', control: this.cloneJobModal, component: this.cloneJobComponent }
      
    ];
    this.modalCollection = collection;
  }

  ngAfterViewInit() {
    const clinicNoteObj: any = { id: 'note', component: this.clinicNoteModal };
    if (!this.modalCollection.some(item => item.id === clinicNoteObj.id)) {
      this.modalCollection.push(clinicNoteObj);
    }
    this.internalMailService.modalDetail.subscribe((data: any) => {
      //console.log('internalMailService data', data);
      this.modalCode = data.type;
      this.isNew = data.isNew;
      this.model = data.model;
      this.showDetail = data.showDetail;
      this.callback = data.callback;
      this.managedType = data.managedType;
      this.genericFlag = data.genericFlag;
      this.scheduleDate = data.scheduleDate;
      this.currentOrganisation = data.currentOrganisation

      const item = find(this.modalCollection, mc => mc.id === this.modalCode);
      if (item.control) {
        item.control.show();
      } else {
        item.component.open();
      }
    })
  }

  onModalHide() {
    const component = find(this.modalCollection, mc => mc.id === this.modalCode).component;
    if (component.onContainerHideEvent) {
      component.onContainerHideEvent();
    }
  }
}
