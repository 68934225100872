import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const ENDPOINT = 'ClinicNote';

@Injectable({
  providedIn: 'root'
})
export class ClinicNoteService {

  constructor(private baseApi: CarestartApiService) { }

  /* Note */

  getNote(id: any) {
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getNotesByClient(clientId: any, managedType: any = "", isActive: any = "") {
    const endpoint = `${ENDPOINT}/client/${clientId}?managedType=${managedType}&isActive=${isActive}`;
    return this.baseApi.basicGet(endpoint);
  }

  getNotesByProvider(providerId: any, managedType: string = "") {
    const endpoint = `${ENDPOINT}/provider/${providerId}?managedType=${managedType}`;
    return this.baseApi.basicGet(endpoint);
  }

  getNotesByDate(startDate: any, endDate: any, managedType: string = ""): Promise<any> {
    const endpoint = `${ENDPOINT}/dates?startDate=${startDate}&endDate=${endDate}&managedType=${managedType}`;
    const val: any = this.baseApi.basicGet(endpoint);
    //console.log('getNotesByDate', val);
    return val;
  }

  getNotesActive(managedType: string = "") {
    const endpoint = `${ENDPOINT}/active?managedType=${managedType}`;
    return this.baseApi.basicGet(endpoint);
  }

  getClientsLatestNote(managedType: string = "") {
    const endpoint = `${ENDPOINT}/clients/latestNote?managedType=${managedType}`;
    return this.baseApi.basicGet(endpoint);
  }

  getNoteHours(startDate: any, endDate: any, managedType: string = "") {
    const endpoint = `${ENDPOINT}/hours?startDate=${startDate}&endDate=${endDate}&managedType=${managedType}`;
    return this.baseApi.basicGet(endpoint);
  }

  createNote(data: any) {
    return this.baseApi.basicCreate(ENDPOINT, data);
  }

  // updateNote(data: any) {
  //   const endpoint = `${ENDPOINT}/${data.id}`;
  //   return this.baseApi.update(endpoint, data);
  // }

  patchNote(id: any, payload: any) {
    return this.baseApi.updatePartial(ENDPOINT, id, payload);
  }

  patch(data: any) {
    const patchData: any = this.baseApi.createPatchObject(data);
    return this.baseApi.updatePartial(ENDPOINT, data.id, patchData);
  }

  deleteNote(id: any) {
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }
}
