import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from '@core/services/document.service';
import { InternalMailService } from '@core/services/internal-mail.service';
import { SettingsService } from 'src/app/core/settings/settings.service';

@Component({
  selector: 'app-offside-pdf-panel',
  templateUrl: './offside-pdf-panel.component.html',
  styleUrls: ['./offside-pdf-panel.component.scss']
})

export class OffsidePdfPanelComponent implements OnInit {
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;

  selectedFile: any;
  selectedBlob: any;
  isLoading: any = false;
  pdfViewerLocation: any = 'hidden';
  imagePreview: boolean;
  pdfPreview: boolean;

  constructor(
    private settings: SettingsService,
    private internalMailService: InternalMailService,
    private documentService: DocumentService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.internalMailService.pdfDocumentId.subscribe((doc: any) => {
      if (doc === 'close') { this.closePDFView(); }
      if (!doc || !doc.id) return false;
      // call the document
      this.toggleView(doc);
    })

    this.internalMailService.imgDocumentId.subscribe((doc: any) => {
      if (!doc || !doc.id) return false;
      // call the document
      this.toggleView(doc);
    })

    this.settings.isMenuCollapsed.subscribe((isCollapsed: any) => {
      if (this.pdfViewerLocation !== 'hidden') {
        this.movePDFLocation('possibleLeft');
      }
    });
  }

  toggleView(doc: any) {
    if (doc === 'close') {
      this.closePDFView();
      return;
    }
    if (doc === null) return false;

    //TODO check if type ="pdf", if not should return message and leave

    // already have doc selected in memory
    if (this.selectedFile && this.selectedFile.id === doc.id) {
      this.pdfViewerOnDemand.zoom = 'page-width';
      this.pdfViewerOnDemand.pdfSrc = this.selectedBlob;
      this.pdfViewerOnDemand.refresh();
      this.openPDFView();
      return;
    }

    // save flag for in memory doc
    this.selectedFile = doc;

    this.isLoading = true
    this.imagePreview = false;
    this.pdfPreview = false;
    //this.documentService.getFile(doc.id, doc.contentType).then(blob => {
    this.documentService.getFile(doc.id).then(blob => {
      if (doc.contentType === 'application/pdf') {
        this.setPDFView(blob);
      }
      if (doc.contentType.indexOf('image') > -1) {
        const imgCtrl: any = document.getElementById("image");
        let reader = new FileReader()
        reader.addEventListener('loadend', () => {
          let contents = reader.result;
          imgCtrl.src = contents;
          imgCtrl.style.display = 'block';
          this.imagePreview = true;
        });
        if (blob instanceof Blob) reader.readAsDataURL(blob)
      }
      if (doc.contentType === 'application/octet-stream') {
        try {
          this.setPDFView(blob);
        } catch (err) {
          this.toastrService.error('Error', `Something went wrong with viewing the document. Err: ${err}`);
        }
      }
    }).catch(err => {
      this.toastrService.error('Error', `Something went wrong with viewing the document. Err: ${err}`);
    }).finally(() => {
      this.isLoading = false;
    });

    this.openPDFView();
    if (doc.location == 'center') {
      this.movePDFLocation('center');
    }
    this.pdfViewerOnDemand.pdfSrc = new Blob();
    this.pdfViewerOnDemand.refresh();
  }

  setPDFView(blob: any) {
    this.pdfPreview = true;
    this.pdfViewerOnDemand.scale = 2;
    this.pdfViewerOnDemand.pageMode = 'none';
    this.pdfViewerOnDemand.zoom = 'page-width';
    this.pdfViewerOnDemand.pdfSrc = blob;
    this.pdfViewerOnDemand.refresh();
    this.selectedBlob = blob;
  }

  openPDFView() {
    this.reset();
    this.settings.setLayoutSetting('offsidePdfPanelOpen', true);
    this.setPdfViewerLocation('right');
  }

  closePDFView() {
    this.reset();
    this.setPdfViewerLocation('hidden');
    this.settings.setLayoutSetting('offsidePdfPanelOpen', false);
  }

  movePDFLocation(newLocation: any) {
    this.reset();

    if (this.pdfViewerLocation === 'hidden') return false;

    // toggle collapsed ourselves if it came from event listener
    let localCollapsed = newLocation === 'possibleLeft' ? !this.isCollapsed() : this.isCollapsed();

    if (newLocation === 'left' || (newLocation === 'possibleLeft' && this.pdfViewerLocation === 'left')) {
      console.log('move to left collapsed', localCollapsed);
      newLocation = 'left';

      if (localCollapsed) {
        this.settings.setLayoutSetting('offsidePdfPanelFurtherLeft', true);
      } else {
        this.settings.setLayoutSetting('offsidePdfPanelLeft', true);
      }
    }
    if (newLocation === 'right') {
    }
    if (newLocation === 'center') {
      this.settings.setLayoutSetting('offsidePdfPanelCenter', true);
    }
    this.setPdfViewerLocation(newLocation);
    console.log('location after movePDFLocation', this.pdfViewerLocation);
  }

  reset() {
    this.settings.setLayoutSetting('offsidePdfPanelFurtherLeft', false);
    this.settings.setLayoutSetting('offsidePdfPanelLeft', false);
    this.settings.setLayoutSetting('offsidePdfPanelCenter', false);
  }

  isCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }

  setPdfViewerLocation(location) {
    this.pdfViewerLocation = location;
  }

  cancel() {
    this.closePDFView();
  }

}
