import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UserProfileService } from '@core/services/user-profile.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import Swal from 'sweetalert2'

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss']
})
export class ResetComponent {
    @BlockUI() blockUI: NgBlockUI;
    private authService = inject(AuthService);
    private fb = inject(FormBuilder);
    private route = inject(ActivatedRoute);

    public valForm: FormGroup;
    private token: string;
    public errors: any = [];
    formSubmitted = false;

    isLoadingReset = false;
    userProfile: any;
    username: any;

    constructor(private router: Router, private userProfileService: UserProfileService) { }

    ngOnInit() {
        this.valForm = this.fb.group({
            'password': [null, [Validators.required, Validators.minLength(6), this.validatePasswordFormat]],
            'confirmPassword': [null, [Validators.required]]
        });

        this.token = decodeURIComponent(this.route.snapshot.queryParamMap.get('token'));
        this.username = this.route.snapshot.queryParamMap.get('email'); //TODO can we put this into reset link ? 
    }

    private validatePasswordFormat(control: FormControl): { [key: string]: boolean } | null {
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[A-Z]).{6,}$/;
        const isValid = passwordRegex.test(control.value);
        return isValid ? null : { 'invalidFormat': true };
    }

    submitForm($ev: any, value: any) {
        $ev.preventDefault();
        this.formSubmitted = true;
      
        const forms = document.querySelectorAll('.needs-validation')
        // Loop over them and prevent submission
        Array.from(forms).forEach((form: any) => {
            form.addEventListener('submit', event => {
                if (!form.checkValidity()) {
                  event.preventDefault()
                  event.stopPropagation()
                }
          
                form.classList.add('was-validated')
            }, false)
        })
      
        if (this.valForm.valid && value.password === value.confirmPassword) {
          this.resetPassword(value);
        }
      }
      
      

    resetPassword(value: any) {
        console.log(value);

        // blank spaces come in where a + was due to email. convert back
        this.token = this.token.replace(/\s/g, '+');

        console.log('token', '*' + this.token + '*');
        if (this.token === null || this.token === 'null') {
            console.log('token is null');
            Swal.fire({
                title: 'Password could not be reset',
                text: 'No token was provided. Please go back to Forgot Password page and try again',
                icon: 'warning'
            });
            return
        }

        this.isLoadingReset = true
        this.errors = [];

        this.authService.confirmResetPassword(this.token, value.password, value.confirmPassword).then((data: any) => {
            // error message
            if (data.Status == 0) {

                Swal.fire({
                    title: 'Password could not be reset',
                    text: data.Message,
                    icon: 'warning'
                });

            } else {

                Swal.fire({
                    title: 'Password reset',
                    text: 'Your password was reset',
                    icon: 'success'
                }).then((result: any) => {
                    if (result.value) {
                      if (this.username) {
                        this.loginUserCarestart({email: this.username, password: value.password})
                      }
                    }
                  });
            }
        }, (error: any) => {
            Swal.fire({
                title: 'Error while trying to reset password',
                text: error.message,
                icon: 'warning',
                confirmButtonColor: '#DD6B55',
            });

            this.errors = error.errors;
            console.log('this.errors', this.errors)
            console.log('this.errors.Password', this.errors?.Password)

        }).finally(() => {
            this.isLoadingReset = false;
        });
    }

    loginUserCarestart(credentials: any) {
        this.blockUI.start();
        this.authService.carestartEmailLogin(credentials.email, credentials.password)
            .then((authData: any) => {
                if (!authData.id && authData.Status === 0 && authData.Message) {
                    this.blockUI.stop();
                    Swal.fire({
                        title: 'Not able to login',
                        icon: 'warning',
                        text: authData.Message,
                        confirmButtonColor: '#DD6B55',
                    });
                    return;
                }
                this.authService.setAuth(authData);
                this.userProfileService.getUserProfile()
                    .then(res => {
                        this.userProfile = res;
                        this.blockUI.stop();
                        this.router.navigate(['/home']);
                    })
                    .catch((err) => {
                        this.authService.logout();
                        this.blockUI.stop();
                        Swal.fire({
                            title: 'Not able to get user profile',
                            icon: 'warning',
                            //text: err,
                            confirmButtonColor: '#DD6B55',
                        });
                    });
            }).catch((err) => {
                this.blockUI.stop();
                Swal.fire({
                    title: 'Not able to login',
                    icon: 'warning',
                    //text: err,
                    confirmButtonColor: '#DD6B55',
                });
            });
    }
}
