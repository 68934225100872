import { Injectable, Injector, OnInit } from '@angular/core';
import { menu } from '../routes/menu';
//import { routes } from '../routes/routes';
import { MenuService } from '../core/menu/menu.service';

import map from 'lodash-es/map';
import isEqual from 'lodash-es/isEqual';
import lowerCase from 'lodash-es/lowerCase';
import find from 'lodash-es/find';
import intersectionWith from 'lodash-es/intersectionWith';
import forEach from 'lodash-es/forEach';

import { Route, Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalMenuService implements OnInit {

  public urls: string[] = [];

  constructor(
    //private authService: AuthService,
    private _router: Router,
    private injector: Injector,
    private settingsService: SettingsService,
    private menuService: MenuService) {

    // TODO i need the canActivate roles wth these paths
    // this._router.config.forEach(i => {
    //     this.getPaths(i);
    //   })
    //   console.log('urls', this.urls);

  }

  ngOnInit() {
    

  }

  async setMenu(user) {

    //const childrenRoutes = this._router.config[0].children;
    //console.log('this._router', this._router);

    //console.log('globalmenu make request for userValue');
    if (!user || !user.roles) return false;

    const userRoles = user.roles;
    const userRolesNames = map(userRoles, a => { return a.name; });

    const org = this.settingsService.getOrganisationSetting(null);
    //console.log('org from globalmenu', org);
    
    // tried above but if we dont have the org set yet then not ready for it
    // need to call function that checks first if org data is there

    // BUILD MENU
    let modules = org.modules;
    let moduleCodes = map(modules, m => { return m.code });
    let roleCodes = map(userRoles, m => { if (m.code === 'A') { m.code = 'admin'; } return m.code });
    //console.log('roleCodes', roleCodes);

    var newMenu: any = [];
    var availableMenuItems: any = [];

    // make the full array first then loop again and check if 2 breaklines hit each other in a row
    // ie loop to create list and then loop to create view

    forEach(menu, (menuItem: any) => {
      //console.log('menu item', menuItem);
      const allowAllModules = find(menuItem.modules,m => lowerCase(m) === 'all')
      const allowThisModule = menuItem.modules && intersectionWith(menuItem.modules, moduleCodes, isEqual).length
      const allowAllRoles = find(menuItem.roles, r => lowerCase(r) === 'all')
      const allowthisModule = (menuItem.roles && intersectionWith(menuItem.roles, roleCodes, isEqual).length)

      if (!menuItem.modules || allowAllModules || allowThisModule) {       
          if (allowAllRoles || allowthisModule) {
            //console.log('push item', item);
            // if (this.checkRoleOK(item, childrenRoutes, userRolesNames))
            availableMenuItems.push(menuItem)
          }
        }
    });

    forEach(availableMenuItems, (item: any, index) => {
      const nextItem = availableMenuItems[index + 1]
      // if breakline then only push if we have some items to fill before next breakline
      if (item.text === 'breakLine') {
        if (nextItem && nextItem.text != 'breakLine' && nextItem.heading != true) {    
          newMenu.push(item);
        }
      } else {
        newMenu.push(item);
      }
    })

    this.menuService.addMenu(newMenu);
  }

  checkRoleOK(item: any, childrenRoutes: any, userRolesNames: any) {
    if (!item.heading) {
      const link = item.link ? item.link.split('/')[1] : null;
      //console.log('link', link);
      console.log('menuItem', item);

      const r = find(childrenRoutes, (r: any) => r.path === link);
      //console.log('r', r);
      if (r) {
        console.log('has r.data', r.data);
        console.log('has userRolesNames found', r.data && r.data.roles?.some((r: any) => userRolesNames.indexOf(r) > -1));
        const hasRole = r.data && r.data.roles ? r.data.roles?.some((r: any) => userRolesNames.indexOf(r) > -1) : null;
        console.log('has Role', hasRole);

        if (hasRole || r.data === null || r.data.roles === null) {
          console.log('push item to menu', item);
          return true
        }
      } else {
        //console.log('no children links');
      }
    } else {
      // headers, but should we display headers if no sub items for them ?
      return true;
    }

    return false;
  }

  getPaths(route: Route, parent: string = '') {
    if (route.redirectTo) {
      return;
    }
    if (route.children) {
      route.children.forEach(i => {
        this.getPaths(i, parent + route.path);
      });
    }
    else if (route.loadChildren) {
      (<any>this._router).configLoader.load(this.injector, route).subscribe(i => {
        i.routes.forEach(j => {
          this.getPaths(j, parent + route.path)
        });
      });
    }
    else if (route.path) {
      this.setPath(route.path, parent);
    }
  }
  
  setPath(path, parent) {
    let fullPath: string;
    if (path) {
      if (parent) {
        fullPath = `/${parent}/${path}`;
      }
      else {
        fullPath = `/${path}`
      }
    }
    this.urls.push(fullPath)
  }
}
