import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'any' 
})
export class MailService {

	http: HttpClient;
	mailgunUrl: string;
	mailgunApiKey: string;
	sender: string;

	constructor(http: HttpClient) {
		this.http = http;
		this.mailgunUrl = 'mg.iconnecttraining.com.au';
		this.mailgunApiKey = window.btoa('api:2af183ba-c32712af');
		this.sender = 'Admin at SOS <contact@sossupport.com.au>';
	}

	send(recipient: string, subject: string, text: string, cc: string = '') {
		const promise = new Promise((resolve, reject) => {
			const message = `from=${this.sender}&to=${recipient}${cc ? '&cc=' : ''}${cc}&subject=${subject}&text=${text}`;

			const url = `https://api.mailgun.net/v3/${this.mailgunUrl}/messages`;

			const xhr = new XMLHttpRequest();
			xhr.withCredentials = true;

			xhr.addEventListener('readystatechange', function () {
				if (this.readyState === 4) {
					resolve('err');
				}
			});

			xhr.open('POST', url);
			xhr.setRequestHeader('authorization', `Basic ${this.mailgunApiKey}`);
			xhr.setRequestHeader('content-type', 'application/x-www-form-urlencoded');
			xhr.setRequestHeader('cache-control', 'no-cache');

			xhr.send(message);
		});
		return promise;

	}
}
