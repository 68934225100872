import { Injectable, effect, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SettingsService {

    private user: any = {};
    private organisation: any = {};
    private app: any = {};
    private type: any = [];
    private layout: any;
    private priceGuides: any;
    private appSettings = signal(this.app); // new BehaviorSubject(this.app);  // so we can subscribe to changes in app settings
    private userSettings = signal(this.user); //new BehaviorSubject(this.user);
    private orgSettings = signal(this.organisation); // new BehaviorSubject(this.organisation);
    private typeSettings = signal(this.type); //new BehaviorSubject(this.type);

    private _actionMenuItems: any = [{ title: '' }]
    actionMenuItems = new Subject();

    public isMenuCollapsed: any = new Subject();

    constructor() { 
        this.init(); 
        effect(() => {
            console.log('The userSettings from effect',  this.userSettings());
        });
    }

    init() {
        // -------------=== User Settings ===----------------
        this.user = { id: '', name: 'User', job: 'Guest', picture: 'assets/img/user/user-placeholder.jpg' };

        // -------------=== Organisation Settings ===----------------
        this.organisation = { name: '-----', abn: '00000000', accountingPackage: 'none' };

        // -------------=== App Settings ===----------------
        this.app = {
            name: 'Carestart',
            description: 'Carestart Web Application',
            version: environment.release,
            apiVersion: '',
            year: ((new Date()).getFullYear()),
            // also allow capture of current page data from router via authguard
            pageRequiredModules: [],
            pageRequiredRoles: [],
            pageName: '',
            tokenRefreshTime: ''
        };

        // -------------=== Layout Settings ===----------------
        this.layout = { isFixed: true, isCollapsed: false, isBoxed: false, isRTL: false, horizontal: false,
            isFloat: false, asideHover: false, theme: null, asideScrollbar: false, isCollapsedText: false,
            useFullLayout: false, hiddenFooter: false, offsidebarOpen: false, offsidePanelOpen: false,
            offsideMessagePanelOpen: false, offsidePdfPanelOpen: false, offsidePdfPanelLeft: false,
            offsidePdfPanelFurtherLeft: false, offsidePdfPanelCenter: false, asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

        // -------------=== PriceGuide Settings ===----------------
        this.priceGuides = null;

        // -------------=== Type Settings ===----------------
        this.type = [];
    }

    // obs 
    getAppSettings() { return this.appSettings(); } //.asObservable(); }
    
    getUserSettings() { return this.userSettings(); }

    getUserSignal() { return this.userSettings; }
    
    getOrgSettings() { return this.orgSettings(); }
    
    getTypeSettings() { return this.typeSettings(); }

    // plain
    getAppSetting(name: any) { return name ? this.app[name] : this.app; }
    
    getUserSetting(name: any) { return name ? this.user[name] : this.user; }
    
    getOrganisationSetting(name: any) { return name ? this.organisation[name] : this.organisation; }
    
    getLayoutSetting(name: any) { return name ? this.layout[name] : this.layout; }

    getPriceGuidesSetting(name: any) { return name ? this.priceGuides[name] : this.priceGuides; }
    
    getTypeSetting(name: any) { return name ? this.type[name] : this.type; }

    setAppSetting(name: any, value: any) {
        this.app[name] = value;
        //this.appSettings.next(this.app);
        this.appSettings.set(this.app);
    }

    setUserSetting(name: any, value: any) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
        this.userSettings.set(this.user);
    }

    setOrganisationSetting(name: any, value: any) {
        // if (typeof this.organisation[name] !== 'undefined') {
        //     this.organisation[name] = value;
        // }

        this.organisation[name] = value;
        this.orgSettings.set(this.organisation);
    }

    setLayoutSetting(name: any, value: any) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    setTypeSetting(name: any, value: any) {
        this.type[name] = value;
        this.typeSettings.set(this.type);
    }

    setUser(value: any) {
        this.user = value;
        this.userSettings.set(this.user);
    }

    setOrganisation(value: any) {
        this.organisation = value;
        this.orgSettings.set(this.organisation);
    }

    setPriceGuides(value: any) {
        this.priceGuides = value;
    }

    removeAllUserSettings() {
        this.user = null;
        this.userSettings.set(this.user);
    }

    removeAllOrgSettings() {
        this.organisation = null;
        this.orgSettings.set(this.organisation);
    }
    
    removeAllTypeSettings() {
        this.type = null;
        this.typeSettings.set(this.type);
    }

    toggleLayoutSetting(name: any) {
        if (name === 'isCollapsed') {
            this.isMenuCollapsed.next(this.layout[name]);
        }
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    // setDocTitle(title: string) {
    //     console.log('current title:::::' + this.titleService.getTitle());
    //     this.titleService.setTitle(title);
    //  }


    /** Action Menu Items */
    changeActionMenuItems(data: any) {
        this._actionMenuItems = data;
        console.log('action menu items changed: ', this._actionMenuItems);
        this.actionMenuItems.next(this._actionMenuItems);
    }

    clearActionMenuItems() {
        this._actionMenuItems = [{ title: '' }];
        this.actionMenuItems.next(this._actionMenuItems);
    }

}
