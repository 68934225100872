<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
    <div class="container-fluid">
    <!-- START navbar header-->
    <div class="navbar-header">
        <a class="navbar-brand" routerLink="/">
            <div class="brand-logo">
                <!-- CARESTART -->
                <img class="img-fluid" src="assets/img/logo/logo_wide2.png" alt="App Logo" />
            </div>
            <div class="brand-logo-collapsed">
                <img class="img-fluid" src="assets/img/logo/logo-single.png" alt="App Logo" />
            </div>
        </a>
    </div>
    <!-- END navbar header-->
    <!-- START Left navbar-->
    <ul class="navbar-nav me-auto flex-row flex-grow-1">
        <li class="nav-item d-none">
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link d-none d-md-block d-lg-block d-xl-block" trigger-resize=""
                (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
                <em class="fas fa-bars" id="trigger-resize"></em>
            </a>
            <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
            <a class="nav-link sidebar-toggle d-md-none"
                (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
                <em class="fas fa-bars"></em>
            </a>
        </li>
        <!-- START User avatar toggle-->
        <li class="nav-item nav-link-text dropdown dropdown-list pointer" dropdown>
            <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
            <a class="nav-link dropdown-toggle" dropdownToggle>
                <em class="icon-user me-2"></em>{{currentOrganisation?.name || 'No organisation'}}
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action">
                            <div class="ms-2 d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fa fa-user fa-2x text-info"></em>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <p class="m-0">{{settings.getUserSetting('firstName')}} {{settings.getUserSetting('lastName')}}</p>
                                    <p class="m-0 text-muted text-sm">{{userProfile?.rolesString}}</p>
                                </div>
                            </div>
                        </div>

                        <!-- organisations  -->
                        <div [class]="organisation.id === currentOrganisation.id ? 'list-group-item list-group-item-action selected-account' : 'list-group-item list-group-item-action'"
                            *ngFor="let organisation of organisations">
                            <div class="ms-2 d-flex" (click)="selectOrganisation(organisation)">
                                <div class="flex-shrink-0">
                                    <em class="fa fa-building fa-2x {{organisation.color}}"></em>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <p class="m-0">{{organisation.name}}</p>
                                    <p class="m-0 text-muted text-sm">{{organisation.role}}</p>
                                </div>
                            </div>
                        </div>


                        <!-- list item-->
                        <div class="list-group-item list-group-item-action">
                            <div class="ms-2 d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fa fa-sign-out fa-2x text-danger"></em>
                                </div>
                                <div class="flex-grow-1 ms-3" (click)="signOut()">
                                    <p class="m-0">Sign out</p>
                                    <p class="m-0 text-muted text-sm">Sign out of account</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END list group-->
                </div>
            </div>

        </li>
        <!-- END User avatar toggle-->
        <!-- START lock screen-->
        <!-- <li class="nav-item d-none d-md-block">
            <a class="nav-link"  title="Lock screen" [routerLink]="'/lock'">
                <em class="icon-lock"></em>
            </a>
        </li> -->
        <!-- END lock screen-->


        <!-- <li class="nav-item dropdown dropdown-list" dropdown>
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle>
                {{selectedModule?.name || 'No'}} Module
            </a>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
                <div class="dropdown-item">
                    <div class="list-group">                        

                        
                        <div [class]="module.id === selectedModule?.id ? 'list-group-item list-group-item-action selected-account' : 'list-group-item list-group-item-action'" *ngFor="let module of modules">
                            <div class="ms-2 media" (click)="selectModule(module)">
                                <div class="flex-shrink-0">
                                    <em class="fa fa-cog fa-2x {{module.color}}"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">{{module.name}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </li> -->
    </ul>
    <!-- END Left navbar-->
    <!-- START Right Navbar-->
    <ul class="navbar-nav list-unstyled flex-row">

        <li class="nav-item dropdown d-none d-lg-inline-block topbar-dropdown">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="true">
                <i class="fa-solid fa-grip"></i>
            </a>
            <div class="dropdown-menu dropdown-lg dropdown-menu-end p-0 animated flipInX" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0px, 0px, 0px);" data-popper-placement="bottom-end">

                <div class="p-2">
                    <div class="row px-2">
                        <h4 class="mb-1 text-center">Quick Links</h4>
                    </div>
                    <div class="row g-0">
                        <div class="col">
                            <!-- <a class="dropdown-icon-item hoverable"  (click)="goToPhoneRecords()">                               
                                <i class="fa-solid fa-phone fa-2x text-primary"></i>
                                <div>Phone</div>
                            </a> -->
                            <a class="dropdown-icon-item hoverable"  (click)="goToJobsManage()">                               
                                <i class="fa-solid fa-calendar-days fa-2x text-primary"></i>
                                <div>Jobs</div>
                            </a> 
                        </div>
                        <div class="col">
                            <a class="dropdown-icon-item hoverable"  (click)="goToInvoices()">
                                <i class="fa-solid fa-file-invoice-dollar fa-2x text-green"></i>
                                <div>Invoices</div>
                            </a>
                        </div>
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="goToBills()">
                                <i class="fa-solid fa-file fa-2x text-purple"></i>
                                <div>Bills</div>
                            </a>
                        </div>
                    </div>

                    <div class="row g-0">
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="goToFileUpload()">
                                <!-- <img src="../assets/images/brands/github.png" alt="Github"> -->
                                <i class="fa-solid fa-folder-open fa-2x text-secondary"></i>
                                <div>Files</div>
                            </a>
                        </div>
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="goToPayroll()">
                                <i class="fa-solid fa-user-clock fa-2x text-info"></i>
                                <div>Payroll</div>
                            </a>
                        </div>
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="goToSettings()">
                                <i class="fa-solid fa-gear fa-2x text-gray"></i>
                                <div>Settings</div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </li>

        <!-- Phone Records icon-->
        <!-- <li class="nav-item">
            <a class="nav-link" (click)="goToPhoneRecords()">
                <i class="fas fa-phone"></i>
            </a>
        </li> -->
        <!-- File Upload icon-->
        <!-- <li class="nav-item">
            <a class="nav-link" (click)="goToFileUpload()">
                <i class="fas fa-upload"></i>
            </a>
        </li> -->
       
        <!-- New Job Items only if module DS available-->
        <li *ngIf="currentOrganisation !== null" class="nav-item dropdown d-none d-lg-inline-block topbar-dropdown">
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret waves-effect waves-light" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="true">
                <i class="fa-regular fa-calendar-plus"></i>
            </a>
            <div class="dropdown-menu dropdown-lg dropdown-menu-end p-0 animated flipInX" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0px, 0px, 0px);" data-popper-placement="bottom-end">
                <div class="p-2">
                    <div class="row px-2">
                        <h4 class="mb-1 text-center">New Roster Event</h4>
                    </div>
                    <div class="row g-0">
                        <div class="col">
                            <a class="dropdown-icon-item hoverable"  (click)="newRosterEvent('j')">
                                <!-- <img src="../assets/images/brands/github.png" alt="Github"> -->
                                <i class="fas fa-people-arrows fa-2x text-primary"></i>
                                <!-- <div>Job</div> -->
                                <div>Roster Shift</div>
                            </a>
                        </div>         
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('cm')">
                                <i class="fas fa-person-chalkboard fa-2x text-purple"></i>
                                <div>Client Meeting</div>
                            </a>
                        </div>     
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('im')">
                                <i class="fa-solid fa-people-group fa-2x text-green"></i>
                                <div>Staff Meeting</div>
                            </a>
                        </div>                    
                    </div>

                    <div class="row g-0 mt-2">
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('s')">
                                <!-- <img src="../assets/images/brands/github.png" alt="Github"> -->
                                <i class="fa-solid fa-street-view fa-2x text-secondary"></i>
                                <div>Supervision</div>
                            </a>
                        </div>
                        <!-- <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('q')">
                                <i class="fas fa-tasks fa-2x text-info"></i>
                                <div>Quote</div>
                            </a>
                        </div> -->
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('lc')">
                                <i class="fas fa-location-dot fa-2x text-secondary"></i>
                                <div>Location</div>
                            </a>
                        </div>
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('ta')">
                                <i class="fas fa-plane-departure fa-2x text-secondary"></i>
                                <div>Time Away</div>
                            </a>
                        </div>
                    </div>

                    <div class="row g-0 mt-2">
                        <div class="col">
                            <a class="dropdown-icon-item hoverable"  (click)="newRosterEvent('ce')">
                                <i class="fab fa-slideshare fa-2x text-green"></i>
                                <div>Community Engagement</div>
                            </a>
                        </div>
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('pd')">
                                <i class="fas fa-user-graduate fa-2x text-purple"></i>
                                <div>Professional Development</div>
                            </a>
                        </div>
                        <div class="col">
                            <a class="dropdown-icon-item hoverable" (click)="newRosterEvent('re')">
                                <i class="fa-solid fa-bell fa-2x text-green"></i>
                                <div>Reminder</div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </li>
        <!-- END New Job Items -->
        

        <!-- New Items -->
        <li  class="nav-item dropdown dropdown-list" dropdown>
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle>
                <em class="icon-note"></em>
                <!-- <span class="badge bg-danger">11</span> -->
            </a>
            <!-- START Dropdown menu-->
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action hoverable" (click)="newNoteEvent('Therapy')">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fas fa-notes-medical fa-2x me-2 text-info"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New Therapy Note</p>   
                                    <p class="text-muted text-sm">For Therapy Clients</p>                                
                                </div>
                            </div>
                        </div>
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action hoverable" (click)="newNoteEvent('COS')">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fas fa-notes-medical fa-2x me-2 text-info"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New COS Note</p>   
                                    <p class="text-muted text-sm">For COS clients</p>                                
                                </div>
                            </div>
                        </div>    
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action hoverable" (click)="newNoteEvent('Other')">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fas fa-notes-medical fa-2x me-2 text-info"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New Supports Note</p>   
                                    <p class="text-muted text-sm">For Supports clients</p>                                
                                </div>
                            </div>
                        </div>                         
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>
        <!-- END New Items -->

         <!-- Search icon-->
         <li class="nav-item">
            <a class="nav-link" (click)="openNavSearch($event)">
                <em class="icon-magnifier"></em>
            </a>
        </li>

        <!-- Fullscreen (only desktops)-->
        <li class="nav-item d-none d-md-block">
            <a class="nav-link" #fsbutton (click)="toggleFullScreen($event)">
                <em class="fa fa-expand"></em>
            </a>
        </li>
        <!-- START Alert menu-->
        <li *ngIf="showNotifictionsMenu" class="nav-item dropdown dropdown-list" dropdown>
            <a class="nav-link dropdown-toggle dropdown-toggle-nocaret" dropdownToggle>
                <em class="icon-bell"></em>
                <span class="badge bg-danger">11</span>
            </a>
            <!-- START Dropdown menu-->
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
                <div class="dropdown-item">
                    <!-- START list group-->
                    <div class="list-group">
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fab fa-twitter fa-2x text-info"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">New followers</p>
                                    <p class="m-0 text-muted text-sm">1 new follower</p>
                                </div>
                            </div>
                        </div>
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fa fa-envelope fa-2x text-warning"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">
                                        <a class="nav-link" (click)="goToMailbox()">
                                            New emails
                                        </a>
                                    </p>
                                    <p class="m-0 text-muted text-sm">You have 10 new emails</p>
                                </div>
                            </div>
                        </div>
                        <!-- list item-->
                        <div class="list-group-item list-group-item-action">
                            <div class="d-flex">
                                <div class="flex-shrink-0">
                                    <em class="fa fa-tasks fa-2x text-success"></em>
                                </div>
                                <div class="media-body">
                                    <p class="m-0">Pending Tasks</p>
                                    <p class="m-0 text-muted text-sm">11 pending task</p>
                                </div>
                            </div>
                        </div>
                        <!-- last list item-->
                        <div class="list-group-item list-group-item-action">
                            <span class="d-flex align-items-center">
                                <span class="text-sm">More notifications</span>
                                <span class="badge bg-danger ms-auto">14</span>
                            </span>
                        </div>
                    </div>
                    <!-- END list group-->
                </div>
            </div>
            <!-- END Dropdown menu-->
        </li>
        <!-- END Alert menu-->
        <!-- START Offsidebar button-->
        <li class="nav-item">
            <a class="nav-link" (click)="toggleOffsidebarPanel(); $event.stopPropagation()">
                <em class="icon-notebook"></em>
            </a>
        </li>
        <!-- END Offsidebar menu-->
    </ul>
    <!-- END Right Navbar-->
    <app-navsearch [visible]="getNavSearchVisible()" (onclose)="setNavSearchVisible(false)"></app-navsearch>
    </div>
</nav>

 <!-- menu -->
 <app-horizontal></app-horizontal>