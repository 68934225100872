import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of, EMPTY, forkJoin, concat, empty } from 'rxjs';
import { catchError, concatMap, finalize, switchMap } from 'rxjs/operators';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@core/settings/settings.service';
import { JwtService } from 'src/app/services/jwt.service';
import { environment } from '@environment/environment';

const REFRESH_TIME_THRESHOLD_MINS = 5;  // mins
const MAX_REFRESH_DURATION = 1 * 60 * 1000; // 1 minutes

interface ApiError {
    status: number;
    title: string;
    error: {
        [key: string]: string[] | string | { [key: string]: string[] | string };
    };
}
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private requestQueue: HttpRequest<any>[] = [];
    private refreshingInProgressTimestamp: number | null = null; // Store the timestamp when refresh started
    private accessTokenSubject: WritableSignal<string> = signal('');

    constructor(
        private router: Router,
        private jwtService: JwtService,
        private settingsService: SettingsService
    ) { 
        // Check if refreshing was in progress when the application starts
        this.checkRefreshingInProgress();
        this.saveUnixTimestampToSettings(this.getAccessToken());
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isUnauthenticatedRequest(request.urlWithParams)) {
            //console.log('intercept isUnauthenticatedRequest', request)
            return next.handle(request);
          }
      
          if (this.isCarestartApiRequest(request.urlWithParams)) {
            //console.log('intercept isCarestartApiRequest', request)
            return this.handleCarestartApiRequest(request, next);
          }
      
          //console.log('intercept other request', request)
          return this.handleOtherRequest(request, next);
    }

    private getAccessToken() {
        return this.jwtService.getTokenResponse().jwtToken;
    }

    private isUnauthenticatedRequest(url: string): boolean {
        const unauthenticatedPaths = [
          'auth/authenticate',
          'auth/googleAuthenticate',
          'auth/forgot-password',
          'auth/reset-password',
          'auth/signUp',
        ];
        return unauthenticatedPaths.some((path) => url.includes(path));
    }

    private isRefreshTokenRequest(url: string): boolean {
        return url.includes('auth/refresh-token');
    }

    private isCarestartApiRequest(url: string): boolean {
        return url.startsWith(environment.carestartApiUri);
    }

    private handleCarestartApiRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        const token = this.getAccessToken();
        const timeUntilTokenRefresh = this.calculateTimeUntilTokenRefresh(token);
        request = this.addAuthorizationHeader(request, token);

        // if this request is the token refresh then act straight away
        if (this.isRefreshTokenRequest(request.url)) {
            return next.handle(request).pipe(
                catchError((error) => this.handleHttpError(error, next))
            );
        }
                
        // where refresh has started but another request arrives before we are done, queue it up
        if (this.refreshingInProgressTimestamp !== null) {
            console.log('push to queue and wait', request)
            this.requestQueue.push(request);
            console.log('push to queue and wait requestQueue', this.requestQueue)
            return EMPTY; 
        }
    
        // token time to refresh check
        if (timeUntilTokenRefresh < 0) {
            console.log('push to queue and wait', request)
            this.requestQueue.push(request);
            console.log('push to queue and wait requestQueue', this.requestQueue)
            return this.refreshToken(request, next);
        } else {
            return next.handle(request).pipe(catchError((error) => this.handleHttpError(error, next)));
        }
    }    

    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentTime = Date.now();

        // override for cases where taking too long to refresh and may have got stuck
        // if (this.refreshingInProgressTimestamp !== null && 
        //     (currentTime - this.refreshingInProgressTimestamp) > MAX_REFRESH_DURATION) {
        //     this.resetRefreshingInProgress();
        // }

        // if not already started refresh token then do it
        if (!this.refreshingInProgressTimestamp) {
          this.startRefreshingInProgress();
      
          return this.jwtService.refreshToken().pipe(
            switchMap((res: any) => {
              if (res.Status === 0) {
                this.resetRefreshingInProgress();
                return this.logoutAndRedirect(null);
              }
      
              const newToken = res.jwtToken;
              this.saveUnixTimestampToSettings(newToken);
              this.accessTokenSubject.set(newToken);
      
              // Set refreshingInProgress to false after successfully refreshing the token
              this.resetRefreshingInProgress();

              console.log('should process next')

                // after a lot of testing, the queue works but the page freezes on userProfile sometimes
                // to ensure the page is updated, lets reload it until we solve the problem
                // call the queue first because it may have been saving data for us
                // even though it freezes on return saving the data may be important for us
                   this.processQueueWithNewToken(newToken, next);
                   location.reload();
                   return EMPTY;
      
                //debugger;
              //return this.processQueueWithNewToken(newToken, next);
            }),
            catchError((error: any) => {
              // Ensure that refreshingInProgress is set to false in case of an error
              this.resetRefreshingInProgress();
      
              if (error.status === 500) {
                this.logoutAndRedirect(error);
                //this.router.navigateByUrl('/500');
              }
              return throwError(error);
            })
          );
        } else {          
          return EMPTY;
        }
    }

    private processQueueWithNewToken(newToken: string, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('processQueueWithNewToken', this.requestQueue);
      
        if (this.requestQueue.length === 0) {
          console.log('No queued requests to process');
          return EMPTY; // Return an empty observable
        }
      
        return concat(this.processNextRequest(newToken, next));
    }
      
    private processNextRequest(newToken: string, next: HttpHandler): Observable<HttpEvent<any>> {
        const request = this.requestQueue.shift();
      
        if (request) {
          console.log('Processing request:', request);
          const requestWithToken = this.addAuthorizationHeader(request, newToken);
      
          return next.handle(requestWithToken).pipe(
            catchError((error) => {
              console.error('Error processing request:', error);
              // Continue processing next request even if there's an error
              return of(); // Return an empty observable to proceed to the next request
            }),
            finalize(() => {
              console.log('next.handle(requestWithToken) completed.');
            })
          );
        }
          
        console.log('No more requests to process');
        return EMPTY; // Return an empty observable        
    }
      
    private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
        if (token) {
          return request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              ApiKey: environment.apiKey,
            },
          });
        }
        return request;
    }
      
    private calculateTimeUntilTokenRefresh(accessToken: any): number {
        const tokenExpirationTime = this.jwtService.getTokenExpirationTime(accessToken) / 1000;
        const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
        const timeDifferenceSeconds = tokenExpirationTime - currentTimestamp;
        const minutesUntilTarget = Math.floor(timeDifferenceSeconds / 60);
            
        // console.log('timeUntilTokenRefresh', timeUntilTokenRefresh);
        // console.log('this.refreshingInProgressTimestamp', this.refreshingInProgressTimestamp);

        return minutesUntilTarget - REFRESH_TIME_THRESHOLD_MINS;
    }

    private checkRefreshingInProgress() {
        const storedRefreshingInProgress = localStorage.getItem('refreshingInProgress');
        
        if (storedRefreshingInProgress) {
          const storedTimestamp = parseInt(storedRefreshingInProgress, 10);
          const currentTime = Date.now();
          
          if ((currentTime - storedTimestamp) <= MAX_REFRESH_DURATION) {
            // The refresh is considered in progress if the stored timestamp is within the allowed duration
            this.refreshingInProgressTimestamp = storedTimestamp;
          } else {
            // If the stored timestamp has exceeded the allowed duration, reset it
            this.resetRefreshingInProgress();
          }
        }
    }

    // Manually reset the refreshingInProgress status
    private resetRefreshingInProgress() {        
        localStorage.removeItem('refreshingInProgress');
        this.refreshingInProgressTimestamp = null;
    }

    // Start the refreshing process and set the timestamp
    private startRefreshingInProgress() {
        console.log('refreshToken in startRefreshingInProgress');
        this.refreshingInProgressTimestamp = Date.now();
        localStorage.setItem('refreshingInProgress', this.refreshingInProgressTimestamp.toString());
    }

    private logoutAndRedirect(err): Observable<HttpEvent<any>> {
        this.router.navigateByUrl('/login');
        return of(); // throwError(() => new Error(err.error));
    }

    private interceptOther(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError(err => {
                // return throwError(() => new Error(err.error));
                return throwError(() => this.buildError(err));
            })
        );
    }    

    private handleHttpError(error: HttpErrorResponse, next: HttpHandler): Observable<HttpEvent<any>> {
        switch (error.status) {
          case 401: return this.handle401Error(error, next);
          case 403: return this.handle403Error(error);
          case 409: return this.handle409Error(error);
          case 500: return this.handle500Error(error);
          default: return throwError(error);
        }
      }

    private handle401Error(error: HttpErrorResponse, next: HttpHandler): Observable<HttpEvent<any>> {
        // since we are checking whether refreshToken is < 0 time then the only way we should get a 401 is if ??? we had no token to begin with?
        console.log('handle401Error', this.requestQueue[0])
        if (this.jwtService.getTokenResponse().refreshToken) {
            return this.refreshToken(this.requestQueue[0], next);
        } else {
            return this.logoutAndRedirect(error);
        }
    }

    private handle403Error(err: any): Observable<HttpEvent<any>> {
        return this.logoutAndRedirect(err);
    }

    private handle409Error(err: any): Observable<HttpEvent<any>> {
        return throwError(() => err);
    }

    // server error
    private handle500Error(err: any): Observable<HttpEvent<any>> {
        // may be a code problem so dont log them out for this
        return throwError(() => err);
    }

    private saveUnixTimestampToSettings(accessToken: any) {
        const unixTimestamp = this.calculateTimeUntilTokenRefresh(accessToken);

        const date = new Date(unixTimestamp * 1000);
        const minutes = date.getMinutes();
        console.log('saveUnixTimestampToSettings', unixTimestamp)
        console.log('saveUnixTimestampToSettings mins', minutes)
        
        const tokenExpirationTime = this.jwtService.getTokenExpirationTime(accessToken) / 1000;
        this.settingsService.setAppSetting('tokenRefreshTime', tokenExpirationTime);
    }

    private handleOtherRequest(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((error) => throwError(() => this.buildError(error)))
        );
    }

    extractErrorMessages(value: any): string[] {
        const messages: string[] = [];

        if (typeof value === 'string') {
            messages.push(value);
        } else if (Array.isArray(value)) {
            messages.push(...value);
        } else
            if (typeof value === 'object') {
                for (const key in value) {
                    if (value.hasOwnProperty(key)) {
                        const subValue = value[key];
                        messages.push(...this.extractErrorMessages(subValue));
                    }
                }
            }

        return messages;
    }

    buildError(error: ApiError) {
        const statusText = error.status.toString();
        const title = error.title;
        const errorMessages: string[] = [];

        // Extract error messages from the 'error' object
        const errors: any = error?.error?.errors;
        if (errors && typeof error.error === 'object') {
            for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    const errorValue = errors[key];
                    const messages = this.extractErrorMessages(errorValue);
                    errorMessages.push(...messages.map(message => `${key}: ${message}`));
                }
            }
        }

        let longErrorMessage = `${statusText} ${statusText === '400' ? 'Bad Request' : title}`;
        let errorMessage = '';

        if (errorMessages.length > 0) {
            longErrorMessage += `: ${errorMessages.join(' ')}`;
            errorMessage += `${errorMessages.join(' ')}`;
        }

        return { error: error, longMessage: longErrorMessage, message: errorMessage };
    }
}


export const TokenInterceptorProvider = { 
    provide: HTTP_INTERCEPTORS, 
    useClass: TokenInterceptor, 
    multi: true 
};