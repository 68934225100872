import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const CLIENT_ENDPOINT = 'client';
const GROUP_CLIENT_BOOKING_ENDPOINT = 'groupClientBooking';
const CONTACT_ENDPOINT = 'contact';
const CLIENT_MEDICATION_ENDPOINT = 'ClientMedication';
const CLIENT_HEALTH_PROFILE = 'clientHealthProfile';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(private baseApi: CarestartApiService) { }

  /* CLIENT */
  createClient(data: any) {
    delete data.id; // make sure there is no id set as null, since we are supposed to be creating a new one
    return this.baseApi.basicCreate(CLIENT_ENDPOINT, data);
  }

  patchClient(client: any) {
    const data: any = this.baseApi.createPatchObject(client);
    return this.baseApi.updatePartial(CLIENT_ENDPOINT, client.id, data);
  }

  updateCOSManager(client: any, cosManagerId: any) {
    const data: any = this.baseApi.createPatchObject({ cosManagerId: cosManagerId});
    return this.baseApi.updatePartial(CLIENT_ENDPOINT, client.id, data);
  }

  updateGoalsText(client: any, goalsText: any) {
    const data: any = this.baseApi.createPatchObject({ goalsText: goalsText});
    return this.baseApi.updatePartial(CLIENT_ENDPOINT, client.id, data);
  }

  updateClient(data: any) {
    const endpoint = `${CLIENT_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  deleteClient(clientId: any) {
    const endpoint = `${CLIENT_ENDPOINT}/${clientId}`;
    return this.baseApi.delete(endpoint);
  }

  getClients(pageSize: any = 100, page: any = 1, activeStatus: any = 'Active', searchWords: any = "", clientType: any = 'all', ndis: any = 'all', cos: any = 'all') {
    const endpoint = `${CLIENT_ENDPOINT}?activeStatus=${activeStatus}&clientType=${clientType}&ndis=${ndis}&cos=${cos}&searchWord=${searchWords}&Limit=${pageSize}&Page=${page}`;
    return this.baseApi.basicGet(endpoint);
  }

  getClientSummary() {
    const endpoint = `${CLIENT_ENDPOINT}/summary`;
    return this.baseApi.basicGet(endpoint);
  }

  // used for dropdownlists etc
  getClientIds(isActive: any = true) {
    const endpoint = `${CLIENT_ENDPOINT}/ids?isActive=${isActive}`;
    return this.baseApi.basicGet(endpoint);
  }

  getClientIdsObs(isActive: any = true) {
    const endpoint = `${CLIENT_ENDPOINT}/ids?isActive=${isActive}`;
    return this.baseApi.getObs(endpoint);
  }

  getClientsByType(managedType: any = '', isActive = true) {
    const endpoint = managedType ? `${CLIENT_ENDPOINT}/${managedType}?isActive=${isActive}` : `${CLIENT_ENDPOINT}?isActive=${isActive}`;
    return this.baseApi.basicGet(endpoint);
  }

  getCOSClients() {
    const endpoint = `${CLIENT_ENDPOINT}/cos`;
    return this.baseApi.basicGet(endpoint);
  }

  getActiveCOSClients(billingDate: string = '') {
    const endpoint = `${CLIENT_ENDPOINT}/activeCos/${billingDate}`;
    return this.baseApi.basicGet(endpoint);
  }

  getClient(id: string) {
    const endpoint = `${CLIENT_ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  getPMClients() {
    const endpoint = `${CLIENT_ENDPOINT}/pmClients`;
    return this.baseApi.basicGet(endpoint);
  }

  saveClient(data: any) {
    const endpoint = `${CLIENT_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  getClientsForUser(userId: string) {
    const endpoint = `${CLIENT_ENDPOINT}/user/${userId}`;
    return this.baseApi.basicGet(endpoint);
  }

  /* Group Booking */

  createGroupBooking(bookingData) {
    return this.baseApi.basicCreate(GROUP_CLIENT_BOOKING_ENDPOINT, bookingData);
  }

  saveGroupBooking(jobId: any, clientId: any, bookingData: any) {
    const endpoint = `${GROUP_CLIENT_BOOKING_ENDPOINT}/${jobId}/${clientId}`;
    return this.baseApi.update(endpoint, bookingData);
  }

  patchGroupBooking(jobId: any, clientId: any, bookingData: any) {
    const endpoint = `${GROUP_CLIENT_BOOKING_ENDPOINT}/${jobId}/${clientId}`;
    const patchData: any = this.baseApi.createPatchObject(bookingData);
    return this.baseApi.updatePartial(endpoint, patchData.id, patchData);
  }

  deleteGroupBooking(jobId: any, clientId: any) {
    const endpoint = `${GROUP_CLIENT_BOOKING_ENDPOINT}/${jobId}/${clientId}`;
    return this.baseApi.delete(endpoint);
}

  /* Contacts */

  getContactsByClient(clientId: any) {
    const endpoint = `${CONTACT_ENDPOINT}/client/${clientId}`;
    return this.baseApi.basicGet(endpoint);
  }

  getContacts() {
    const endpoint = `${CONTACT_ENDPOINT}`;
    return this.baseApi.basicGet(endpoint);
  }

  getContact(contactId: any) {
    const endpoint = `${CONTACT_ENDPOINT}/${contactId}`;
    return this.baseApi.basicGet(endpoint);
  }

  updateContact(data: any) {
    const endpoint = `${CONTACT_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  createContact(data: any) {
    return this.baseApi.basicCreate(CONTACT_ENDPOINT, data);
  }

  deleteContact(contactId: any) {
    const endpoint = `${CONTACT_ENDPOINT}/${contactId}`;
    return this.baseApi.delete(endpoint);
  }

  /* Medication */

  getMedicationByClient(clientId: any) {
    const endpoint = `${CLIENT_MEDICATION_ENDPOINT}/client/${clientId}`;
    return this.baseApi.basicGet(endpoint);
  }

  getMedication(medicationId: any) {
    const endpoint = `${CLIENT_MEDICATION_ENDPOINT}/${medicationId}`;
    return this.baseApi.basicGet(endpoint);
  }

  updateMedication(data: any) {
    const endpoint = `${CLIENT_MEDICATION_ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  createMedication(data: any) {
    return this.baseApi.basicCreate(CLIENT_MEDICATION_ENDPOINT, data);
  }

  deleteMedication(medicationId: any) {
    const endpoint = `${CLIENT_MEDICATION_ENDPOINT}/${medicationId}`;
    return this.baseApi.delete(endpoint);
  }


  // * Client health profile * //

  getHealthProfileByHealthProfileId(profileId: any) {
    const endpoint = `${CLIENT_HEALTH_PROFILE}/${profileId}`;
    return this.baseApi.basicGet(endpoint);
  }

  getHealthProfileByClient(clientId: any) {
    const endpoint = `${CLIENT_HEALTH_PROFILE}/client/${clientId}`;
    return this.baseApi.basicGet(endpoint);
  }

  updateHealthProfile(data: any) {
    const endpoint = `${CLIENT_HEALTH_PROFILE}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  createHealthProfile(data: any) {
    delete data.id;
    return this.baseApi.basicCreate(CLIENT_HEALTH_PROFILE, data);
  }

  patchHealthProfile(data) {
    const patchData: any = this.baseApi.createPatchObject(data);
    return this.baseApi.updatePartial(CLIENT_HEALTH_PROFILE, data.id, patchData);
  }

  deleteHealthProfile(id: any) {
    const endpoint = `${CLIENT_HEALTH_PROFILE}/${id}`;
    return this.baseApi.delete(endpoint);
  }




}
