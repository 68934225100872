import { Injectable } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { CarestartApiService } from '../../services/api/carestart-api-base.service';
import filter from 'lodash-es/filter'
import { filesize } from 'filesize';
import { BusinessService } from 'src/app/services/business.service';

const ENDPOINT = 'Document';
@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private baseApi: CarestartApiService, private businessService: BusinessService) { }

  async patchDocumentFields(id: any, patchFields: any) {
    patchFields.id = id;
    this.patchDocument(patchFields);
  }

  uploadDocument(formData: any) {
    return this.baseApi.postFile(formData);
  }

  async getLogo(documentId: any = null): Promise<Blob> {
    // TODO review
    if (documentId != '00000000-0000-0000-0000-000000000000') {
        //const logoDocument: any = await this.getDocument(documentId);
        var urlCreator = window.URL || window.webkitURL;
        this.getFile(documentId).then(data => {
            return urlCreator.createObjectURL(data as Blob);            
        });
    } else {
      return null
    }
  }

  getDocumentCategories(group: any = '') {
    let returnVal = [];

    const allCats = [
      { name: 'Uncategorized', value: 'uncategorized', color: 'dark' },

      { group: 'Client', name: 'Assessment', value: 'client-assessment', color: 'purple' },
      { group: 'Client', name: 'Budget', value: 'client-budget', color: 'purple' },
      { group: 'Client', name: 'Consent', value: 'client-consent', color: 'purple' },
      { group: 'Client', name: 'Email', value: 'client-email', color: 'orange' },
      { group: 'Client', name: 'Intake', value: 'client-intake', color: 'purple' },
      { group: 'Client', name: 'Support Plan', value: 'support-plan', color: 'purple' },
      { group: 'Client', name: 'Invoice Approvals', value: 'client-invoice-approvals', color: 'gray' },
      { group: 'Client', name: 'NDIS Plans', value: 'ndis-plans', color: 'black' },
      { group: 'Client', name: 'Notes', value: 'notes-for-client', color: 'purple' },
      { group: 'Client', name: 'Progress Report', value: 'client-progress', color: 'purple' },
      { group: 'Client', name: 'Service Agreements', value: 'service-agreements', color: 'orange' },
      { group: 'Client', name: 'Statements', value: 'client-statement', color: 'green' },
     
      { group: 'Staff',  name: 'Profile Docs', value: 'profile-docs', color: 'blue' },
      { group: 'Staff', name: 'Timesheets', value: 'timesheets', color: 'yellow' },

      { group: 'Provider', name: 'Plan Manage Invoices', value: 'pm-invoices', color: 'pink' },
      { group: 'Provider', name: 'General', value: 'provider-general', color: 'blue' },

      { group: 'NDIS', name: 'Price Guides', value: 'price-guides', color: 'brown' },

      { group: 'Job', name: 'Quote', value: 'quote', color: 'blue' },
      { group: 'Billing', name: 'Receipts', value: 'receipts', color: 'red' },
      { group: 'Billing', name: 'Remittances', value: 'remittances', color: 'green' },

      { group: 'Org', name: 'Templates', value: 'templates', color: 'yellow' },
      { group: 'Org', name: 'HR', value: 'hr', color: 'orange' },
      { group: 'Org', name: 'Company Doc', value: 'company', color: 'green' },
      { group: 'Org', name: 'Policy', value: 'policy', color: 'gray' },
      { group: 'Org', name: 'Procedure', value: 'procedure', color: 'gray' },
      { group: 'Org', name: 'Checklist', value: 'checklist', color: 'gray' },
      { group: 'Org', name: 'Other', value: 'other', color: 'gray' },

    ];

    if (group) {
      returnVal = filter(allCats, a => a.group === group);
    } else {
      returnVal = allCats;
    }

    return returnVal;
  }

  setDefaultSettingsUploadDocuments() {
    const settings: Array<any> = [
      {
        'name': 'resume',
        'displayName': 'Resume',
        'ext': 'pdf,doc,jpg',
        hasDates: false,
      },
      {
        'name': 'copy_of_qualifications',
        'displayName': 'Copy of Qualifications',
        'ext': 'pdf,doc,jpg',
        hasDates: false
      },
      {
        'name': 'drivers_licence',
        'displayName': "Driver's license",
        'ext': 'pdf,doc,jpg',
        hasDates: true,
        datesText: 'Expiration date'
      },
      {
        'name': 'criminal_screening',
        'displayName': 'Criminal History Screening or Blue and Yellow Card',
        'ext': 'pdf,doc,jpg',
        hasDates: false
      },
      {
        'name': 'tax_declaration',
        'displayName': 'Tax Declaration',
        'ext': 'pdf,doc,jpg',
        hasDates: false
      },
      {
        'name': 'supperannuation_form',
        'displayName': 'Superannuation Choice Form',
        'ext': 'pdf,doc,jpg',
        hasDates: false
      },
      {
        'name': 'ndis_training_cert',
        'displayName': 'NDIS Training Module completion cert',
        'ext': 'pdf,doc,jpg',
        hasDates: false
      },
      {
        'name': 'first_aid',
        'displayName': 'First Aid',
        'ext': 'pdf,doc,jpg',
        hasDates: true,
        datesText: 'Expiration date'
      },
      {
        'name': 'flu_shot_cert',
        'displayName': 'Flu Immunization Certification',
        'ext': 'pdf,doc,jpg',
        hasDates: true,
        datesText: 'Date of taking Flu Immunization Cert'
      }
    ];
  }

  getDocuments(pageSize: any = 100, page: any = 1, group: any = "", category: any = "", searchWord: any = '', contentType: any = '', status: any = '', documentDateOrder: string = 'DESC') {
    const endpoint = `${ENDPOINT}?group=${group}&category=${category}&searchWord=${searchWord}&contentType=${contentType}&status=${status}&&Limit=${pageSize}&Page=${page}&documentDateOrder=${documentDateOrder}`;
    
    return this.baseApi.basicGet(endpoint);
  }

  getDocumentsObs() {
    return this.baseApi.getObs(ENDPOINT);
  }

  getSummary(category: string = "") {
    const endpoint = `${ENDPOINT}/summary?category=${category}`;
    return this.baseApi.getObs(endpoint);
  }

  getDocumentsByCategory(cat: string, status: string = '') {
    const endpoint = `${ENDPOINT}/category/${cat}?status=${status}`;
    return this.baseApi.basicGet(endpoint);
  }

  getDocumentByClient(clientId: any) {
    const endpoint = `${ENDPOINT}/client/${clientId}`;
    return this.baseApi.basicGet(endpoint);
  }

  getDocumentByStaff(staffId: any) {
    const endpoint = `${ENDPOINT}/staff/${staffId}`;
    return this.baseApi.basicGet(endpoint);
  }

  getDocumentByProvider(providerId: any) {
    const endpoint = `${ENDPOINT}/provider/${providerId}`;
    return this.baseApi.basicGet(endpoint);
  }

  getDocumentByUser(userId: any) {   // not used by the org. only for standalone user access docs
    const endpoint = `${ENDPOINT}/user/${userId}`;
    return this.baseApi.getObs(endpoint);
  }

  getDocumentByJob(jobId: any) {
    const endpoint = `${ENDPOINT}/job/${jobId}`;
    return this.baseApi.getObs(endpoint);
  }

  getDocument(id: any) {
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  updateDocument(data: any) {
    const endpoint = `${ENDPOINT}/${data.id}`;
    return this.baseApi.update(endpoint, data);
  }

  patchDocument(payload: any) {
    const data: any = this.baseApi.createPatchObject(payload);
    return this.baseApi.updatePartial(ENDPOINT, payload.id, data);
  }

  //this would only create a doc in db, not in storage 
  createDocument(data: any) {
    return this.baseApi.basicCreate(ENDPOINT, data);
  }

  // TODO will have to add to web api to delete doc from this as well. not done yet
  deleteDocument(id: any) {
    const endpoint = `${ENDPOINT}/${id}`;
    return this.baseApi.delete(endpoint);
  }

  /* FILES */

  async getFile(id: any) {
    const endpoint = `upload/${id}`;
    return this.baseApi.fileGet(endpoint, '');
  }

  uploadFile(document: any, source: any = null, sourceId: any = null) {
    if (source) { document[`${source}Id`] = sourceId };
    const mimeResult: any = this.businessService.mimeDb(document.type);
    document.extension = mimeResult ? mimeResult.extensions[0] : null;
    document.readableFileSize = filesize(document.size);

    let extension = document.name.split('.').pop();
    extension = extension || document.extension;
    document.nameDateStamped = `${document.name.replace(/\.[^/.]+$/, "")}_${(new Date()).getTime()}.${extension}`;

    let fileName = document.name;

    document.inProgress = true;
    const formData = new FormData();
    formData.append('file', document, fileName);

    const promise = new Promise((resolve, reject) => {
      this.uploadDocument(formData)
      .subscribe((event: any) => {
        if (event.status === 400 || event.status === 500) {
          document.success = false
          document.inProgress = false;
          reject('Something went wrong while trying to upload Document. Please contact administrator');
        }
        if (event.type === HttpEventType.Response) {
          let data = event.body?.data;
          let documentId = data.documentId;

          const saveItem: any = {
            id: documentId,
            category: document.category,
          }
          this.patchDocument(saveItem)
          .then(() => { document.success = true; })
          .finally(() => { document.inProgress = false; });;
          resolve(documentId)
        }
      });
    })
    return promise;
  }

  invoiceFormAnalyzer(id: any) {
    const endpoint = `${ENDPOINT}/formAnalyzer/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

  planFormAnalyzer(id: any) {
    const endpoint = `${ENDPOINT}/planAnalyzer/${id}`;
    return this.baseApi.basicGet(endpoint);
  }

    // FUNCTIONS
  // *********

  saveStatus(id: any, status: any) {
    const promise = new Promise((resolve, reject) => {
      const payload = [{
        "id": id,
        "op": "replace",
        "path": "/status",
        "value": status
      }];
      this.baseApi.updatePartial(ENDPOINT, id, payload).then((data: any) => {
        resolve(data);
      }).catch(error => {
        resolve(error);
      });
    });
    return promise;
  }
}