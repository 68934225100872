import { Component, ViewChild } from '@angular/core';
import { InternalMailService } from '@core/services/internal-mail.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
    @ViewChild('header', { static: true }) header;
    @ViewChild('modalContainer', { static: true }) modalContainer; 
    constructor(private internalMailService: InternalMailService) { }

    toggleTaskPanel() {
        this.internalMailService.changePanelDetail('Tasks')
        //this.settings.toggleLayoutSetting('offsidePanel');  // this part needs to be checked. it works from call in header but not here
    }

    toggleClientPanel() {
        this.internalMailService.changePanelDetail('Clients')
    }

}
