import { Injectable } from '@angular/core';
import { CarestartApiService } from 'src/app/services/api/carestart-api-base.service';

const MAIL_ENDPOINT = 'mail';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private baseApi: CarestartApiService) { }

  /* MAIL */

  createMail(data: any) {
    return this.baseApi.basicCreate(MAIL_ENDPOINT, data);
  }

  getMails() {
    //const endpoint = `mail?startDate=` + startDate + `&endDate=`+ endDate + `&includes=` + includes;
    return this.baseApi.basicGet(MAIL_ENDPOINT);
  }

  getMailsObs() {
    //const endpoint = `mail?startDate=` + startDate + `&endDate=`+ endDate + `&includes=` + includes;
    return this.baseApi.getObs(MAIL_ENDPOINT);
  }

  getMail(id: any) {
    return this.baseApi.basicGet(`${MAIL_ENDPOINT}/${id}`)
  }

  updateMail(id: any, payload: any) {
      return this.baseApi.updatePartial(`${MAIL_ENDPOINT}`, id, payload);
  }

  deleteMail(id: any) {
    return this.baseApi.delete(`${MAIL_ENDPOINT}/${id}`);
  }

  // SEND MAIL 

  sendMail(emailTo: string, replyTo: string, subject: string, bodyText: string, fromName: string, documentId: string = null) {
    const sendMailEndpoint = 'mailSend';

    const data = {
      emailTo: emailTo,
      replyTo: replyTo,
      subject: subject,
      bodyText: bodyText,
      documentId: documentId,
      fromName: fromName
    }
    
    return this.baseApi.basicCreate(sendMailEndpoint, data);
  }

}
